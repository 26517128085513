import React from "react";
import { Switch, Route } from "react-router-dom";
import ProjectWizard from "../containers/ProjectWizard/ProjectWizard";
import ProjectOverview from "./ProjectOverview";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import ProjectDashboard from "./ProjectDashboard";
import TaskOverview from "./TaskOverview";
import TaskWizard from "./Wizards/TaskWizard";

const ProjectsPage = () => {
  return (
    <Switch>
      <Route exact path="/projects" component={ProjectDashboard} />
      <PrivateRoute
        requiredRole="PROJECTMANAGER"
        exact
        path="/projects/create"
        component={ProjectWizard}
      />
      <PrivateRoute
        requiredRole="PROJECTMANAGER"
        exact
        path="/projects/:projectId/tasks/create"
        component={TaskWizard}
      />
      <Route
        exact
        path="/projects/:projectId/tasks/:taskId"
        component={TaskOverview}
      />
      <Route exact path="/projects/:projectId/" component={ProjectOverview} />
      <Route
        exact
        path="/projects/:projectId/:tab"
        component={ProjectOverview}
      />
      <Route
        exact
        path="/projects/:projectId/:tab/:id"
        component={ProjectOverview}
      />
    </Switch>
  );
};

export default ProjectsPage;
