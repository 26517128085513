import React, { ReactNode, Fragment } from "react";

interface Props {
  label: string;
  children: ReactNode;
  allowedOffline?: boolean;
}

const Tab = ({ label, children }: Props) => (
  <Fragment>
    <div>{label}</div>
    <div>{children}</div>
  </Fragment>
);

export default Tab;
