import endpoints, { axios } from "../../config/api";
import Document, { DocumentListResponse } from "../../types/Document";

async function getFolderContents(
  folderId: string,
  sort?: string
): Promise<DocumentListResponse> {
  return await (
    await axios.get(endpoints.newfolders.folderContents(folderId), {
      params: { sort }
    })
  ).data;
}

export default getFolderContents;
