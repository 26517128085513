import React, { useState, FunctionComponent, useEffect } from "react";
import EditIcon from "../../assets/img/editIcon.svg";
import EditIconHover from "../../assets/img/editIconHover.svg";
import TextInput from "./TextInput";
import { useOnlineProvider } from "../../state/OnlineProvider";
import checkUserRole from "../../helpers/checkUserRole";
import { useAuth } from "../../state/AuthProvider";

interface Props {
  value: string;
  placeholder: string;
  onChange?: (input: string | undefined) => void;
  type?: string;
  pattern?: string;
  inputRef?: any;
  validationMessage?: string;
  triggerValidation: () => void;
  name: string;
  errors: any;
  maxLength?: number;
}

const InlineEditableInput: FunctionComponent<Props> = ({
  onChange,
  value,
  placeholder,
  type,
  pattern,
  inputRef,
  validationMessage,
  triggerValidation,
  name,
  errors,
  maxLength
}) => {
  const { isOnline } = useOnlineProvider();
  const [icon, setIcon] = useState(EditIcon);
  const [isEditing, setEditing] = useState(false);
  const [input, setInput] = useState<string>(value);
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    if (errors) {
      setEditing(true);
    }
  }, [errors]);

  const isValid = async () => {
    await triggerValidation();
    if (!pattern || !input) {
      setErrorMessage("");
      return true;
    }

    if (input.match(pattern)) {
      setErrorMessage("");
      return true;
    }

    validationMessage && setErrorMessage(validationMessage);
    return false;
  };

  const onEditingChange = async () => {
    if (!(await isValid())) {
      return;
    }

    if (isEditing && onChange) {
      onChange(input);
    }

    setEditing(!isEditing);
  };

  return (
    <div className="flex flex-row justify-between items-center w-full">
      {isEditing && checkUserRole("PROJECTMANAGER", user.role) ? (
        <TextInput
          label=""
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={e => setInput(e.target.value)}
          pattern={pattern}
          inputRef={inputRef}
          errorMessage={errorMessage}
          name={name}
          maxLength={maxLength}
        />
      ) : (
        <p>{input}</p>
      )}
      {isOnline && checkUserRole("PROJECTMANAGER", user.role) && (
        <p
          onClick={e => onEditingChange()}
          className="ml-4 InlineEditIconContainer"
        >
          {isEditing ? (
            <svg className="ml-2" height="22px" width="22px" version="1.1">
              <g fill="#00B2A9">
                <path d="M6,10 L2.5,6.5 L0.5,8.5 L6,14 L15.5,4.5 L13.5,2.5 L6,10 Z"></path>
              </g>
            </svg>
          ) : (
            <img
              src={icon}
              alt="editable input icon"
              onMouseEnter={() => setIcon(EditIconHover)}
              onMouseLeave={() => setIcon(EditIcon)}
            />
          )}
        </p>
      )}
    </div>
  );
};

InlineEditableInput.defaultProps = {
  type: "text"
};

export default InlineEditableInput;
