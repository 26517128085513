import React, { FC } from "react";

const OfflineTab: FC = () => {
  return (
    <div>
      <h1 className="mt-8">
        You are currently offline. This feature is only available whilst
        connected to the internet.
      </h1>
    </div>
  );
};

export default OfflineTab;
