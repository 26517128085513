import React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

interface Props {
  imgSrc: string;
  imgAlt: string;
  label: string;
  isSidebarOpen: boolean;
  link: string;
  onClick: () => void;
  totalNotifications?: number;
}

const SidebarItem = ({
  imgAlt,
  imgSrc,
  label,
  isSidebarOpen,
  link,
  onClick,
  totalNotifications
}: Props) => {
  return (
    <NavLink to={link} onClick={() => onClick()} className="sidebarItem">
      <img src={imgSrc} alt={imgAlt} />
      {label === "NOTIFICATIONS" &&
        !!totalNotifications &&
        totalNotifications > 0 && (
          <div
            style={{
              position: "relative",
              top: "-10px",
              right: "10px",
              height: "20px",
              width: "20px",
              fontSize: "11px",
              borderRadius: "20px",
              background: "red",
              color: "white",
              textAlign: "center"
            }}
          >
            {totalNotifications}
          </div>
        )}
      {isSidebarOpen && (
        <h3
          className={classnames("whitespace-no-wrap", {
            "pl-0":
              label === "NOTIFICATIONS" &&
              totalNotifications &&
              totalNotifications > 0,
            "pl-5": !(
              label === "NOTIFICATIONS" &&
              totalNotifications &&
              totalNotifications > 0
            )
          })}
        >
          {label}
        </h3>
      )}
    </NavLink>
  );
};

export default SidebarItem;
