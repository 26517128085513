import React, { FC } from "react";
import { RouteProps, Route } from "react-router-dom";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import OfflinePage from "../pages/OfflinePage";

interface Props {
  allowOffline?: boolean;
}

const AppleRoute: FC<Props & RouteProps> = ({ allowOffline, ...rest }) => {
  if (!getOnlineStatus() && !allowOffline) {
    return <OfflinePage />;
  }

  return <Route {...rest} />;
};

export default AppleRoute;
