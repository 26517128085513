import database from "../database";
import toFile from "./toFile";
import api from "../api";

export async function isSyncRequired() {
  const offlineEvents = await database.find({
    selector: {
      _id: { $regex: "offline:\\w*$" }
    }
  });
  return true;
}

async function syncOfflineData() {
  const offlineEvents = await database.find({
    selector: {
      _id: { $regex: "offline:[\\s\\S]*" }
    }
  });

  const errors = [];
  const docCount = offlineEvents.docs.length;

  for (let x = 0; x < docCount; x++) {
    const document = offlineEvents.docs[x] as any;
    const { payload, action, entity } = document;

    try {
      switch (action) {
        case "CREATE": {
          if (entity === "task") {
            await api.createSubtask(
              payload.projectId,
              payload.parentTaskId,
              payload,
              payload.isCompleted
            );
          }
          if (entity === "document") {
            const isTaskDocument = document.payload._id.includes(":tasks:");
            const file = toFile(payload.file, payload.name);
            if (!file) {
              break;
            }

            if (isTaskDocument) {
              // await api.uploadTaskDocument(
              //   payload.name,
              //   file,
              //   payload.isGlobal,
              //   payload.isPrivate,
              //   payload.projectId,
              //   payload.taskId,
              //   payload.ownerId
              // );
              // TODO: This would never happen?
              // await api.newFolders.uploadFile(
              //   payload.folderId,
              //   payload.name,
              //   payload.isPrivate,
              //   file
              // );
            } else {
              // await api.uploadProjectDocument(
              //   payload.name,
              //   file,
              //   payload.isGlobal,
              //   payload.isPrivate,
              //   payload.projectId
              // );
            }
          }
          break;
        }
        case "UPDATE": {
          if (entity === "task") {
            await api.completeSubtask(
              payload.projectId,
              payload.taskId,
              payload.subtaskId
            );
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      errors.push({ message: `Error when trying to ${action} ${entity}` });
      console.error(error);
    }
  }

  await syncProjects();
}

const syncProjects = () => {
  return new Promise(async resolve => {
    const projects = await database.find({
      selector: {
        _id: { $regex: "^projects:[0-9]+$" }
      }
    });

    const docs = await database.allDocs({ include_docs: true });
    await Promise.all(docs.rows.map(r => r.doc && database.remove(r.doc)));

    const projectIds = projects.docs.map((p: any) => p.id);
    await Promise.all(
      projectIds.map((p: number) => {
        return api.downloadProject(p);
      })
    );

    return resolve();
  });
};

export default syncOfflineData;
