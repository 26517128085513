import React, { useState, Fragment } from "react";
import Modal from "react-responsive-modal";
import { DocumentWithFile } from "../../types/Document";
import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import checkUserRole from "../../helpers/checkUserRole";
import { useAuth } from "../../state/AuthProvider";
import { getOnlineStatus } from "../../helpers/useOnlineStatus";
import formatDate from "../../helpers/formatDate";
import settings from "../../config/settings";
import endpoints from "../../config/api";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  image: DocumentWithFile | undefined;
  deleteImage: (imageId: string) => void;
  currentFolder: string;
}

const ViewImageModal = ({
  image,
  isModalOpen,
  setIsModalOpen,
  deleteImage,
  currentFolder
}: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { user } = useAuth();
  return (
    <Modal
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
      center={true}
      classNames={{
        modal: "viewImageModal ",
        closeButton: "mt-2 mr-2 focus:outline-none"
      }}
    >
      <div className="h-56 w-56 md:h-84 md:w-84">
        <img
          src={`${settings.engineUrl}${endpoints.newfolders.downloadFile(
            currentFolder,
            image?.id ?? ""
          )}`}
          alt={image?.name}
          className="h-full w-full"
        />
      </div>
      <p className="text-white">{image?.name}</p>
      <p className="text-white">{image && formatDate(image.createdAt)}</p>
      <div className="flex w-full justify-center mt-4">
        {(checkUserRole("PROJECTMANAGER", user.role) ||
          image?.ownerId === user.id) && (
          <Fragment>
            <ConfirmDeleteModal
              setIsModalOpen={setIsDeleteModalOpen}
              isModalOpen={isDeleteModalOpen}
              onConfirm={() => {
                if (image) {
                  deleteImage(image?.id);
                }
                setIsDeleteModalOpen(false);
                setIsModalOpen(false);
              }}
              entityLabel="Image"
            />

            <button
              className="btn-circle bg-transparent hover:bg-transparent border-red border-2 flex justify-center "
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <img
                src={deleteHoverIcon}
                alt={image?.name}
                className="hover:fill-white"
              />
            </button>
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

export default ViewImageModal;
