import React, { useEffect, useState, useRef, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Modal } from "react-responsive-modal";
import { PulseLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import endpoints, { axios } from "../../config/api";
import Header from "../../components/Header";
import ProtectedComponent from "../../components/ProtectedComponent";
import TextInput from "../../components/Input/TextInput";
import useAxios from "axios-hooks";
import useDebounce from "../../helpers/useDebounce";
import CreateUserModal, {
  UserToCreate
} from "../../containers/Team/CreateUserModal";
import api from "../../api";
import TeamMemberListItem from "../../containers/Team/TeamMemberListItem";
import { AxiosError } from "axios";

const TeamPage = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [userToDelete, setUserToDelete] = useState("");
  const [users, setUsers] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const scrollRef = useRef(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    setUsers([]);
    setCurrentPage(0);

    axios
      .get(endpoints.users.search(debouncedSearchTerm))
      .then(({ data }) => {
        setUsers([...data.payload]);
        setCurrentPage(data.currentPage);
        setMaxPage(data.maxPage);
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [debouncedSearchTerm]);

  const [deleteUserResponse, deleteUser] = useAxios(
    {
      url: endpoints.users.delete("")
    },
    { manual: true }
  );

  const onAccountClicked = (userId: string) => {
    history.push(`/users/${userId}`);
  };

  const onDeleteClicked = (userId: string) => {
    deleteUser({ url: endpoints.users.delete(userId), method: "DELETE" })
      .then(data => {
        addToast("User account deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setSearchTerm("");
        setIsModalOpen(false);
        setUsers(users.filter((user: any) => user.id !== userId));
      })
      .catch(error => {
        if (error.response.status === 400) {
          addToast("Failed to delete User account as they are a site manager", {
            appearance: "error",
            autoDismiss: true
          });
          setIsModalOpen(false);
          return;
        }
        addToast("Failed to delete User account", {
          appearance: "error",
          autoDismiss: true
        });
        setIsModalOpen(false);
      });
  };

  const loadMore = (page: any) => {
    axios
      .get(endpoints.users.search(debouncedSearchTerm, page))
      .then(({ data }) => {
        setUsers([...users, ...data.payload]);
        setCurrentPage(data.currentPage);
        setMaxPage(data.maxPage);
      })
      .catch(error => console.log(error));
  };

  const createUser = (userToCreate: UserToCreate) => {
    api
      .createUser(userToCreate)
      .then(({ data }) => {
        addToast("User Created Successfully", {
          appearance: "success",
          autoDismiss: true
        });
        setUsers([...users, data]);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          addToast(
            "User Creation Failed. The Email Address may be already assigned to a current user",
            {
              appearance: "error",
              autoDismiss: true
            }
          );
          return;
        }
        addToast("User Creation Failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  return (
    <div ref={scrollRef} className="h-screen overflow-auto  ">
      <div className=" sm:hidden absolute bottom-2 right-1 z-10">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <button
            onClick={() => setIsCreateUserModalOpen(true)}
            className="btn-circle"
          >
            +
          </button>
        </ProtectedComponent>
      </div>
      <div className="hidden sm:block">
        <Header title="Teams">
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <button
              className="btn-primary"
              onClick={() => setIsCreateUserModalOpen(true)}
            >
              + Add Staff Member
            </button>
          </ProtectedComponent>
        </Header>
      </div>
      <div className="px-8">
        <div className="mt-8 w-56">
          <TextInput
            className=" bg-lightGrey"
            value=""
            label="Search for users"
            placeholder="Enter user's name"
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
            maxLength={256}
          />
        </div>
        <div className="mt-8 block">
          {loading && (
            <table className=" w-full ">
              <thead>
                <tr className="bg-lightGrey">
                  <th className="pb-3">Name</th>
                  <th className="w-auto md:w-64 pb-3"></th>
                  <th className="invisible sm:visible pb-3">Role</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <div className="w-full flex items-center justify-center">
                      <PulseLoader color="#00B2A9" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {users && !loading && users.length === 0 ? (
            <h1>
              {searchTerm.trim().length === 0
                ? "There are currently no projects available"
                : "No Users match this search term"}
            </h1>
          ) : (
            !loading && (
              <InfiniteScroll
                pageStart={1}
                threshold={250}
                hasMore={currentPage < maxPage}
                loadMore={loadMore}
                useWindow={false}
                className="mb-16"
                element="div"
                getScrollParent={() => scrollRef.current}
                loader={
                  <div className="w-full flex justify-center items-center">
                    <PulseLoader color="#00B2A9" />
                  </div>
                }
              >
                <table className="border-collapse space-x-1">
                  <thead>
                    <tr className="bg-lightGrey">
                      <th className="pb-3">Name</th>
                      <th className="w-auto md:w-64 pb-3"></th>
                      <th className="invisible sm:visible pb-3">Role</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users.map((item: any) => (
                        <TeamMemberListItem
                          item={item}
                          onAccountClicked={onAccountClicked}
                          setIsModalOpen={setIsModalOpen}
                          setUserToDelete={setUserToDelete}
                        />
                      ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            )
          )}
        </div>
      </div>
      <Modal
        center={true}
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        showCloseIcon={false}
      >
        <h2>Are you sure you want to delete this User?</h2>
        <h4 className="font-normal">
          This is a permanent action and cannot be reversed
        </h4>
        <div className="flex flex-col-reverse sm:flex-row  mt-8">
          <button
            className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn-primary  w-full sm:w-56"
            onClick={() => onDeleteClicked(userToDelete)}
          >
            Delete
          </button>
        </div>
      </Modal>
      <CreateUserModal
        closeModal={() => setIsCreateUserModalOpen(false)}
        isModalOpen={isCreateUserModalOpen}
        onSubmit={e => createUser(e)}
      />
    </div>
  );
};

export default TeamPage;
