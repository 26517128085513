import React, { Fragment } from "react";
import MainLayout from "../../layouts/MainLayout";
import { useHistory } from "react-router-dom";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Fragment>
      <div className="flex flex-col h-full w-full p-8 justify-center text-center">
        <h1 className="text-6xl mb-4">404</h1>
        <h1>The page you're looking for can't be found</h1>
        <button
          className="btn-primary mt-4 self-center"
          onClick={() => history.push("/projects")}
        >
          Back to your dashboard
        </button>
      </div>
    </Fragment>
  );
};

export default NotFoundPage;
