import React from "react";

interface Props {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  placeholder?: string;
  errorMessage?: string;
  name?: string;
  maxLength?: number;
}

const TextAreaInput = ({
  label,
  className,
  placeholder,
  errorMessage,
  value,
  name,
  maxLength,
  onChange
}: Props) => {
  return (
    <fieldset>
      <label className="text-sm" htmlFor={label}>
        {label}
      </label>

      <textarea
        id={label}
        className={`${className}  ${
          errorMessage && errorMessage.trim().length > 0 ? "error" : ""
        }`}
        placeholder={placeholder}
        defaultValue={value}
        onChange={e => onChange(e)}
        name={name}
        maxLength={maxLength}
      />
      <div className="inputErrorMessage">{errorMessage}</div>
    </fieldset>
  );
};

export default TextAreaInput;
