import React, { useEffect, useState, useRef } from "react";
import endpoints, { axios } from "../../config/api";
import Task from "../../types/Task";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import { useToasts } from "react-toast-notifications";
import { PulseLoader } from "react-spinners";
import ProtectedComponent from "../../components/ProtectedComponent";
import TaskSubtaskItem from "./TaskSubtaskItem";
import api from "../../api";

interface Props {
  projectId: number;
  taskId: number;
  setIsAllSubtasksComplete: (isAllComplete: boolean) => void;
  parentTask: Task;
  canCompleteSubtasks: boolean;
}

const TaskSubtaskTab = ({
  projectId,
  taskId,
  setIsAllSubtasksComplete,
  parentTask,
  canCompleteSubtasks
}: Props) => {
  const [subtasks, setSubtasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [subtaskToDelete, setSubtaskToDelete] = useState<number>();
  const [isCreatingSubtask, setIsCreatingSubtask] = useState(false);
  const [subtaskToCreateName, setSubtaskToCreateName] = useState("");
  const subtaskRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToasts();

  useEffect(() => {
    setLoading(true);
    setSubtasks([]);
    api
      .getSubTasks(projectId, taskId)
      .then(data => {
        setSubtasks([...data.payload]);
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [projectId, taskId]);

  useEffect(() => {
    const canParentBeCompleted =
      subtasks.length === 0 ||
      subtasks.map(s => s.isCompleted).filter(s => !s).length === 0;

    setIsAllSubtasksComplete(canParentBeCompleted);
  }, [subtasks, setIsAllSubtasksComplete, loading]);

  const completeSubtask = (taskToComplete: number) => {
    api.completeSubtask(projectId, taskId, taskToComplete).then(() => {
      const subtaskToUpdate = subtasks.findIndex(t => t.id === taskToComplete);
      const updatedSubtasks = [...subtasks];

      updatedSubtasks[subtaskToUpdate] = {
        ...updatedSubtasks[subtaskToUpdate],
        isCompleted: true
      };

      setSubtasks(updatedSubtasks);
      addToast("Sucessfully Completed Subtask", {
        appearance: "success",
        autoDismiss: true
      });
    });
  };

  const deleteSubTask = () => {
    if (subtaskToDelete) {
      api
        .deleteSubTask(projectId, taskId, subtaskToDelete)
        .then(() => {
          setSubtasks(subtasks.filter(f => f.id !== subtaskToDelete));
          setSubtaskToDelete(undefined);
          setIsDeleteModalOpen(false);
          addToast("Sucessfully Deleted Subtask", {
            appearance: "success",
            autoDismiss: true
          });
        })
        .catch(() => {
          addToast("Failed to Delete Subtask, Try again.", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  };

  const updateSubtask = (subtask: Task) => {
    axios
      .put(endpoints.task.update(projectId, subtask.id), {
        name: subtask.name
      })
      .then(() => {
        addToast("Sucessfully Updated Subtasks", {
          appearance: "success",
          autoDismiss: true
        });
        const updatedSubtasks = subtasks;
        const itemToUpdateIndex = updatedSubtasks.findIndex(
          t => t.id === subtask.id
        );
        updatedSubtasks[itemToUpdateIndex] = {
          ...updatedSubtasks[itemToUpdateIndex],
          name: subtask.name
        };
        setSubtasks([...updatedSubtasks]);
      });
  };

  const createSubtask = () => {
    if (subtaskToCreateName.length === 0) {
      setIsCreatingSubtask(false);
      setSubtaskToCreateName("");
      return;
    }
    const subtaskToCreate = {
      projectId,
      parentTaskId: parentTask?.id,
      name: subtaskToCreateName,
      description: subtaskToCreateName,
      startDate: parentTask?.startDate,
      expectedCompletionDate: parentTask?.expectedCompletionDate
    };

    api
      .createSubtask(projectId, taskId, subtaskToCreate)
      .then(data => {
        setIsCreatingSubtask(false);
        setSubtaskToCreateName("");
        setSubtasks([...subtasks, data]);
        addToast("Sucessfully Created Subtask", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        addToast("Failed to create subtask. Try again", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  useEffect(() => {
    if (isCreatingSubtask && subtaskRef.current) {
      subtaskRef.current && subtaskRef.current.focus();
    }
  }, [subtaskRef, isCreatingSubtask]);

  return (
    <div className="mt-8 block">
      <table className="border-collapse space-x-1 mb-8">
        <thead>
          <tr className="bg-white">
            <th className="w-auto md:w-64 pb-3" colSpan={2}>
              Sub-task Name
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={3}>
                <div className="w-full flex items-center justify-center">
                  <PulseLoader color="#00B2A9" />
                </div>
              </td>
            </tr>
          )}
          {subtasks && !loading && subtasks.length === 0 && !isCreatingSubtask && (
            <tr className="text-center ">
              <td colSpan={4}>No Subtasks Found</td>
            </tr>
          )}
          {subtasks &&
            subtasks.map((item: Task) => (
              <TaskSubtaskItem
                completeSubtask={completeSubtask}
                parentTask={parentTask}
                setIsDeleteModalOpen={setIsDeleteModalOpen}
                setSubtaskToDelete={setSubtaskToDelete}
                subtask={item}
                handleUpdateSubtask={updateSubtask}
                canCompleteSubtasks={canCompleteSubtasks}
              />
            ))}
          <tr className={`${isCreatingSubtask ? "table-row" : "hidden"}`}>
            <td className="w-12 pl-0 md:pl-2 py-1">
              <input
                id="form-input-create"
                className={`checkbox appearance-none border-lightBlue border-2 cursor-pointer h-6 w-6`}
                type="checkbox"
                placeholder=""
              />
            </td>
            <td className={`pl-2 text-grey`}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createSubtask();
                }}
              >
                <input
                  onBlur={() => createSubtask()}
                  placeholder="Subtask Name"
                  value={subtaskToCreateName}
                  onChange={({ target: { value } }) =>
                    setSubtaskToCreateName(value)
                  }
                  autoFocus={true}
                  maxLength={256}
                  ref={subtaskRef}
                />
              </form>
            </td>
            <td>
              <div className="flex justify-end">
                <div
                  id="deleteIcon"
                  className="cursor-pointer"
                  onClick={() => {
                    setIsCreatingSubtask(false);
                    setSubtaskToCreateName("");
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ProtectedComponent requiredRole="PROJECTMANAGER">
        <ConfirmDeleteModal
          entityLabel="Subtask"
          isModalOpen={isDeleteModalOpen}
          onConfirm={() => subtaskToDelete && deleteSubTask()}
          setIsModalOpen={setIsDeleteModalOpen}
        />
        {parentTask && !parentTask.isCompleted && (
          <div>
            <button
              className="btn-tertiary my-6"
              onClick={() => {
                setIsCreatingSubtask(true);
              }}
            >
              + Add a subtask
            </button>
          </div>
        )}
      </ProtectedComponent>
    </div>
  );
};

export default TaskSubtaskTab;
