import endpoints, { axios } from "../config/api";
import database from "../database";

async function deleteProject(projectId: number) {
  const localProject = await database.find({
    selector: {
      _id: { $eq: `projects:${projectId}` }
    }
  });

  if (localProject.docs.length > 0) {
    await database.remove(localProject.docs[0]);
  }

  return axios.delete(endpoints.project.delete(projectId));
}

export default deleteProject;
