import React, { FunctionComponent } from "react";
import { ToastProps } from "react-toast-notifications";
import TickIcon from "../assets/img/checkIcon.svg";
import CloseIcon from "../assets/img/closeIcon.svg";
import WarningIcon from "../assets/img/warningIcon.svg";
import InfoIcon from "../assets/img/infoIcon.svg";

const options = {
  success: {
    bgColour: "bg-lightBlue",
    textColour: "text-lightBlue",
    icon: TickIcon,
    label: "Success!"
  },
  warning: {
    bgColour: "bg-orange",
    textColour: "text-orange",
    icon: WarningIcon,
    label: "Warning!"
  },
  info: {
    bgColour: "bg-blue",
    textColour: "text-blue",
    icon: InfoIcon,
    label: "Info"
  },
  error: {
    bgColour: "bg-red",
    textColour: "text-red",
    icon: CloseIcon,
    label: "Error!"
  }
};

const AppleToast: FunctionComponent<ToastProps> = ({
  appearance,
  onDismiss,
  children
}) => {
  const option: any = options[appearance];

  return (
    <div className="flex  flex-row my-4 w-84 shadow-md">
      <div
        className={`flex w-12 min-h-full items-center justify-center ${option.bgColour}`}
      >
        <img src={option.icon} alt="close toast" />
      </div>
      <div className="py-3 px-2 bg-white w-full">
        <p className={`font-bold font-sm ${option.textColour}`}>
          {option.label}
        </p>
        <small className="font-xxs">{children}</small>
      </div>
      {onDismiss && (
        <div
          className="bg-white pt-2 pr-2 cursor-pointer"
          onClick={() => onDismiss()}
        >
          <svg width="10px" height="10px" viewBox="0 0 17 16" version="1.1">
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                transform="translate(2.000000, 1.000000)"
                stroke="#CDCDCD"
                stroke-width="2"
              >
                <g id="Hamburger">
                  <path d="M0,12.5 L13,1 M0,0.5 L13,13"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )}
    </div>
  );
};

export default AppleToast;
