import React from "react";
import Select, { ValueType } from "react-select";
import { Option } from "react-select/src/filters";

interface Props {
  className?: string;
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  //@ts-ignore
  onChange?: (e: Option | ValueType<Option>) => void;
  onSearchChange?: (e: string) => void;
  searchValue?: string;
  placeholder?: string;
  type?: string;
  value?: any;
  options: Option[];
  isMulti?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  noOptionsMessage?: string;
}

const SelectInput = ({
  className,
  errorMessage,
  inputRef,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  options,
  onSearchChange,
  searchValue,
  isMulti = false,
  isLoading,
  isSearchable = true,
  noOptionsMessage
}: Props) => {
  return (
    <fieldset className="w-full">
      <label className="text-sm" htmlFor={label}>
        {label}
      </label>

      <Select
        id={`form-input-${label}`}
        className={`${className} ${errorMessage && "error"}`}
        type={type}
        isMulti={isMulti}
        placeholder={placeholder}
        options={options}
        onChange={e => onChange && onChange(e)}
        inputValue={searchValue}
        isSearchable={isSearchable}
        onInputChange={e => onSearchChange && onSearchChange(e)}
        ref={inputRef}
        name={name}
        isLoading={isLoading}
        value={value}
        noOptionsMessage={(obj: { inputValue: string }) =>
          noOptionsMessage || "No Options"
        }
      />
      <div className="inputErrorMessage">{errorMessage}</div>
    </fieldset>
  );
};

export default SelectInput;
