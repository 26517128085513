import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";

async function getTaskDependancies(projectId: number, taskId: number) {
  if (!getOnlineStatus()) {
    const task: any = await database.get(
      `projects:${projectId}:tasks:${taskId}`
    );
    const dependencies = task.dependencies;
    const dependencyList =
      dependencies.length > 0
        ? await Promise.all(
            dependencies.map(async (d: number) => {
              return await database.get(`projects:${projectId}:tasks:${d}`);
            })
          )
        : [];

    return dependencyList;
  }
  return (
    await axios.get(endpoints.task.getTaskDependancies(projectId, taskId))
  ).data;
}

export default getTaskDependancies;
