import addDependancyToTask from "./addDependancyToTask";
import addDependancyToTaskTemplate from "./addDependancyToTaskTemplate";
import addTaskToProjectTemplate from "./addTaskToProjectTemplate";
import addUserToProject from "./addUserToProject";
import addUserToTask from "./addUserToTask";
import completeSubtask from "./completeSubtask";
import createCommercialDocument from "./createCommercialDocument";
import createSubtask from "./createSubtask";
import createUser from "./createUser";
import deleteCommercialDocument from "./deleteCommercialDocument";
import deleteNotification from "./deleteNotification";
import deleteProject from "./deleteProject";
import deleteProjectTemplate from "./deleteProjectTemplate";
import deleteSubTask from "./deleteSubTask";
import deleteTask from "./deleteTask";
import deleteTaskFromProjectTemplate from "./deleteTaskFromProjectTemplate";
import deleteTaskTemplate from "./deleteTaskTemplate";
import getAddableDependancyTasks from "./getAddableDependancyTasks";
import getCommercialTemplates from "./getCommercialTemplates";
import getDashboard from "./getDashboard";
import getProjectById from "./getProjectById";
import getProjectCommercials from "./getProjectCommercials";
import getProjectList from "./getProjectList";
import getProjectTasks from "./getProjectTasks";
import getProjectTemplates from "./getProjectTemplates";
import getProjectTemplateTasks from "./getProjectTemplateTasks";
import getProjectUsers from "./getProjectUsers";
import getSubTasks from "./getSubTasks";
import getTaskById from "./getTaskById";
import getTaskByTaskId from "./getTaskByTaskId";
import getTaskList from "./getTaskList";
import getTaskMembers from "./getTaskMembers";
import getTaskTemplate from "./getTaskTemplate";
import getTaskTemplates from "./getTaskTemplates";
import getTaskTemplateSubtasks from "./getTaskTemplateSubtasks";
import getUser from "./getUser";
import getUserNotifications from "./getUserNotifications";
import getUserTaskList from "./getUserTaskList";
import login from "./login";
import markNotificationAsRead from "./markNotificationAsRead";
import removeDependancyFromTask from "./removeDependancyFromTask";
import removeDependancyFromTaskTemplate from "./removeDependancyFromTaskTemplate";
import getTaskDependancies from "./getTaskDependancies";
import downloadProject from "./downloadProject";
import getAddableDependancyTemplateTasks from "./getAddableDependancyTemplateTasks";
import getAddableDependancyTemplateTasksByIds from "./getAddableDependancyTemplateTasksByIds";
import getTaskTemplateDependancies from "./getTaskTemplateDependancies";
import getRecursiveTaskTemplateDependancies from "./getRecursiveTaskTemplateDependancies";
import getTotalUserNotifications from "./getTotalUserNotifications";
import updateProjectUser from "./updateProjectUser";

import getRootFolderProject from "./newFolder/getRootFolderProject";
import getRootFolderTask from "./newFolder/getRootFolderTask";
import getFolderContents from "./newFolder/getFolderContents";
import uploadFile from "./newFolder/uploadFile";
import getNewFolder from "./newFolder/getNewFolder";
import replaceFile from "./newFolder/replaceFile";
import deleteFile from "./newFolder/deleteFile";
import getFile from "./newFolder/getFile";
import updateFile from "./newFolder/updateFile";
import updateNewFolder from "./newFolder/updateNewFolder";
import getGlobalFolder from "./newFolder/getGlobalFolder";
import createNewSubfolder from "./newFolder/createNewSubfolder";
import deleteNewFolder from "./newFolder/deleteNewFolder";
import getCommercialFolderProject from "./newFolder/getCommercialFolderProject";
import getRootFolderTemplateProject from "./newFolder/getRootFolderTemplateProject";
import getRootFolderTemplateTask from "./newFolder/getRootFolderTemplateTask";
import getCommercialTemplatesFolder from "./newFolder/getCommercialTemplatesFolder";
import copyFile from "./newFolder/copyFile";
import getSubfolders from "./newFolder/getSubfolders";

export default {
  addDependancyToTask,
  addDependancyToTaskTemplate,
  addTaskToProjectTemplate,
  addUserToProject,
  addUserToTask,
  completeSubtask,
  createCommercialDocument,
  createSubtask,
  createUser,
  deleteCommercialDocument,
  deleteNotification,
  deleteProject,
  deleteProjectTemplate,
  deleteSubTask,
  deleteTask,
  deleteTaskFromProjectTemplate,
  deleteTaskTemplate,
  downloadProject,
  getAddableDependancyTasks,
  getAddableDependancyTemplateTasks,
  getAddableDependancyTemplateTasksByIds,
  getTaskTemplateDependancies,
  getCommercialTemplates,
  getDashboard,
  getProjectById,
  getProjectCommercials,
  getProjectList,
  getProjectTasks,
  getProjectTemplates,
  getProjectTemplateTasks,
  getProjectUsers,
  getRecursiveTaskTemplateDependancies,
  getSubTasks,
  getTaskById,
  getTaskByTaskId,
  getTaskDependancies,
  getTaskList,
  getTaskMembers,
  getTaskTemplate,
  getTaskTemplates,
  getTaskTemplateSubtasks,
  getTotalUserNotifications,
  getUser,
  getUserNotifications,
  getUserTaskList,
  login,
  markNotificationAsRead,
  removeDependancyFromTask,
  removeDependancyFromTaskTemplate,
  updateProjectUser,
  // new folders:
  newFolders: {
    getFolderContents,
    uploadFile,
    getNewFolder,
    deleteFile,
    getFile,
    updateFile,
    updateNewFolder,
    createNewSubfolder,
    getGlobalFolder,
    deleteNewFolder,
    getSubfolders,

    getRootFolderProject,
    getCommercialFolderProject,
    getRootFolderTask,
    getRootFolderTemplateTask,
    getCommercialTemplatesFolder,
    copyFile,
    replaceFile,
    getRootFolderTemplateProject
  }
};
