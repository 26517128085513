import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import api from "../../api";
import SelectInput from "../../components/Input/SelectInput";
import useDebounce from "../../helpers/useDebounce";
import Task from "../../types/Task";
import { Option } from "react-select/src/filters";

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  onConfirm: (dependancyId: number) => void;
  projectId: number;
  currentTaskId: number;
  selectedTask: Task | undefined;
  setSelectedTask: (selectedTask: Task) => void;
  closeModal: () => void;
}

const CreateTaskDependancyModal = ({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  projectId,
  currentTaskId,
  selectedTask,
  setSelectedTask,
  closeModal
}: Props) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (isModalOpen) {
      api
        .getAddableDependancyTasks(projectId, currentTaskId)
        .then(({ data }) => {
          setTasks([...data]);
        });
    }
  }, [debouncedSearchTerm, projectId, currentTaskId, isModalOpen]);

  return (
    <Modal
      center={true}
      onClose={() => closeModal()}
      open={isModalOpen}
      showCloseIcon={false}
    >
      <h2>Select a task that this task depends on</h2>
      <SelectInput
        label="Select a task"
        placeholder="Select a task"
        value={
          selectedTask
            ? {
                value: selectedTask.id,
                label: selectedTask.name,
                data: selectedTask
              }
            : {}
        }
        options={tasks.map(t => {
          return {
            value: t.id.toString(),
            label: t.name,
            data: t
          };
        })}
        onChange={e => {
          setSelectedTask((e as Option).data);
        }}
        onSearchChange={e => {
          setSearchTerm(e);
        }}
        noOptionsMessage="Sorry, there are no available tasks which can be assigned as a dependency."
      />
      <div className="flex flex-col-reverse sm:flex-row justify-between mt-8">
        <button
          className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className="btn-primary  w-full sm:w-56"
          onClick={() => selectedTask && onConfirm(selectedTask.id)}
        >
          Add Dependancy
        </button>
      </div>
    </Modal>
  );
};

export default CreateTaskDependancyModal;
