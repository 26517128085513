import React, { ReactNode } from "react";
import classnames from "classnames";

interface Props {
  label: string;
  isActive?: boolean;
  onClick: () => void;
  children?: ReactNode;
  fluid?: boolean;
}

const TabLink = ({ label, isActive, onClick, fluid }: Props) => (
  <button
    onClick={onClick}
    className={classnames("text-blue pt-4 pb-2 font-bold tabLink min-w-100", {
      active: isActive,
      "flex-grow": fluid,
      "w-56": !fluid
    })}
  >
    {label}
  </button>
);

export default TabLink;
