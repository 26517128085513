import { Machine, assign } from "xstate";
import endpoints, { axios } from "../../config/api";
import api from "../../api";
import Task from "../../types/Task";

const updateTaskRequest = (task: any) => {
  return axios.put(endpoints.task.update(task.projectId, task.id), task);
};

const completeTaskRequest = (projectId: number, taskId: number) => {
  return axios.post(endpoints.task.complete(projectId, taskId));
};

const deleteTaskRequest = (task: any) => {
  return axios.delete(endpoints.task.delete(task.projectId, task.id));
};

interface TaskPageStateContext {
  taskId: number;
  projectId: number;
  task: any;
  project: any;
  dependancies: Task[];
  userName: string;
  isUserAssignedToTask: boolean;
}

const TaskPageStateMachine = Machine<TaskPageStateContext>({
  id: "TaskPageStateMachine",
  initial: "load",
  context: {
    taskId: 0,
    projectId: 0,
    project: null,
    task: null,
    dependancies: [],
    userName: "",
    isUserAssignedToTask: false
  },
  states: {
    idle: {
      on: {
        UPDATE: { target: "update" },
        COMPLETE: { target: "completeTask" },
        DELETE: { target: "deleteTask" },
        SET_START_DATE: {
          actions: assign({
            task: (context, event) => ({
              ...context.task,
              startDate: event.startDate
            })
          }),
          target: "update"
        },
        SET_COMPLETED_DATE: {
          actions: assign({
            task: (context, event) => ({
              ...context.task,
              completionDate: event.completionDate
            })
          }),
          target: "update"
        },
        SET_DESCRIPTION: {
          actions: assign({
            task: (context, event) => ({
              ...context.task,
              description: event.description
            })
          }),
          target: "update"
        },
        CHANGE_TASK: {
          actions: assign({
            taskId: (context, event) => event.taskId,
            projectId: (context, event) => event.projectId
          }),
          target: "load"
        },
        SET_DEPENDANCIES: {
          actions: assign({
            dependancies: (context, event) => event.dependancies
          })
        }
      }
    },
    load: {
      invoke: {
        id: "LoadTask",
        src: context =>
          Promise.all([
            api.getTaskById(context.projectId, context.taskId),
            api.getProjectById(context.projectId),
            api.getTaskDependancies(context.projectId, context.taskId),
            api.getTaskMembers(
              context.projectId,
              context.taskId,
              context.userName
            )
          ]),
        onDone: {
          target: "idle",
          actions: assign({
            task: (_, event) => event.data[0],
            project: (_, event) => event.data[1],
            dependancies: (_, event) => event.data[2],
            isUserAssignedToTask: (_, event) => {
              const data = event.data[3];
              if (data && data.payload.length > 0) {
                return true;
              }
              return false;
            }
          })
        },
        onError: {
          target: "error",
          actions: (c, e) => console.error(e)
        }
      }
    },
    update: {
      invoke: {
        id: "UpdateTask",
        src: context => updateTaskRequest(context.task),
        onDone: {
          target: "success"
        },
        onError: {
          target: "error"
        }
      }
    },
    completeTask: {
      invoke: {
        id: "CompleteTask",
        src: context => completeTaskRequest(context.projectId, context.taskId),
        onDone: {
          target: "success",
          actions: assign({
            task: context => {
              return {
                ...context.task,
                isCompleted: true
              };
            }
          })
        },
        onError: {
          target: "error"
        }
      }
    },
    deleteTask: {
      invoke: {
        id: "DeleteTask",
        src: context => deleteTaskRequest(context.task),
        onDone: {
          target: "deleteSuccess",
          actions: assign({
            task: context => {
              return {
                ...context.task,
                isArchived: true
              };
            }
          })
        },
        onError: {
          target: "error"
        }
      }
    },
    success: {
      entry: "showSuccessNotification",
      after: {
        1000: "idle"
      }
    },
    error: {
      entry: "showErrorNotification",
      after: {
        1000: "idle"
      }
    },
    deleteSuccess: {
      entry: "handleDeleteSuccess",
      after: {
        1000: "idle"
      }
    },
    deleteError: {
      entry: "showDeleteErrorNotification",
      after: {
        1000: "idle"
      }
    }
  }
});

export default TaskPageStateMachine;
