import React, { useState, useEffect, useCallback } from "react";
import { useToasts } from "react-toast-notifications";
import { PulseLoader } from "react-spinners";
import User from "../../types/User";
import useDebounce from "../../helpers/useDebounce";
import endpoints, { axios } from "../../config/api";
import ProtectedComponent from "../../components/ProtectedComponent";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import ProjectTeamTabItem from "../ProjectOverviewPage/ProjectTeamTabItem";
import AddTeamMemberModal from "../../components/Modal/AddTeamMemberModal";
import api from "../../api";
import classnames from "classnames";
import { useOnlineProvider } from "../../state/OnlineProvider";

interface Props {
  projectId: number;
  taskId: number;
  isTaskComplete: boolean;
}

const TaskTeamTab = ({ projectId, taskId, isTaskComplete }: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState("");
  const { addToast } = useToasts();
  const { isOnline } = useOnlineProvider();

  useEffect(() => {
    setLoading(true);
    setUsers([]);
    api
      .getTaskMembers(projectId, taskId, debouncedSearchTerm)
      .then(data => {
        setUsers([...data.payload]);
        setLoading(false);
      })
      .catch(_ => {
        addToast("Failed to retrieve users", {
          appearance: "error",
          autoDismiss: true
        });
      });
  }, [addToast, debouncedSearchTerm, projectId, taskId]);

  const addUserToTask = useCallback(
    (userId: string) => {
      api
        .addUserToTask(projectId, taskId, userId)
        .then(() => {
          setIsAddMemberModalOpen(false);
          addToast("User added to task.", {
            appearance: "success",
            autoDismiss: true
          });
        })
        .catch(() => {
          addToast("Failed to add user to task. Try again.", {
            appearance: "error",
            autoDismiss: true
          });
        });
    },
    [addToast, projectId, taskId]
  );

  const removeUserFromTask = useCallback(() => {
    axios
      .delete(endpoints.task.removeUser(projectId, taskId), {
        data: [userToRemove]
      })
      .then(() => {
        setIsDeleteModalOpen(false);
        setUsers(users.filter(u => u.id !== userToRemove));
        addToast("User removed from task", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        setIsDeleteModalOpen(false);
        addToast("Failed to remove user from task", {
          appearance: "error",
          autoDismiss: true
        });
      });
  }, [addToast, taskId, userToRemove, users]);

  return (
    <div>
      <div className="lg:flex lg:flex-row lg:justify-between mt-12">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <ConfirmDeleteModal
            isModalOpen={isDeleteModalOpen}
            setIsModalOpen={setIsDeleteModalOpen}
            onConfirm={removeUserFromTask}
            entityLabel="User from the Task"
          />
          <AddTeamMemberModal
            closeModal={() => setIsAddMemberModalOpen(false)}
            isModalOpen={isAddMemberModalOpen}
            onSubmit={(selectedUser: User) => {
              setIsAddMemberModalOpen(false);
              setUsers([...users, selectedUser]);
              addUserToTask(selectedUser.id);
            }}
            projectId={projectId}
            taskId={taskId}
          />

          <button
            className={classnames("w-full lg:w-56", {
              "btn-secondary": !isTaskComplete && isOnline,
              "btn-disabled": isTaskComplete || !isOnline
            })}
            disabled={isTaskComplete || !isOnline}
            onClick={() => setIsAddMemberModalOpen(true)}
          >
            + Add a team member
          </button>
        </ProtectedComponent>
        <div className="flex flex-col lg:flex-row lg:justify-end lg:items-center">
          <p className="mt-4 lg:my-0 lg:mr-4">Look up a user...</p>
          <input
            className="w-full lg:w-56"
            value={searchTerm}
            placeholder="Enter user's name"
            onChange={e => setSearchTerm(e.target.value)}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.currentTarget.blur();
              }
            }}
          />
        </div>
      </div>
      <div className="mt-8 block">
        <table className="border-collapse space-x-1 mb-8">
          <thead>
            <tr className="bg-white">
              <th className="pb-3">Name</th>
              <th className="w-auto md:w-64 pb-3"></th>
              <th className="invisible sm:visible pb-3">Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={3}>
                  <div className="w-full flex justify-center items-center">
                    <PulseLoader color="#00B2A9" />
                  </div>
                </td>
              </tr>
            )}
            {users && !loading && users.length === 0 && (
              <tr className="text-center ">
                <td colSpan={4}>No Users Found</td>
              </tr>
            )}
            {users &&
              users
                .sort((a: any, b: any) => {
                  if (a.firstName < b.firstName) {
                    return -1;
                  }
                  if (a.firstName > b.firstName) {
                    return 1;
                  }
                  return 0;
                })
                .map((item: any) => (
                  <ProjectTeamTabItem
                    projectId={projectId}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    setUserToRemove={setUserToRemove}
                    item={item}
                  />
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskTeamTab;
