export interface ISettings {
  engineUrl: string;
  enableServiceWorker: boolean;
}

const settings: ISettings = {
    engineUrl: "https://app-applebridgeportal-api.azurewebsites.net/api/v1",
  // engineUrl: "https://applebridge-dev.azurewebsites.net/api/v1"
  enableServiceWorker: false
};

export default settings;
