import React, { Fragment } from "react";
import openButton from "../../assets/img/openButton.svg";
import SidebarHeader from "./SidebarHeader";
import SidebarItem from "./SidebarItem";
import projectsIcon from "../../assets/img/projectsIcon.svg";
import teamsIcon from "../../assets/img/teamsIcon.svg";
import templatesIcon from "../../assets/img/templatesIcon.svg";
import logo from "../../assets/img/logo.svg";
import documentationIcon from "../../assets/img/documentationIcon.svg";
import profileIcon from "../../assets/img/profileIcon.svg";
import notificationIcon from "../../assets/img/notificationIcon.svg";
import hamburger from "../../assets/img/hamburger.svg";
import SidebarDivider from "./SidebarDivider";
import { useSidebar } from "../../state/SidebarProvider";
import ProtectedComponent from "../ProtectedComponent";
import { useOnlineProvider } from "../../state/OnlineProvider";
import { useNotifications } from "../../state/NotificationProvider";

interface Props {
  userName: string;
}

const Sidebar = ({ userName }: Props) => {
  const { closeSidebar, setIsSidebarOpen, isSidebarOpen } = useSidebar();
  const { isOnline } = useOnlineProvider();
  const { totalNotifications } = useNotifications();

  return (
    <Fragment>
      <button
        className={`pip circle ${isSidebarOpen ? "open" : ""}`}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <img src={openButton} alt=">>" />
      </button>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div>
          {!isSidebarOpen && (
            <div className="mobileHeader">
              <div className="mobileHeaderContent">
                <img alt="Applebridge Logo" className="w-8" src={logo} />
                <div className="flex flex-col p-2">
                  <h2 className="p-0">WELCOME, {userName}</h2>
                  {!isOnline && (
                    <small className="text-red -mt-1 text-xxs">
                      Offline Mode
                    </small>
                  )}
                </div>
              </div>
              <img
                id="hamburger"
                alt="hamburger"
                src={hamburger}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              />
            </div>
          )}
        </div>
        <SidebarHeader
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        <SidebarItem
          onClick={closeSidebar}
          imgAlt="projectsIcon"
          imgSrc={projectsIcon}
          label="PROJECTS"
          isSidebarOpen={isSidebarOpen}
          link="/projects"
        />
        <SidebarItem
          onClick={closeSidebar}
          imgAlt="teamsIcon"
          imgSrc={teamsIcon}
          label="TEAMS"
          isSidebarOpen={isSidebarOpen}
          link="/team"
        />
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <SidebarItem
            onClick={closeSidebar}
            imgAlt="templatesIcon"
            imgSrc={templatesIcon}
            label="TEMPLATES"
            isSidebarOpen={isSidebarOpen}
            link="/templates"
          />
        </ProtectedComponent>
        <SidebarItem
          onClick={closeSidebar}
          imgAlt="documentationIcon"
          imgSrc={documentationIcon}
          label="DOCUMENTATION"
          isSidebarOpen={isSidebarOpen}
          link="/documentation"
        />
        <SidebarDivider />
        <SidebarItem
          onClick={closeSidebar}
          imgAlt="notificationIcon"
          imgSrc={notificationIcon}
          label="NOTIFICATIONS"
          isSidebarOpen={isSidebarOpen}
          link="/notifications"
          totalNotifications={totalNotifications}
        />
        <SidebarItem
          onClick={closeSidebar}
          imgAlt="profileIcon"
          imgSrc={profileIcon}
          label="YOUR ACCOUNT"
          isSidebarOpen={isSidebarOpen}
          link="/account"
        />
      </div>
    </Fragment>
  );
};

export default Sidebar;
