import React, { useState, useEffect } from "react";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroller";
import { PulseLoader } from "react-spinners";
import Task from "../../types/Task";
import ProtectedComponent from "../../components/ProtectedComponent";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import { useToasts } from "react-toast-notifications";
import ProjectTaskTabItem from "./ProjectTaskTabItem";
import deleteTask from "../../api/deleteTask";
import { useHistory } from "react-router-dom";
import { useOnlineProvider } from "../../state/OnlineProvider";
import api from "../../api";

interface Props {
  scrollRef: React.MutableRefObject<null>;
  projectId: number;
  getProjectProgress?: any;
}

const ProjectTaskTab = ({
  scrollRef,
  projectId,
  getProjectProgress
}: Props) => {
  const { push } = useHistory();
  const { addToast } = useToasts();
  const [currentTasks, setCurrentTasks] = useState<Task[]>([]);
  const [currentTaskPage, setCurrentTaskPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [taskToDelete, setTaskToDelete] = useState<Task>();
  const [isTaskDeleteModalOpen, setIsTaskDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isOnline } = useOnlineProvider();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await api.getProjectTasks(projectId, 1);
      setCurrentTasks([...currentTasks, ...data.payload]);
      setCurrentTaskPage(data.currentPage);
      setMaxPage(data.maxPage);
      setLoading(false);
    };
    getData();
  }, [projectId]);

  const loadMoreTasks = async (page: any) => {
    const data = await api.getProjectTasks(projectId, page);
    setCurrentTasks(x => [...x, ...data.payload]);
    setCurrentTaskPage(page);
  };

  const deleteSelectedTask = (taskToDelete: Task) => {
    deleteTask(taskToDelete.projectId, taskToDelete.id)
      .then(() => {
        addToast("Task has been deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setCurrentTasks(currentTasks.filter(t => t.id !== taskToDelete.id));
        setTaskToDelete(undefined);
        setIsTaskDeleteModalOpen(false);
        getProjectProgress();
      })
      .catch(() => {
        addToast("Task deletion failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const incompleteTasks = currentTasks
    .filter(t => !t.isCompleted)
    .map(item => (
      <ProjectTaskTabItem
        item={item}
        projectId={projectId}
        setIsTaskDeleteModalOpen={setIsTaskDeleteModalOpen}
        setTaskToDelete={setTaskToDelete}
      />
    ));

  const completeTasks = currentTasks
    .filter(t => t.isCompleted)
    .map(item => (
      <ProjectTaskTabItem
        item={item}
        projectId={projectId}
        setIsTaskDeleteModalOpen={setIsTaskDeleteModalOpen}
        setTaskToDelete={setTaskToDelete}
      />
    ));

  return (
    <div className="flex flex-col flex-1">
      <ProtectedComponent requiredRole="PROJECTMANAGER">
        <button
          disabled={!isOnline}
          onClick={() => push(`/projects/${projectId}/tasks/create`)}
          className={classnames("w-full md:w-56 mt-12", {
            "btn-secondary": isOnline,
            "btn-disabled": !isOnline
          })}
        >
          + Create a task
        </button>
      </ProtectedComponent>
      <div className="mt-12">
        <InfiniteScroll
          pageStart={1}
          threshold={250}
          hasMore={currentTaskPage < maxPage}
          loadMore={loadMoreTasks}
          useWindow={false}
          className="mb-8"
          element="div"
          getScrollParent={() => scrollRef.current}
          loader={
            <div className="w-full flex justify-center items-center">
              <PulseLoader color="#00B2A9" />
            </div>
          }
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="w-1/2 sm:w-1/6">Task Name</th>
                <th className="hidden md:table-cell md:w-1/6 ">Start Date</th>
                <th className="hidden md:table-cell md:w-1/6">
                  Completion Date
                </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <div className="w-full flex justify-center items-center">
                  <PulseLoader color="#00B2A9" />
                </div>
              )}
              {currentTasks && !loading && currentTasks.length === 0 && (
                <tr className="text-center">
                  <td colSpan={4}>No Tasks Found</td>
                </tr>
              )}
              {incompleteTasks}
              {completeTasks.length > 0 && (
                <tr>
                  <td>
                    <p className="font-roboto font-black text-sm text-blue">
                      Completed Tasks
                    </p>
                  </td>
                </tr>
              )}
              {completeTasks}
            </tbody>
          </table>
        </InfiniteScroll>
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <ConfirmDeleteModal
            onConfirm={() => taskToDelete && deleteSelectedTask(taskToDelete)}
            entityLabel="Task"
            setIsModalOpen={setIsTaskDeleteModalOpen}
            isModalOpen={isTaskDeleteModalOpen}
          />
        </ProtectedComponent>
      </div>
    </div>
  );
};

export default ProjectTaskTab;
