import React, { useState, FC, useEffect, useRef, useCallback } from "react";
import useAxios from "axios-hooks";
import endpoints from "../../config/api";
import InfiniteScroll from "react-infinite-scroller";
import { PulseLoader } from "react-spinners";
import ListItem from "../../components/List/ListItem";
import TemplateProjectListItem from "./TemplateProjectListItem";
import ProjectTemplate, {
  ProjectTemplateListResponse
} from "../../types/ProjectTemplate";
import ProtectedComponent from "../../components/ProtectedComponent";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import api from "../../api";
import { useToasts } from "react-toast-notifications";

interface Props {
  scrollRef: React.MutableRefObject<null>;
}

const TemplateProjectsTab: FC<Props> = ({ scrollRef }) => {
  const [currentProjectTemplates, setCurrentProjectTemplates] = useState<
    ProjectTemplate[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [projectTemplateToDelete, setProjectTemplateToDelete] = useState<
    number
  >();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { addToast } = useToasts();

  const [{ loading, data }, getProjectTemplateRequest] = useAxios<
    ProjectTemplateListResponse
  >(
    {
      url: `${endpoints.projectTemplates.getPage(currentPage)}`,
      method: "GET"
    },
    { manual: true }
  );

  const deleteProjectTemplate = useCallback(() => {
    if (projectTemplateToDelete) {
      api
        .deleteProjectTemplate(projectTemplateToDelete)
        .then(() => {
          addToast("Project Template Deleted Successfully", {
            appearance: "success",
            autoDismiss: true
          });
          setCurrentProjectTemplates(
            currentProjectTemplates.filter(
              p => p.id !== projectTemplateToDelete
            )
          );
          setProjectTemplateToDelete(undefined);
          setIsDeleteModalOpen(false);
        })
        .catch(() => {
          addToast("Failed to delete Project Template. Try again.", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  }, [addToast, projectTemplateToDelete, currentProjectTemplates]);

  useEffect(() => {
    getProjectTemplateRequest();
  }, [getProjectTemplateRequest, currentPage]);

  useEffect(() => {
    if (data) {
      setCurrentPage(data.currentPage);
      setMaxPage(data.maxPage);
      setCurrentProjectTemplates([...currentProjectTemplates, ...data.payload]);
    }
  }, [data]);

  return (
    <div className="flex flex-1 bg-white ">
      <div className="flex-1  pt-8 flex flex-col">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <ConfirmDeleteModal
            entityLabel="Project Template"
            isModalOpen={isDeleteModalOpen}
            onConfirm={() => projectTemplateToDelete && deleteProjectTemplate()}
            setIsModalOpen={setIsDeleteModalOpen}
          />
        </ProtectedComponent>
        <h5 className="text-lightBlue">Name</h5>
        <div>
          <InfiniteScroll
            pageStart={1}
            threshold={250}
            hasMore={currentPage < maxPage}
            loadMore={() => setCurrentPage(currentPage + 1)}
            useWindow={false}
            className="mb-16"
            element="div"
            getScrollParent={() => scrollRef.current}
            loader={
              <div className="w-full flex justify-center items-center">
                <PulseLoader color="#00B2A9" />
              </div>
            }
          >
            {loading ? (
              <div className="w-full flex items-center justify-center">
                <PulseLoader color="#00B2A9" />
              </div>
            ) : currentProjectTemplates.length > 0 ? (
              currentProjectTemplates
                .sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                .map(projectTemplate => {
                  return (
                    <TemplateProjectListItem
                      projectTemplate={projectTemplate}
                      setIsDeleteModalOpen={setIsDeleteModalOpen}
                      setProjectTemplateToDelete={setProjectTemplateToDelete}
                    />
                  );
                })
            ) : (
              <ListItem
                containerStyle="bg-white hover:bg-lightGrey"
                label="No Project Templates found"
              />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default TemplateProjectsTab;
