import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";
import endpoints, { axios } from "../config/api";

async function getSubTasks(projectId: number, taskId: number) {
  if (!getOnlineStatus()) {
    const task: any = await database.get(
      `projects:${projectId}:tasks:${taskId}`
    );

    return {
      payload: task.subtasks,
      maxPage: 1,
      currentPage: 1
    };
  }

  const response = await axios.get(
    endpoints.task.getSubTasks(projectId, taskId)
  );
  return response.data;
}

export default getSubTasks;
