const dropzoneAcceptedFileTypes = [
  "image/*",
  "video/*",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/msexcel",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/mspowerpoint",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
];

export default dropzoneAcceptedFileTypes;
