export enum FileType {
  FOLDER = 0,
  FILE = 1
}

interface Document {
  id: string;
  name: string;
  location: string;
  path: string;

  isGlobal: boolean;
  ownerId: string;
  createdAt: Date;
  fileType: FileType;

  parentFolderId: string;
  parentFolderName: string;
}

export interface DocumentWithFile extends Document {
  file?: string;
}

export interface DocumentListResponse {
  payload: Document[];
  pageSize: number;
  currentPage: number;
  maxPage: number;
}

export default Document;
