import React, { FC } from "react";
import { Redirect, Switch } from "react-router-dom";

import ProjectWizard from "../../containers/ProjectWizard/ProjectWizard";
import MainLayout from "../../layouts/MainLayout";
import AccountPage from "../../pages/Account";
import DocumentsPage from "../../pages/Documents";
import NotFoundPage from "../../pages/NotFound";
import NotificationsPage from "../../pages/Notifications";
import ProjectsPage from "../../pages/ProjectsPage";
import TeamPage from "../../pages/Team";
import TemplatesPage from "../../pages/Templates";
import UserPage from "../../pages/User";
import ProjectTemplateWizard from "../../pages/Wizards/ProjectTemplateWizard";
import TaskTemplateWizard from "../../pages/Wizards/TaskTemplateWizard";
import { useAuth } from "../../state/AuthProvider";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Route from "../Route";

const DashboardRedirect = () => <Redirect to="/projects" />;
const NotBuilt = () => (
  <MainLayout>
    <h1>Page Not Built Yet</h1>
  </MainLayout>
);
const App: FC = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  return (
    <MainLayout>
      <Switch>
        <Route
          exact
          path="/"
          component={DashboardRedirect}
          allowOffline={true}
        />
        <Route exact path="/team" component={TeamPage} allowOffline={false} />
        <Route
          exact
          path="/notifications"
          component={NotificationsPage}
          allowOffline={false}
        />
        <Route
          exact
          path="/users/create"
          component={NotBuilt}
          allowOffline={false}
        />
        <Route
          exact
          path="/users/:id"
          component={UserPage}
          allowOffline={false}
        />

        <Route
          exact
          component={AccountPage}
          path="/account"
          allowOffline={true}
        />
        <Route path="/projects" component={ProjectsPage} allowOffline={true} />
        <PrivateRoute
          requiredRole="PROJECTMANAGER"
          exact
          path="/projects/create"
          component={ProjectWizard}
          allowOffline={false}
        />
        <Route
          exact
          path="/documentation"
          component={DocumentsPage}
          allowOffline={false}
        />
        <Route
          exact
          path="/documentation/:folderId"
          component={DocumentsPage}
          allowOffline={false}
        />
        <PrivateRoute
          requiredRole="PROJECTMANAGER"
          exact
          path="/templates"
          component={TemplatesPage}
          allowOffline={false}
        />
        <PrivateRoute
          requiredRole="PROJECTMANAGER"
          exact
          path="/templates/projects/create"
          component={ProjectTemplateWizard}
          allowOffline={false}
        />
        <PrivateRoute
          requiredRole="PROJECTMANAGER"
          exact
          path="/templates/projects/:id"
          component={ProjectTemplateWizard}
          allowOffline={false}
        />
        <PrivateRoute
          requiredRole="PROJECTMANAGER"
          exact
          path="/templates/tasks/create"
          component={TaskTemplateWizard}
        />
        <PrivateRoute
          requiredRole="PROJECTMANAGER"
          exact
          path="/templates/tasks/:id"
          component={TaskTemplateWizard}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </MainLayout>
  );
};

export default App;
