import endpoints, { axios } from "../../config/api";

async function getRootFolderProject(projectId: number) {
  return await (
    await axios.get(endpoints.project.getRootFolder(projectId), {
      maxRedirects: 1
    })
  ).data;
}

export default getRootFolderProject;
