import endpoints, { axios } from "../../config/api";

async function getRootFolderTemplateProject(taskId: number) {
  return await (
    await axios.get(endpoints.projectTemplates.getRootFolder(taskId), {
      maxRedirects: 1
    })
  ).data;
}

export default getRootFolderTemplateProject;
