import endpoints, { axios } from "../config/api";

async function deleteCommercialDocument(projectId: number, id: string) {
  const response = await axios.delete(
    endpoints.commercials.delete(projectId, id)
  );
  return response.data;
}

export default deleteCommercialDocument;
