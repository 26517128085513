import React, { FC, useState, Fragment, useEffect, useRef } from "react";
import ListItem from "../../components/List/ListItem";
import Notification from "../../types/Notification";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ActionIcon from "../../assets/img/options.svg";
import ProtectedComponent from "../../components/ProtectedComponent";
import RedCloseIcon from "../../assets/img/redCloseIcon.svg";
import classnames from "classnames";
import notificationReadIcon from "../../assets/img/notificationRead.svg";
import notificationUnreadIcon from "../../assets/img/notificationUnread.svg";

interface Props {
  notification: Notification;
  markNotificationAsRead: (notificationId: number) => void;
  onClick: (notification: Notification) => void;
  onClose: (notificationId: number) => void;
}

const NotificationListItem: FC<Props> = ({
  notification,
  markNotificationAsRead,
  onClick,
  onClose
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <tr className=" w-full  cursor-pointer" key={notification.id}>
      <td>
        <img
          className="min-w-6 h-6  rounded-full"
          src={
            notification.isRead ? notificationReadIcon : notificationUnreadIcon
          }
          alt=""
        />
      </td>
      <td
        className={classnames("pl-2", {
          "no-underline": notification.isRead,
          underline: !notification.isRead
        })}
        onClick={() => onClick(notification)}
      >
        {notification.name}
        {CheckIsMobile() && (
          <Fragment>
            <br />
            {new Date(notification.createdDate).toLocaleDateString()}
          </Fragment>
        )}
      </td>
      <td
        className={classnames("", {
          hidden: CheckIsMobile()
        })}
        onClick={() => onClick(notification)}
      >
        {new Date(notification.createdDate).toLocaleDateString()}
      </td>
      <td>
        <div>
          <div className="block h-full">
            <Popover
              overlayColor="rgba(0,0,0,0)"
              placement="left-center"
              arrowProps={{ className: "text-white " }}
              arrow={!CheckIsMobile()}
              action={null}
              open={isPopoverOpen}
              initZindex={0}
            >
              <button
                className="py-3 m-0 pr-4 focus:outline-none cursor-pointer h-full "
                onClick={() => setIsPopoverOpen(true)}
                onBlur={() => setIsPopoverOpen(false)}
              >
                <img
                  className="h-full w-full"
                  src={ActionIcon}
                  alt="item options"
                />
              </button>
              <div
                ref={popoverRef}
                className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-xl"
              >
                {!notification.isRead && (
                  <Fragment>
                    <button
                      className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                      onMouseDown={() => {
                        markNotificationAsRead(notification.id);
                        setIsPopoverOpen(false);
                      }}
                    >
                      Mark as Read
                    </button>
                    <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                  </Fragment>
                )}
                <ProtectedComponent requiredRole="PROJECTMANAGER">
                  <button
                    className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                    onMouseDown={() => {
                      onClose(notification.id);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <img className="pr-4" alt="delete" src={RedCloseIcon} />
                    Close
                  </button>
                </ProtectedComponent>
              </div>
            </Popover>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default NotificationListItem;
