import React, { useEffect, useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import ProtectedComponent from "../../components/ProtectedComponent";
import ListItem from "../../components/List/ListItem";
import endpoints, { axios } from "../../config/api";
import TaskTemplate, {
  TaskTemplateListResponse
} from "../../types/TaskTemplate";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import InfiniteScroll from "react-infinite-scroller";
import { PulseLoader } from "react-spinners";
import useAxios from "axios-hooks";

interface Props {
  subtasks: TaskTemplate[];
  setSubtasks: (subtasks: TaskTemplate[]) => void;
  parentTaskId: number;
  scrollRef: any;
}

const TaskTemplateEditSubtaskPage = ({
  subtasks,
  setSubtasks,
  parentTaskId,
  scrollRef
}: Props) => {
  const [isCreatingSubtask, setIsCreatingSubtask] = useState(false);
  const [subtaskToCreateName, setSubtaskToCreateName] = useState("");
  const [subtaskToDelete, setSubtaskToDelete] = useState<number>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const subtaskRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToasts();
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [{ loading, data }, getTaskTemplateSubtaskRequest] = useAxios<
    TaskTemplateListResponse
  >(
    {
      url: `${endpoints.taskTemplates.getSubtasks(parentTaskId, currentPage)}`,
      method: "GET"
    },
    { manual: true }
  );

  useEffect(() => {
    getTaskTemplateSubtaskRequest();
  }, [getTaskTemplateSubtaskRequest, currentPage]);

  const deleteSubTask = () => {
    if (subtaskToDelete) {
      axios
        .delete(endpoints.taskTemplates.delete(subtaskToDelete))
        .then(({ data }) => {
          setSubtasks(subtasks.filter(f => f.id !== subtaskToDelete));
          setSubtaskToDelete(undefined);
          setIsDeleteModalOpen(false);
          addToast("Sucessfully Deleted Subtask", {
            appearance: "success",
            autoDismiss: true
          });
        })
        .catch(() => {
          addToast("Subtask Deletion failed", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  };

  const createSubtask = () => {
    if (subtaskToCreateName.length === 0) {
      setIsCreatingSubtask(false);
      setSubtaskToCreateName("");
      return;
    }

    const formData = new FormData();
    formData.append("name", subtaskToCreateName);
    formData.append("description", subtaskToCreateName);
    formData.append("parentTaskId", parentTaskId.toString());

    axios
      .post(endpoints.taskTemplates.create, formData)
      .then(({ data }) => {
        addToast("Successfully Created Subtask", {
          appearance: "success",
          autoDismiss: true
        });
        setSubtasks([...subtasks, data]);
        setIsCreatingSubtask(false);
        setSubtaskToCreateName("");
      })
      .catch(() => {
        addToast("Subtask Creation failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  useEffect(() => {
    if (isCreatingSubtask && subtaskRef.current) {
      subtaskRef.current && subtaskRef.current.focus();
    }
  }, [subtaskRef, isCreatingSubtask]);

  return (
    <div>
      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        <InfiniteScroll
          pageStart={1}
          threshold={250}
          hasMore={currentPage < maxPage}
          loadMore={() => setCurrentPage(currentPage + 1)}
          useWindow={false}
          element="div"
          getScrollParent={() => scrollRef.current}
          loader={
            <div className="w-full flex justify-center items-center">
              <PulseLoader color="#00B2A9" />
            </div>
          }
        >
          {subtasks.length > 0
            ? subtasks.map(item => (
                <ListItem
                  containerStyle="bg-lightGrey hover:bg-white"
                  label={item.name}
                  labelStyle="no-underline"
                  middleIcon={<div></div>}
                  popover={
                    <ProtectedComponent requiredRole="PROJECTMANAGER">
                      {
                        <div
                          id="deleteIcon"
                          className="cursor-pointer"
                          onClick={() => {
                            setSubtaskToDelete(item.id);
                            setIsDeleteModalOpen(true);
                          }}
                        />
                      }
                    </ProtectedComponent>
                  }
                />
              ))
            : !isCreatingSubtask && (
                <ListItem
                  containerStyle="bg-lightGrey hover:bg-white "
                  label="No Subtasks found"
                />
              )}
          <div
            className={`ListItem  px-0 sm:pr-8 h-full 
          ${isCreatingSubtask ? "flex" : "hidden"} 
            `}
          >
            <div className="sm:pl-2 pl-0 flex-1 justify-between items-center">
              <div className="pl-2">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    createSubtask();
                  }}
                >
                  <input
                    className="w-full"
                    onBlur={() => createSubtask()}
                    placeholder="Subtask Name"
                    value={subtaskToCreateName}
                    onChange={({ target: { value } }) =>
                      setSubtaskToCreateName(value)
                    }
                    autoFocus={true}
                    maxLength={256}
                    ref={subtaskRef}
                  />
                </form>
              </div>
            </div>
            <div className="flex justify-end h-full w-3/12 min-w-3/12">
              <div
                id="deleteIcon"
                className="cursor-pointer"
                onClick={() => {
                  setIsCreatingSubtask(false);
                  setSubtaskToCreateName("");
                }}
              />
            </div>
          </div>
        </InfiniteScroll>
      </div>

      <ProtectedComponent requiredRole="PROJECTMANAGER">
        <ConfirmDeleteModal
          entityLabel="Subtask"
          isModalOpen={isDeleteModalOpen}
          onConfirm={() => subtaskToDelete && deleteSubTask()}
          setIsModalOpen={setIsDeleteModalOpen}
        />
        <div>
          <button
            className="btn-tertiary mt-8"
            onClick={() => {
              setIsCreatingSubtask(true);
            }}
          >
            + Add a subtask
          </button>
        </div>
      </ProtectedComponent>
    </div>
  );
};

export default TaskTemplateEditSubtaskPage;
