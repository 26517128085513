import database from "../database";
import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";

async function getTaskList(projectId: number, page: number) {
  if (!getOnlineStatus()) {
    const tasks = await database.find({
      selector: {
        _id: { $regex: `^projects:${projectId}:tasks:[0-9]+$` }
      }
    });

    return {
      payload: tasks.docs,
      maxPage: 1,
      currentPage: 1
    };
  }

  const response = await axios.get(
    endpoints.task.getProjectTasks(projectId, page)
  );
  return response.data;
}

export default getTaskList;
