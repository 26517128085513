import React from "react";

interface Props {
  progress: number;
}

const ProgressBar = ({ progress }: Props) => {
  return (
    <div className="bg-white w-full h-3">
      <div className="bg-lightBlue h-3" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
