import en from "../locale/en";

const userRoleOptions = (role: string) => {
  switch (role) {
    case "ADMINISTRATOR":
      return [
        { label: en.STANDARDUSER, value: "STANDARDUSER", data: "STANDARDUSER" },
        {
          label: en.PROJECTMANAGER,
          value: "PROJECTMANAGER",
          data: "PROJECTMANAGER"
        },
        {
          label: en.ADMINISTRATOR,
          value: "ADMINISTRATOR",
          data: "ADMINISTRATOR"
        }
      ];
    case "PROJECTMANAGER":
      return [
        { label: en.STANDARDUSER, value: "STANDARDUSER", data: "STANDARDUSER" },
        {
          label: en.PROJECTMANAGER,
          value: "PROJECTMANAGER",
          data: "PROJECTMANAGER"
        }
      ];

    default:
      return [
        { label: en.STANDARDUSER, value: "STANDARDUSER", data: "STANDARDUSER" }
      ];
  }
};

export default userRoleOptions;
