import React, { useState, useEffect, FC, Fragment } from "react";
import api from "../../api";
import TaskTemplate, { TaskTemplateWithKey } from "../../types/TaskTemplate";
import ListItem from "../../components/List/ListItem";
import TaskTemplateAddDependencyModal from "./TaskTemplateAddDependencyModal";
import ProtectedComponent from "../../components/ProtectedComponent";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import shortid from "shortid";

interface Props {
  selectedTemplateDependancies: TaskTemplateWithKey[];
  setSelectedDependancies: (templates: TaskTemplateWithKey[]) => void;
  taskTemplateId: number;
  taskDependencies: any[];
  setTaskDependencies: (dependencies: any[]) => void;
}

const TaskTemplateEditDependancySelectionPage: FC<Props> = ({
  selectedTemplateDependancies,
  setSelectedDependancies,
  taskTemplateId,
  setTaskDependencies,
  taskDependencies
}) => {
  const [templateTasks, setTemplateTasks] = useState<any[]>([]);
  const [isAddDependancyModalOpen, setIsAddDependancyModalOpen] = useState(
    false
  );
  const [
    isDependancyToDeleteModalOpen,
    setIsDependancyToDeleteModalOpen
  ] = useState(false);
  const [dependancyToDelete, setDependancyToDelete] = useState<
    TaskTemplateWithKey
  >();

  useEffect(() => {
    const idArray = selectedTemplateDependancies.map(t => t.id);
    api
      .getAddableDependancyTemplateTasksByIds(JSON.stringify(idArray))
      .then(({ data }) => {
        setTemplateTasks([...data]);
      });
  }, [selectedTemplateDependancies]);

  const addDependenciesToTaskTemplate = (
    newDependency: TaskTemplateWithKey,
    dependenciesToDisplay: TaskTemplate[]
  ) => {
    api
      .addDependancyToTaskTemplate(taskTemplateId, [newDependency.id])
      .then(() => {
        setSelectedDependancies([
          ...selectedTemplateDependancies,
          { ...newDependency, key: shortid.generate() }
        ]);
        setTaskDependencies([
          ...taskDependencies,
          { id: newDependency.key, dependencies: dependenciesToDisplay }
        ]);
      });
  };

  const deleteDependency = () => {
    if (dependancyToDelete) {
      api
        .removeDependancyFromTaskTemplate(taskTemplateId, [
          dependancyToDelete.id
        ])
        .then(() => {
          setSelectedDependancies(
            selectedTemplateDependancies.filter(
              d => d.key !== dependancyToDelete.key
            )
          );
          setIsDependancyToDeleteModalOpen(false);
        });
    }
  };

  return (
    <div>
      <div>
        {selectedTemplateDependancies.length > 0 ? (
          selectedTemplateDependancies.map(dependancy => (
            <Fragment>
              <ListItem
                isClickable={false}
                label={dependancy.name}
                key={dependancy.id}
                middleIcon={<div></div>}
                popover={
                  <div
                    id="deleteIcon"
                    className="cursor-pointer"
                    onClick={() => {
                      setDependancyToDelete(dependancy);
                      setIsDependancyToDeleteModalOpen(true);
                    }}
                  />
                }
              />
              {taskDependencies.find(x => x.id === dependancy.key) &&
                taskDependencies
                  .find(x => x.id === dependancy.key)
                  .dependencies.map((dependency: any) => {
                    return (
                      <ListItem
                        containerStyle="bg-lightGrey hover:bg-white pl-4"
                        label={`> ${dependency.name}`}
                        middleIcon={<div></div>}
                        popover={<div></div>}
                      />
                    );
                  })}
            </Fragment>
          ))
        ) : (
          <ListItem
            containerStyle="bg-lightGrey hover:bg-white "
            label="No Dependencies found"
          />
        )}
      </div>

      <ProtectedComponent requiredRole="PROJECTMANAGER">
        <ConfirmDeleteModal
          entityLabel="Dependency"
          isModalOpen={isDependancyToDeleteModalOpen}
          onConfirm={() => dependancyToDelete && deleteDependency()}
          setIsModalOpen={setIsDependancyToDeleteModalOpen}
        />
        <TaskTemplateAddDependencyModal
          isModalOpen={isAddDependancyModalOpen}
          onSubmit={(selectedTemplate, d) => {
            setIsAddDependancyModalOpen(false);
            addDependenciesToTaskTemplate(selectedTemplate, d);
          }}
          closeModal={() => setIsAddDependancyModalOpen(false)}
          taskTemplateId={taskTemplateId}
        />
        {
          <div>
            <button
              className="btn-tertiary mt-8"
              onClick={() => {
                setIsAddDependancyModalOpen(true);
              }}
            >
              + Add a Dependency
            </button>
          </div>
        }
      </ProtectedComponent>
    </div>
  );
};

export default TaskTemplateEditDependancySelectionPage;
