import React, { Fragment, ReactNode } from "react";
import classnames from "classnames";

interface Props {
  imageUrl?: string;
  middleIcon?: any;
  labelStyle?: string;
  label: string;
  meta?: string;
  popover?: any;
  onClick?: () => void;
  containerStyle?: string;
  isClickable?: boolean;
  children?: ReactNode;
}

const ListItem = ({
  imageUrl,
  middleIcon,
  label,
  meta,
  popover,
  labelStyle = "underline",
  onClick,
  children,
  containerStyle = "bg-lightGrey hover:bg-white",
  isClickable = true
}: Props) => (
  <div className={`${containerStyle} ListItem  px-0 sm:pr-8 h-full`}>
    <div className="sm:pl-2 pl-0 flex flex-1 justify-between items-center">
      {imageUrl && (
        <img className="min-w-6 h-6  rounded-full" src={imageUrl} alt="" />
      )}
      <div className="pl-2">
        {meta ? (
          <Fragment>
            <h4
              onClick={() => onClick && isClickable && onClick()}
              className={classnames(" font-normal break-all", {
                "cursor-pointer": isClickable
              })}
            >
              {label}
            </h4>
            <h5>{meta}</h5>
          </Fragment>
        ) : (
          <h4
            className={classnames(
              `${labelStyle} text-darkGrey font-normal break-all`,
              {
                "cursor-pointer": isClickable
              }
            )}
            onClick={() => onClick && isClickable && onClick()}
          >
            {label}
          </h4>
        )}
      </div>
      {children}
    </div>
    <div className="flex justify-between h-full w-3/12 min-w-3/12">
      {middleIcon && middleIcon}
      {popover && popover}
    </div>
  </div>
);

export default ListItem;
