import React from "react";

interface Props {
  type: number;

  setType: (type: number) => void;
}

const ProjectTypeSelectionPage = ({ type, setType }: Props) => {
  const selectedClass = "btn-primary";
  const nonSelectedClass = "btn-secondary";
  return (
    <div>
      <p>What type of project is it?</p>
      <div className="flex flex-col">
        <button
          className={`${
            type === 0 ? selectedClass : nonSelectedClass
          } w-full justify-start`}
          onClick={() => setType(0)}
        >
          Plots
        </button>
        <button
          className={`${
            type === 1 ? selectedClass : nonSelectedClass
          } w-full justify-start my-3`}
          onClick={() => setType(1)}
        >
          Infrastructure
        </button>
        <button
          className={`${
            type === 2 ? selectedClass : nonSelectedClass
          } w-full justify-start mb-3`}
          onClick={() => setType(2)}
        >
          Infrastructure and Plots
        </button>
        <button
          className={`${
            type === 3 ? selectedClass : nonSelectedClass
          } w-full justify-start mb-3`}
          onClick={() => setType(3)}
        >
          Civils
        </button>
        <button
          className={`${
            type === 4 ? selectedClass : nonSelectedClass
          } w-full justify-start mb-3`}
          onClick={() => setType(4)}
        >
          Utilities
        </button>
        <button
          className={`${
            type === 5 ? selectedClass : nonSelectedClass
          } w-full justify-start`}
          onClick={() => setType(5)}
        >
          Earth Works
        </button>
      </div>
    </div>
  );
};

export default ProjectTypeSelectionPage;
