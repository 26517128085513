import React, {
  useContext,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import useOnlineStatus, { getOnlineStatus } from "../helpers/useOnlineStatus";
import syncOfflineData, { isSyncRequired } from "../helpers/syncOfflineData";
import Modal from "react-responsive-modal";
import { PulseLoader } from "react-spinners";
import usePrevious from "../helpers/usePrevious";
import { useAuth } from "./AuthProvider";

interface OnlineStatusProps {
  isOnline: boolean;
}

export const OnlineStatusContext = React.createContext<OnlineStatusProps>({
  isOnline: getOnlineStatus()
});

export const useOnlineProvider = () => useContext(OnlineStatusContext);

const OnlineStatusProvider: FunctionComponent = ({ children }) => {
  const { isOnline } = useOnlineStatus();
  const [isSyncing, setSyncing] = useState(false);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isOnline || !isAuthenticated) {
      return;
    }

    const syncData = async () => {
      const syncRequired = await isSyncRequired();
      if (!syncRequired) {
        return;
      }

      setSyncing(true);
      await syncOfflineData();
      setTimeout(() => setSyncing(false), 1000);
    };
    syncData();
  }, [isOnline, isAuthenticated]);

  return (
    <OnlineStatusContext.Provider value={{ isOnline }}>
      <Modal
        open={isSyncing}
        onClose={() => {}}
        showCloseIcon={false}
        center={true}
      >
        <h2>Syncing Local Data</h2>
        <p>
          Your data is currently syncing with the main database. <br />
          Please wait...
        </p>
        <div className="flex justify-center">
          <PulseLoader color="#00B2A9" />
        </div>
      </Modal>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export default OnlineStatusProvider;
