import React, { FC, useState, useEffect, useRef } from "react";
import ListItem from "../../components/List/ListItem";
import TaskTemplate from "../../types/TaskTemplate";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ProtectedComponent from "../../components/ProtectedComponent";
import Popover from "react-awesome-popover";
import ActionIcon from "../../assets/img/options.svg";
import { useHistory } from "react-router-dom";
import DeleteHoverIcon from "../../assets/img/deleteHover.svg";
interface Props {
  taskTemplate: TaskTemplate;
  setTaskTemplateToDelete: (taskTemplateId: number) => void;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
}

const TemplateTaskListItem: FC<Props> = ({
  taskTemplate,
  setIsDeleteModalOpen,
  setTaskTemplateToDelete
}) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <ListItem
      onClick={() => history.push(`/templates/tasks/${taskTemplate.id}`)}
      label={taskTemplate.name}
      containerStyle="bg-white hover:bg-lightGrey"
      middleIcon={
        <div>
          <button
            className="cursor-pointer hidden sm:block "
            onClick={() => history.push(`/templates/tasks/${taskTemplate.id}`)}
          >
            <div id="editIcon" className="cursor-pointer  pr-4" />
          </button>
        </div>
      }
      popover={
        <div>
          <div
            id="deleteIcon"
            className="cursor-pointer hidden sm:block"
            onClick={() => {
              setTaskTemplateToDelete(taskTemplate.id);
              setIsDeleteModalOpen(true);
            }}
          />
          <div className="block sm:hidden">
            <Popover
              overlayColor="rgba(0,0,0,0)"
              placement="left-center"
              arrowProps={{ className: "text-white " }}
              arrow={!CheckIsMobile()}
              action={null}
              open={isPopoverOpen}
              initZindex={0}
            >
              <button
                className="py-3 m-0 pr-4 focus:outline-none cursor-pointer "
                onClick={() => setIsPopoverOpen(true)}
                onBlur={() => setIsPopoverOpen(false)}
              >
                <img
                  className="h-full w-full"
                  src={ActionIcon}
                  alt="item options"
                />
              </button>
              <div
                ref={popoverRef}
                className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-xl"
              >
                <button
                  className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                  onMouseDown={() =>
                    history.push(`/templates/tasks/${taskTemplate.id}`)
                  }
                >
                  View Task Template
                </button>
                <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                <ProtectedComponent requiredRole="PROJECTMANAGER">
                  <button
                    className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                    onMouseDown={() => {
                      setTaskTemplateToDelete(taskTemplate.id);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <img
                      src={DeleteHoverIcon}
                      alt="Delete"
                      className="cursor-pointer  pr-4"
                    />
                    Delete Task Template
                  </button>
                </ProtectedComponent>
              </div>
            </Popover>
          </div>
        </div>
      }
    />
  );
};

export default TemplateTaskListItem;
