import React, { FC, useEffect, useState } from "react";
import endpoints, { axios } from "../../config/api";
import Task from "../../types/Task";
import ProtectedComponent from "../../components/ProtectedComponent";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import classNames from "classnames";
import { PulseLoader } from "react-spinners";
import TaskDependancyItem from "./TaskDependancyItem";
import CreateTaskDependancyModal from "./CreateTaskDependancyModal";
import api from "../../api";
import { useToasts } from "react-toast-notifications";
import { useOnlineProvider } from "../../state/OnlineProvider";

interface Props {
  projectId: number;
  taskId: number;
  dependancies: Task[];
  setDependancies: (dependancies: Task[]) => void;
  isCompleted: boolean;
}

const TaskDependancyTab: FC<Props> = ({
  projectId,
  taskId,
  dependancies,
  setDependancies,
  isCompleted
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddDependancyModalOpen, setIsAddDependancyModalOpen] = useState(
    false
  );
  const [dependancyToCreate, setDependancyToCreate] = useState<Task>();
  const [dependancyToDelete, setDependancyToDelete] = useState<Task>();
  const { addToast } = useToasts();
  const { isOnline } = useOnlineProvider();
  const addDependancy = () => {
    if (dependancyToCreate) {
      api
        .addDependancyToTask(projectId, taskId, dependancyToCreate.id)
        .then(() => {
          addToast("Dependancy has been added", {
            appearance: "success",
            autoDismiss: true
          });
          setDependancies([...dependancies, dependancyToCreate]);
          closeCreateModal();
        })
        .catch(() => {
          addToast("Failed to add Dependancy. Please try again", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  };

  const removeDependancies = () => {
    if (dependancyToDelete) {
      api
        .removeDependancyFromTask(projectId, taskId, dependancyToDelete.id)
        .then(() => {
          addToast("Dependancy has been removed", {
            appearance: "success",
            autoDismiss: true
          });
          setDependancies([
            ...dependancies.filter(d => d.id !== dependancyToDelete.id)
          ]);
          closeDeleteModal();
        })
        .catch(() => {
          addToast("Failed to remove Dependancy. Please try again", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  };

  const closeCreateModal = () => {
    setIsAddDependancyModalOpen(false);
    setDependancyToCreate(undefined);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDependancyToDelete(undefined);
  };

  const incompleteTasks = dependancies
    .filter(d => !d.isCompleted)
    .map((item: any) => (
      <TaskDependancyItem
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        item={item}
        setDependancyToDelete={value => {
          setDependancyToDelete(value);
        }}
        projectId={projectId}
      />
    ));

  const completeTasks = dependancies
    .filter(d => d.isCompleted)
    .map((item: any) => (
      <TaskDependancyItem
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        item={item}
        setDependancyToDelete={value => {
          setDependancyToDelete(value);
        }}
        projectId={projectId}
      />
    ));

  return (
    <div>
      {isOnline && !isCompleted && (
        <div className="lg:flex lg:flex-row lg:justify-between mt-12">
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <ConfirmDeleteModal
              isModalOpen={isDeleteModalOpen}
              setIsModalOpen={setIsDeleteModalOpen}
              onConfirm={removeDependancies}
              entityLabel="Dependancy from the Task"
            />
            <CreateTaskDependancyModal
              isModalOpen={isAddDependancyModalOpen}
              onConfirm={() => addDependancy()}
              setIsModalOpen={setIsAddDependancyModalOpen}
              currentTaskId={taskId}
              projectId={projectId}
              selectedTask={dependancyToCreate}
              setSelectedTask={setDependancyToCreate}
              closeModal={closeCreateModal}
            />

            <button
              className={classNames(" w-full lg:w-56", {
                "btn-secondary": true,
                "btn-disabled": false
              })}
              disabled={false}
              onClick={() => setIsAddDependancyModalOpen(true)}
            >
              + Add a Dependancy
            </button>
          </ProtectedComponent>
        </div>
      )}
      <div className="mt-8 block">
        <table className="border-collapse space-x-1 mb-8">
          <thead>
            <tr className="bg-white">
              <th className="pb-3">Name</th>
            </tr>
          </thead>
          <tbody>
            {dependancies && dependancies.length === 0 && (
              <tr className="text-center ">
                <td colSpan={4}>No Dependancies Found</td>
              </tr>
            )}
            {incompleteTasks}
            {completeTasks.length > 0 && (
              <tr>
                <td>
                  <p className="font-roboto font-black text-sm text-blue">
                    Completed Tasks
                  </p>
                </td>
              </tr>
            )}
            {completeTasks}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TaskDependancyTab;
