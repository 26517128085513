import React from "react";
import { useOnlineProvider } from "../../state/OnlineProvider";

interface Props {
  label: string;
  onClick?: Function;
  className?: string;
  allowOffline?: boolean;
}

const Button = ({ label, onClick, className, allowOffline }: Props) => {
  const { isOnline } = useOnlineProvider();
  if (!isOnline && !allowOffline) {
    return (
      <button className={`btn-disabled`} disabled>
        {label}
      </button>
    );
  }

  return (
    <button className={className} onClick={e => onClick && onClick(e)}>
      {label}
    </button>
  );
};

export default Button;
