import React, { FC, useState, useEffect } from "react";

import Modal from "react-responsive-modal";

import { PulseLoader } from "react-spinners";
import classnames from "classnames";
import useDebounce from "../../helpers/useDebounce";
import TaskTemplate, { TaskTemplateWithKey } from "../../types/TaskTemplate";
import endpoints, { axios } from "../../config/api";
import api from "../../api";
import TextInput from "../../components/Input/TextInput";
import ProjectTemplateAddTaskDependencyConfirmModal from "../ProjectTemplateWizard/components/ProjectTemplateAddTaskDependencyConfirmModal";

interface Props {
  isModalOpen: boolean;
  onSubmit: (
    selectedTemplate: TaskTemplateWithKey,
    dependenciesToDisplay: TaskTemplate[]
  ) => void;
  closeModal: () => void;
  taskTemplateId: number;
}

const TaskTemplateAddDependencyModal: FC<Props> = ({
  closeModal,
  isModalOpen,
  onSubmit,
  taskTemplateId
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplateWithKey[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<
    TaskTemplateWithKey
  >();
  const [dependenciesToAdd, setDependenciesToAdd] = useState<TaskTemplate[]>(
    []
  );
  const [isDependencyModalOpen, setIsDependancyModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      setLoading(true);
      setTaskTemplates([]);
      axios
        .get(endpoints.taskTemplates.getAddableTaskDependancies(taskTemplateId))
        .then(({ data }) => {
          setTaskTemplates([...data]);
          setLoading(false);
        })
        .catch(error => console.log(error));
    }
  }, [taskTemplateId, isModalOpen]);

  const checkForDependencies = (taskTemplate: TaskTemplateWithKey) => {
    api.getTaskTemplateDependancies(taskTemplate.id).then(data => {
      if (data.length === 0) {
        onSubmit(taskTemplate, []);
        return;
      }
      setSelectedTaskTemplate(taskTemplate);
      setDependenciesToAdd(data);
      setIsDependancyModalOpen(true);
    });
  };

  return (
    <Modal
      onClose={() => {
        closeModal();
      }}
      open={isModalOpen}
    >
      <h2>Add a Task</h2>
      <div>
        <TextInput
          className="border-2"
          label="Search for a Task to add"
          placeholder="Enter a task name"
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          maxLength={256}
        />
        <div>
          {loading ? (
            <div className="flex justify-center">
              <PulseLoader color="#00B2A9" />
            </div>
          ) : (
            taskTemplates.slice(0, 10).map(tt => (
              <div
                className={`flex flex-row py-1 px-2 cursor-pointer ${
                  selectedTaskTemplate === tt ? "bg-lightGrey" : ""
                }`}
                onClick={() => setSelectedTaskTemplate(tt)}
              >
                <p>{tt.name}</p>
              </div>
            ))
          )}
          {!loading && taskTemplates.length === 0 && (
            <div className="flex flex-row py-1 px-2 max-w-xs">
              <p className="text-center">
                Sorry, there are no available tasks which can be assigned as a
                dependency
              </p>
            </div>
          )}
        </div>
        <div className="pt-4">
          <p>
            Selected Task: {selectedTaskTemplate && selectedTaskTemplate.name}
          </p>
        </div>
        <div className="flex flex-row justify-between pt-4">
          <button
            className="btn-secondary border-red text-red hover:border-red hover:text-red w-32"
            onClick={() => {
              setSearchTerm("");
              setSelectedTaskTemplate(undefined);
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            className={classnames("w-32", {
              "btn-primary": selectedTaskTemplate,
              "btn-disabled": !selectedTaskTemplate
            })}
            onClick={() => {
              if (onSubmit && selectedTaskTemplate) {
                checkForDependencies(selectedTaskTemplate);
                setSelectedTaskTemplate(undefined);
              }
            }}
          >
            Add Task
          </button>
        </div>
        <ProjectTemplateAddTaskDependencyConfirmModal
          onSubmit={() => {
            selectedTaskTemplate &&
              onSubmit(selectedTaskTemplate, dependenciesToAdd);
            setSearchTerm("");
            setSelectedTaskTemplate(undefined);
            setDependenciesToAdd([]);
          }}
          isModalOpen={isDependencyModalOpen}
          closeModal={() => setIsDependancyModalOpen(false)}
          dependanciesToAdd={dependenciesToAdd}
        />
      </div>
    </Modal>
  );
};

export default TaskTemplateAddDependencyModal;
