import endpoints, { axios } from "../../config/api";

async function replaceFile(folderId: string, fileId: string, file: File) {
  const documentToCreate = new FormData();
  documentToCreate.append("file", file);

  const response = await axios.post(
    endpoints.newfolders.replace(folderId, fileId),
    documentToCreate
  );

  return response.data;
}

export default replaceFile;
