import React, { useState, useEffect, FC, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";
import classNames from "classnames";
import Folder from "../../../types/Folder";
import api from "../../../api";
import { PulseLoader } from "react-spinners";
import ProtectedComponent from "../../../components/ProtectedComponent";
import { useToasts } from "react-toast-notifications";
import ConfirmDeleteModal from "../../../components/Modal/ConfirmDeleteModal";
import { AxiosError } from "axios";
import CreateSubfolderModal from "../../DocumentPage/Subfolder/CreateSubfolderModal";
import UpdateSubfolderModal from "../../DocumentPage/Subfolder/UpdateSubfolderModal";
import ProjectSubfolderListItem from "./ProjectSubfolderListItem";
import Button from "../../../components/Button";
import { useHistory } from "react-router-dom";
import { useOnlineProvider } from "../../../state/OnlineProvider";
import Document, { FileType } from "../../../types/Document";
interface Props {
  scrollRef: any;
  isCreateSubfolderModalOpen: boolean;
  setIsCreateSubfolderModalOpen: (isOpen: boolean) => void;
  folderId: string;
  subfolders: Document[];
  setSubfolders: (docs: Document[]) => void;
  urlPrefix: string;
  subfolderClickOverride?: (folder: string) => void;
}

const ProjectSubfolderList: FC<Props> = ({
  scrollRef,
  setIsCreateSubfolderModalOpen,
  isCreateSubfolderModalOpen,
  folderId,
  subfolders,
  setSubfolders,
  urlPrefix,
  subfolderClickOverride
}) => {
  const [maxSubfolderPage, setMaxSubfolderPage] = useState(1);
  const [currentSubfolderPage, setCurrentSubfolderPage] = useState(1);

  const [subfolderToCreateName, setSubfolderToCreateName] = useState("");
  const [subfolderToCreateNameError, setSubfolderToCreateNameError] = useState(
    ""
  );
  const [subfolderToUpdateName, setSubfolderToUpdateName] = useState("");
  const [subfolderToUpdateNameError, setSubfolderToUpdateNameError] = useState(
    ""
  );
  const [subfolderToUpdateId, setSubfolderToUpdateId] = useState<string>();
  const [subfolderToUpdateParentId, setSubfolderToUpdateParentId] = useState<
    string
  >();

  const [loading, setLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const { addToast } = useToasts();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState<string>();
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const { push } = useHistory();
  const { isOnline } = useOnlineProvider();

  const createSubfolder = () => {
    if (!subfolderToCreateName || subfolderToCreateName.trim().length === 0) {
      setSubfolderToCreateNameError("Folder Name is required");
      return;
    }
    setIsCreateLoading(true);
    api.newFolders
      .createNewSubfolder(folderId, subfolderToCreateName)
      .then(data => {
        console.log(data);
        setSubfolders(
          [...subfolders, data].sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
        setIsCreateLoading(false);
        closeCreateModal();
        addToast("Folder Created Successfully", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch((error: AxiosError) => {
        setIsCreateLoading(false);
        if (error.response?.status === 400) {
          addToast("Failed to Create Folder. Try again with a different name", {
            appearance: "error",
            autoDismiss: true
          });
        } else {
          addToast("Failed to Create Folder. Try again", {
            appearance: "error",
            autoDismiss: true
          });
        }
      });
  };

  const updateSubfolder = () => {
    if (!subfolderToUpdateId) {
      return;
    }
    if (!subfolderToUpdateName || subfolderToUpdateName.trim().length === 0) {
      setSubfolderToUpdateNameError("Folder Name is required");
      return;
    }
    setIsUpdateLoading(true);

    api.newFolders
      .updateNewFolder(subfolderToUpdateId, subfolderToUpdateName)
      .then(({ data }) => {
        const subfolderToUpdate = subfolders.findIndex(
          f => f.id === subfolderToUpdateId
        );
        const updatedSubfolders = [...subfolders];
        updatedSubfolders[subfolderToUpdate] = {
          ...updatedSubfolders[subfolderToUpdate],
          name: subfolderToUpdateName
        };
        setSubfolders(updatedSubfolders);
        setIsUpdateLoading(false);
        closeUpdateModal();
        addToast("Folder Updated Successfully", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        setIsUpdateLoading(false);
        addToast("Failed to Update Folder. Try again", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const deleteFolder = (folderId: string) => {
    api.newFolders
      .deleteNewFolder(folderId)
      .then(() => {
        addToast("Folder Successfully Deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setIsDeleteModalOpen(false);
        setSubfolders(subfolders.filter(f => f.id !== folderId));
        setFolderToDelete(undefined);
      })
      .catch(() => {
        addToast("Failed to delete folder", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const closeCreateModal = () => {
    setSubfolderToCreateName("");
    setIsCreateSubfolderModalOpen(false);
  };

  const closeUpdateModal = () => {
    setSubfolderToUpdateName("");
    setSubfolderToUpdateId(undefined);
    setIsUpdateModalOpen(false);
  };

  return (
    <Fragment>
      <ProtectedComponent requiredRole="PROJECTMANAGER">
        <CreateSubfolderModal
          closeModal={() => closeCreateModal()}
          nameError={subfolderToCreateNameError}
          onFolderNameChange={value => setSubfolderToCreateName(value)}
          onSubmit={() => createSubfolder()}
          folderName={subfolderToCreateName}
          isModalOpen={isCreateSubfolderModalOpen}
          isSubfolderCreating={isCreateLoading}
        />
      </ProtectedComponent>
      <ProtectedComponent requiredRole="ADMINISTRATOR">
        <ConfirmDeleteModal
          entityLabel="Folder"
          isModalOpen={isDeleteModalOpen}
          onConfirm={() => folderToDelete && deleteFolder(folderToDelete)}
          setIsModalOpen={setIsDeleteModalOpen}
          warningLabel={
            "This action will delete all subfolders and documents within this folder. This is a permanent action and cannot be reversed"
          }
        />
      </ProtectedComponent>
      <ProtectedComponent requiredRole="PROJECTMANAGER">
        <UpdateSubfolderModal
          closeModal={() => closeUpdateModal()}
          nameError={subfolderToUpdateNameError}
          onFolderNameChange={value => setSubfolderToUpdateName(value)}
          onSubmit={() => updateSubfolder()}
          folderName={subfolderToUpdateName}
          isModalOpen={isUpdateModalOpen}
          isSubfolderUpdating={isUpdateLoading}
        />
      </ProtectedComponent>

      {!isOnline ? (
        <div className="w-full flex justify-center items-center my-4">
          Project Folders are only available in online mode. You should download
          any required documents whilst you are online.
        </div>
      ) : loading ? (
        <div className="w-full flex justify-center items-center">
          <PulseLoader color="#00B2A9" />
        </div>
      ) : (
        <InfiniteScroll
          pageStart={1}
          threshold={150}
          hasMore={!isMoreLoading && currentSubfolderPage < maxSubfolderPage}
          loadMore={() => {}}
          useWindow={false}
          className={classNames(
            "sm:flex flex-wrap justify-between sm:justify-start",
            {
              "mb-8 pt-0": subfolders.length > 0,
              "pt-16": subfolders.length === 0
            }
          )}
          element="div"
          getScrollParent={() => scrollRef.current}
          loader={
            <div className="w-full flex justify-center items-center">
              <PulseLoader color="#00B2A9" />
            </div>
          }
        >
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <PulseLoader color="#00B2A9" />
            </div>
          ) : (
            subfolders.length > 0 &&
            subfolders
              .filter(x => x.fileType == FileType.FOLDER)
              .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
              .map(subfolder => {
                return (
                  <ProjectSubfolderListItem
                    folder={subfolder}
                    setFolderToDelete={(folderId: string) =>
                      setFolderToDelete(folderId)
                    }
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                    setFolderToUpdate={(folder: Document) => {
                      setSubfolderToUpdateName(folder.name);
                      setSubfolderToUpdateId(folder.id);
                      setSubfolderToUpdateParentId(folder.parentFolderId);
                    }}
                    setIsUpdateModalOpen={setIsUpdateModalOpen}
                    urlPrefix={urlPrefix}
                    subfolderClickOverride={subfolderClickOverride}
                  />
                );
              })
          )}
        </InfiniteScroll>
      )}
    </Fragment>
  );
};

export default ProjectSubfolderList;
