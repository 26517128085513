import classnames from "classnames";
import React, { FC, Fragment, useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import { RouteChildrenProps, useHistory } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { useToasts } from "react-toast-notifications";

import { useMachine } from "@xstate/react";

import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import EditIcon from "../../assets/img/editIcon.svg";
import Header from "../../components/Header";
import InlineEditableDateInput from "../../components/Input/InlineEditableDateInput";
import InlineEditableTextAreaInput from "../../components/Input/InlineEditableTextAreaInput";
import InlineEditableTypeInput from "../../components/Input/InlineEditableTypeInput";
import AddTeamMemberModal from "../../components/Modal/AddTeamMemberModal";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import ProtectedComponent from "../../components/ProtectedComponent";
import Tab from "../../components/Tab/Tab";
import Tabs from "../../components/Tab/Tabs";
import ProjectCommercialTab from "../../containers/ProjectOverviewPage/ProjectCommercialTab";
import ProjectDocumentTab from "../../containers/ProjectOverviewPage/ProjectDocumentTab";
import ProjectTaskTab from "../../containers/ProjectOverviewPage/ProjectTaskTab";
import ProjectTeamTab from "../../containers/ProjectOverviewPage/ProjectTeamTab";
import checkUserRole from "../../helpers/checkUserRole";
import useOnlineStatus, {
  getOnlineStatus
} from "../../helpers/useOnlineStatus";
import { useAuth } from "../../state/AuthProvider";
import { useOnlineProvider } from "../../state/OnlineProvider";
import User from "../../types/User";
import ProjectOverviewMachine from "./ProjectOverviewMachine";
import ProjectTypeMap from "../../helpers/projectTypeMap";

interface Match {
  projectId: string;
  tab: string;
  id: string;
}

const ProjectOverview: FC<RouteChildrenProps<Match>> = ({ match }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const scrollRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();
  const { user } = useAuth();
  const { isOnline } = useOnlineProvider();
  const currentTab = match && match.params ? match.params.tab : "";
  const projectId =
    match && match.params ? parseInt(match.params.projectId) : 0;
  const entityId = match && match.params ? match.params.id : "";

  const [machine, send] = useMachine(ProjectOverviewMachine, {
    context: {
      projectId: projectId
    },
    actions: {
      projectNotFoundNotification: () =>
        addToast("Project not found", {
          appearance: "info",
          autoDismiss: true
        }),
      successfullyUpdatedProjectNotification: () =>
        addToast("Successfully updated Project", {
          appearance: "success",
          autoDismiss: true
        }),
      successfullyDeletedProjectNotification: () =>
        addToast("Successfully deleted Project", {
          appearance: "success",
          autoDismiss: true
        }),
      failedToUpdateProjectNotification: () =>
        addToast("Failed to update Project. Try again", {
          appearance: "error",
          autoDismiss: true
        })
    }
  });

  const projectOverviewTabs = [
    "overview",
    "tasks",
    "team",
    "documents",
    "commercial"
  ];

  const getDefaultTab = () => {
    if (currentTab) {
      const tabIndex = projectOverviewTabs.findIndex(t => t === currentTab);
      if (tabIndex) {
        return tabIndex;
      }
    }
    return 0;
  };

  const { project } = machine.context;
  if (machine.value === "loading" || !project) {
    return <PulseLoader color="#D1D1D1" size="8" />;
  }

  if (!projectId) {
    history.push("/projects");
    return null;
  }

  const now = new Date();
  const start = new Date(project.startDate);
  const end = new Date(project.expectedCompletionDate);
  const totalTime = end.getTime() - start.getTime();
  const progress = Math.round(
    now < start
      ? 0
      : Math.min(100, ((now.getTime() - start.getTime()) / totalTime) * 100)
  );

  return (
    <div className="h-screen overflow-auto bg-white ">
      <div className="flex flex-col flex-1">
        <Header
          title={project.name}
          isEditable={true}
          onEdit={e =>
            send("SET_NAME", {
              name: e
            })
          }
        />
        <div className="w-full px-8 py-10 bg-lightGrey">
          <div className="flex justify-between">
            <h2>Project Status</h2>
            <h2 className="text-lightBlue">{progress}% Complete</h2>
          </div>
          <ProgressBar progress={progress} />
        </div>
        <div className="w-full px-8 pt-2 bg-white flex-1 pb-20 md:pb-0">
          <Tabs defaultTab={getDefaultTab()} fluid>
            <Tab label="Overview" allowedOffline={true}>
              <div className="flex flex-col md:flex-row py-10 ">
                <div className="flex flex-col mb-4 md:mb-0 md:w-40 md:mr-10 ">
                  <div className="flex flex-col">
                    <h5 className="text-lightBlue">Start Date</h5>

                    <InlineEditableDateInput
                      placeholder=""
                      value={project?.startDate}
                      onChange={e =>
                        send("SET_START_DATE", {
                          startDate: e
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col mt-2">
                    <h5 className="text-lightBlue">Completion Date</h5>
                    <InlineEditableDateInput
                      placeholder=""
                      minDate={project?.startDate}
                      value={project?.expectedCompletionDate}
                      onChange={e =>
                        send("SET_EXPECTED_COMPLETED_DATE", {
                          expectedCompletionDate: e
                        })
                      }
                    />
                  </div>
                  <div className="flex flex-col mt-2">
                    <h5 className="text-lightBlue">Project Type</h5>
                    <InlineEditableTypeInput
                      options={[
                        "Plots",
                        "Infrastructure",
                        "Infrastructure and Plots",
                        "Civils",
                        "Utilities",
                        "Earthworks"
                      ]}
                      value={
                        Object.keys(ProjectTypeMap)[
                          Object.values(ProjectTypeMap).findIndex(
                            v => v === project.type
                          )
                        ]
                      }
                      onChange={e => {
                        send("SET_PROJECT_TYPE", {
                          projectType: ProjectTypeMap[e]
                        });
                      }}
                    />
                  </div>
                  <div className="flex flex-col mt-2">
                    <h5 className="text-lightBlue">Site Manager</h5>
                    <div className="flex flex-row justify-between items-center">
                      <h4>
                        {project.siteManager?.firstName +
                          " " +
                          project.siteManager?.lastName}
                      </h4>
                      <ProtectedComponent requiredRole="PROJECTMANAGER">
                        {getOnlineStatus() && (
                          <Fragment>
                            <p className="ml-4 InlineEditIconContainer">
                              <img
                                src={EditIcon}
                                alt="editable input icon"
                                onClick={() => setIsAddMemberModalOpen(true)}
                              />
                            </p>
                            <AddTeamMemberModal
                              closeModal={() => setIsAddMemberModalOpen(false)}
                              isModalOpen={isAddMemberModalOpen}
                              modalHeader="Assign a site manager"
                              onSubmit={(selectedUser: User) => {
                                setIsAddMemberModalOpen(false);
                                send("SET_SITE_MANAGER", {
                                  siteManager: selectedUser
                                });
                              }}
                              projectId={projectId}
                              searchAllUsers={true}
                            />
                          </Fragment>
                        )}
                      </ProtectedComponent>
                    </div>
                  </div>
                  {user && checkUserRole("PROJECTMANAGER", user.role) && (
                    <div className="flex flex-row pt-4 md:pt-8">
                      <button
                        className={classnames(
                          "btn-tertiary flex flex-row font-normal",
                          { "text-red": isOnline, "text-darkGrey": !isOnline }
                        )}
                        disabled={!isOnline}
                        onClick={() => setIsModalOpen(true)}
                      >
                        <img
                          src={deleteHoverIcon}
                          className="pr-4 "
                          alt="Delete"
                        />
                        Delete Project
                      </button>
                      <Modal
                        center={true}
                        onClose={() => setIsModalOpen(false)}
                        open={isModalOpen}
                        showCloseIcon={false}
                      >
                        <h2>Are you sure you want to delete this Project?</h2>
                        <h4 className="font-normal">
                          This is a permanent action and cannot be reversed
                        </h4>
                        <div className="flex flex-col-reverse sm:flex-row  mt-8">
                          <button
                            className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
                            onClick={() => setIsModalOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-primary  w-full sm:w-56"
                            onClick={() => send("DELETE")}
                          >
                            Delete
                          </button>
                        </div>
                      </Modal>
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <h5 className="text-lightBlue">Project Description</h5>
                  <InlineEditableTextAreaInput
                    value={project?.description}
                    placeholder="Description"
                    onChange={value =>
                      send("SET_DESCRIPTION", { description: value })
                    }
                  />
                </div>
              </div>
            </Tab>
            <Tab label="Tasks" allowedOffline={true}>
              <ProjectTaskTab
                projectId={projectId}
                scrollRef={scrollRef}
                getProjectProgress={() => {
                  send("GET_PROJECT_PROGRESS");
                }}
              />
            </Tab>
            <Tab label="Team" allowedOffline={true}>
              <ProjectTeamTab projectId={projectId} />
            </Tab>
            <Tab label="Documents" allowedOffline={true}>
              <ProjectDocumentTab projectId={projectId} entityId={entityId} />
            </Tab>
            {/* {checkUserRole("PROJECTMANAGER", user.role) ? ( */}
            <Tab label="Commercial">
              <ProjectCommercialTab projectId={projectId} />
            </Tab>
            {/* ) : (
              <Fragment />
            )} */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverview;
