import React, {
  useContext,
  FunctionComponent,
  useState,
  useEffect,
  useReducer
} from "react";
import { AxiosError } from "axios";
import User from "../types/User";
import { setItem, getItem } from "../services/LocalStorage";
import ConfirmLogoutModal from "../components/Modal/ConfirmLogoutModal";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import { useHistory } from "react-router-dom";
import api from "../api";
import { useToasts } from "react-toast-notifications";

interface AuthProps {
  login?(username: string, password: string): void;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User;
  error: AxiosError | undefined;
  token: string | undefined;
  updateUser: (user: any) => void;
  logout: () => void;
  checkLogin: () => void;
}

const USER_STORAGE_TOKEN = "applebridge:user";
const TOKEN_STORAGE_TOKEN = "applebridge:token";
const JWT_TOKEN = "jwtToken";

export const AuthContext = React.createContext<AuthProps>({
  isAuthenticated: false,
  isLoading: false,
  updateUser: () => {},
  user: {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    role: "user",
    profileImage: ""
  },
  error: undefined,
  token: undefined,
  logout: () => {},
  checkLogin: () => {}
});

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FunctionComponent = ({ children }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError>();
  const [showLogoutConfirm, setShowLogoutConfim] = useState(false);
  const [user, setUser] = useState<User>({
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    profileImage: "",
    role: ""
  });
  const [data, setData] = useState<any>();
  const [apiError, setApiError] = useState<any>();

  const login = async (username: string, password: string) => {
    setLoading(true);
    await api
      .login(username, password)
      .then(d => {
        setApiError(undefined);
        setData(d.data);
        setLoading(false);
      })
      .catch(e => {
        setApiError(e);
        setLoading(false);
      });
  };

  const updateUser = (user: User) => {
    setUser(user);
    setItem(USER_STORAGE_TOKEN, user, 3600 * 1000);
  };

  const logout = () => {
    if (getOnlineStatus()) {
      confirmLogout();
      return;
    }

    setShowLogoutConfim(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem(USER_STORAGE_TOKEN);
    localStorage.removeItem(TOKEN_STORAGE_TOKEN);
    localStorage.removeItem(JWT_TOKEN);
    setIsAuthenticated(false);
    setUser({
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      profileImage: "",
      role: ""
    });
    setToken(undefined);
    if (history) {
      history.push("/login");
    }
  };

  const checkLogin = () => {
    const userCheck = getItem(USER_STORAGE_TOKEN);
    const tokenCheck = getItem(TOKEN_STORAGE_TOKEN);
    if (!userCheck || !tokenCheck) {
      setIsAuthenticated(false);
      if (token) {
        addToast("Your session has expired. Please login again", {
          appearance: "error",
          autoDismiss: true
        });
        setToken(undefined);
      }
      return;
    }

    setUser(userCheck);
    setToken(tokenCheck);
    setIsAuthenticated(true);
  };

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (apiError) {
      setError(apiError);
      return;
    }

    if (!data) {
      return;
    }

    if (data.user && data.token) {
      setError(undefined);
      setUser(data.user);
      setToken(data.token);
      setIsAuthenticated(true);
      setItem(USER_STORAGE_TOKEN, data.user, 3600 * 24 * 7 * 1000);
      setItem(TOKEN_STORAGE_TOKEN, data.token, 3600 * 24 * 7 * 1000);
    }
  }, [apiError, data]);

  return (
    <AuthContext.Provider
      value={{
        login,
        isLoading,
        user,
        updateUser,
        isAuthenticated,
        error,
        token,
        logout,
        checkLogin
      }}
    >
      <ConfirmLogoutModal
        isModalOpen={showLogoutConfirm}
        onConfirm={() => confirmLogout()}
        setIsModalOpen={() => setShowLogoutConfim(!showLogoutConfirm)}
      />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
