import React, { useState, FC } from "react";

import { useToasts } from "react-toast-notifications";
import useAxios from "axios-hooks";
import endpoints, { axios } from "../../config/api";
import CreateDocumentModal from "../DocumentPage/CreateDocumentModal";
import DeleteDocumentModal from "../DocumentPage/DeleteDocumentModal";
import dropzoneAcceptedFileTypes from "../../helpers/dropzoneAcceptedFileTypes";
import ListItem from "../../components/List/ListItem";
import ProtectedComponent from "../../components/ProtectedComponent";
import Document from "../../types/Document";
import api from "../../api";
import settings from "../../config/settings";

interface Props {
  taskTemplateId: number;
  createdDocuments: Document[];
  setCreatedDocuments: (documents: Document[]) => void;
  templateFolder: Document;
}

const TemplateTaskEditDocumentsTab: FC<Props> = ({
  taskTemplateId,
  createdDocuments,
  setCreatedDocuments,
  templateFolder
}) => {
  const { addToast } = useToasts();

  const [documentToCreateFile, setDocumentToCreateFile] = useState<File>();
  const [documentToCreateFileError, setDocumentToCreateFileError] = useState(
    ""
  );

  const [documentToCreateName, setDocumentToCreateName] = useState("");
  const [documentToCreateNameError, setDocumentToCreateNameError] = useState(
    ""
  );
  const [documentToCreatePrivate, setDocumentToCreatePrivate] = useState(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<string>();

  const createDocument = () => {
    if (!documentToCreateName || documentToCreateName.trim().length === 0) {
      setDocumentToCreateNameError("Please provide a file name");
      return;
    }
    setDocumentToCreateNameError("");
    if (!documentToCreateFile) {
      setDocumentToCreateFileError("Please provide a file to upload");
      return;
    }
    setDocumentToCreateFileError("");

    api.newFolders
      .uploadFile(
        templateFolder.id,
        documentToCreateName,
        documentToCreatePrivate,
        documentToCreateFile
      )
      .then(createDocumentResponse => {
        setCreatedDocuments([...createdDocuments, createDocumentResponse]);
        closeCreateModal();
        addToast("Document Created Successfully", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        addToast("Failed to Create document. Try again", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const deleteDocument = (documentId: string) => {
    api.newFolders
      .deleteFile(templateFolder.id, documentId)
      .then(() => {
        addToast("Document Successfully Deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setCreatedDocuments(
          createdDocuments.filter(doc => doc.id !== documentId)
        );
        setIsDeleteModalOpen(false);
        setDocumentToDelete(undefined);
      })
      .catch(() => {
        addToast("Failed to delete document. Try again", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const downloadDocument = (location: string) => {
    window.open(location, "_blank");
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setDocumentToCreateFile(undefined);
    setDocumentToCreateName("");
    setDocumentToCreatePrivate(false);
  };
  return (
    <div>
      <CreateDocumentModal
        documentName={documentToCreateName}
        dropzoneLabel="Drop your document here or click to open the file explorer"
        isModalOpen={isCreateModalOpen}
        onDocumentNameChange={value => setDocumentToCreateName(value)}
        onDocumentUpload={file => setDocumentToCreateFile(file)}
        onSubmit={() => createDocument()}
        setIsModalOpen={setIsCreateModalOpen}
        acceptedFileName={documentToCreateFile?.name}
        nameError={documentToCreateNameError}
        isPrivate={documentToCreatePrivate}
        setIsPrivate={setDocumentToCreatePrivate}
        closeModal={() => closeCreateModal()}
        dropzoneError={documentToCreateFileError}
        isDocumentCreating={false}
        acceptedFileTypes={dropzoneAcceptedFileTypes}
        disableFolderSelect
      />
      <DeleteDocumentModal
        setIsModalOpen={setIsDeleteModalOpen}
        isModalOpen={isDeleteModalOpen}
        onConfirm={() => documentToDelete && deleteDocument(documentToDelete)}
      />
      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        <div>
          {createdDocuments.length > 0 ? (
            createdDocuments
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map(document => {
                return (
                  <ListItem
                    containerStyle="bg-lightGrey hover:bg-white"
                    label={document.name}
                    labelStyle="no-underline"
                    middleIcon={
                      <div
                        className="cursor-pointer hidden sm:block"
                        id="downloadIcon"
                        onClick={() =>
                          downloadDocument(
                            `${
                              settings.engineUrl
                            }${endpoints.newfolders.downloadFile(
                              templateFolder.id,
                              document.id
                            )}`
                          )
                        }
                      />
                    }
                    popover={
                      <ProtectedComponent requiredRole="PROJECTMANAGER">
                        <div
                          id="deleteIcon"
                          className="cursor-pointer hidden sm:block"
                          onClick={() => {
                            setDocumentToDelete(document.id);
                            setIsDeleteModalOpen(true);
                          }}
                        />
                      </ProtectedComponent>
                    }
                  />
                );
              })
          ) : (
            <ListItem
              containerStyle="bg-lightGrey hover:bg-white "
              label="No Documents found"
            />
          )}

          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <button
              className="btn-tertiary mt-8"
              onClick={() => setIsCreateModalOpen(true)}
            >
              + Upload a Document
            </button>
          </ProtectedComponent>
        </div>
      </div>
    </div>
  );
};

export default TemplateTaskEditDocumentsTab;
