import endpoints, { axios } from "../../config/api";

async function copyFile(
  folderId: string,
  fileId: string,
  name: string,
  parentFolderId: string
) {
  return await (
    await axios.post(endpoints.newfolders.copyFile(folderId, fileId), {
      name,
      parentFolderId
    })
  ).data;
}

export default copyFile;
