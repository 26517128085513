import React, { FunctionComponent } from "react";

export enum AlertType {
  Info,
  Warning
}

interface Props {
  type: AlertType;
  label: string;
}

function alertType(type: AlertType) {
  if (type === AlertType.Info) {
    return "bg-lightBlue";
  }

  return "bg-orange";
}

const Alert: FunctionComponent<Props> = ({ label, type }) => {
  return (
    <div className={`text-white p-4 my-4 ${alertType(type)}`}>{label}</div>
  );
};

export default Alert;
