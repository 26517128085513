import endpoints, { axios } from "../config/api";

function removeDependancyFromTaskTemplate(
  taskId: number,
  dependancyIds: number[]
) {
  return axios.delete(
    endpoints.taskTemplates.addTaskTemplateDependancies(taskId),
    {
      data: dependancyIds
    }
  );
}

export default removeDependancyFromTaskTemplate;
