import React, { useState, useRef, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroller";
import Document from "../../types/Document";
import { useToasts } from "react-toast-notifications";
import CreateDocumentModal from "../DocumentPage/CreateDocumentModal";
import ViewImageModal from "./ViewImageModal";
import dropzoneAcceptedFileTypes from "../../helpers/dropzoneAcceptedFileTypes";
import api from "../../api";
import { useOnlineProvider } from "../../state/OnlineProvider";
import { useAuth } from "../../state/AuthProvider";
import settings from "../../config/settings";
import endpoints from "../../config/api";

interface Props {
  projectId: number;
  taskId: number;
}

const TaskImageTab = ({ projectId, taskId }: Props) => {
  const { isOnline } = useOnlineProvider();
  const { addToast } = useToasts();
  const [currentDocuments, setCurrentDocuments] = useState<Document[]>([]);
  const [currentFolder, setCurrentFolder] = useState<Document>({} as Document);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const scrollRef = useRef(null);
  const { user } = useAuth();

  const [documentFile, setDocumentFile] = useState<File>();
  const [documentFileError, setDocumentFileError] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentNameError, setDocumentNameError] = useState("");
  const [documentToCreatePrivate, setDocumentToCreatePrivate] = useState(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [imageToView, setImageToView] = useState<Document>();
  const [data, setData] = useState<any>();
  const [isCreateLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const folder = await api.newFolders.getRootFolderTask(projectId, taskId);

      setCurrentFolder(folder);

      const response = await api.newFolders.getFolderContents(folder.id);

      setCurrentDocuments(
        response.payload.filter(x => /\.(jpe?g|png|gif|bmp)$/i.test(x.name))
      );
      setMaxPage(response.maxPage);
      setCurrentPage(response.currentPage);
      setLoading(false);
    };
    getData();
  }, [currentPage, projectId, taskId]);

  const createDocument = () => {
    setCreateLoading(true);
    if (!documentName || documentName.trim().length === 0) {
      setDocumentNameError("Please provide a file name");
      setCreateLoading(false);
      return;
    }
    setDocumentNameError("");

    if (!documentFile) {
      setDocumentFileError("Please provide a file to upload");
      setCreateLoading(false);
      return;
    }
    setDocumentFileError("");

    api.newFolders
      .uploadFile(
        currentFolder.id,
        documentName,
        documentToCreatePrivate,
        documentFile
      )
      .then(createDocumentResponse => {
        setCurrentDocuments([createDocumentResponse, ...currentDocuments]);
        closeCreateModal();
        addToast("Image Created Successfully", {
          appearance: "success",
          autoDismiss: true
        });
        setCreateLoading(false);
      })
      .catch(() => {
        setCreateLoading(false);
        addToast("Image creation failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const deleteDocument = (documentId: string) => {
    api.newFolders
      .deleteFile(currentFolder.id, documentId)
      .then(() => {
        addToast("Image Successfully Deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setCurrentDocuments(
          currentDocuments.filter(doc => doc.id !== documentId)
        );
      })
      .catch(() => {
        addToast("Image deletion failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setDocumentFile(undefined);
    setDocumentName("");
    setDocumentToCreatePrivate(false);
    setDocumentNameError("");
    setDocumentFileError("");
  };

  return (
    <div>
      <CreateDocumentModal
        documentName={documentName}
        dropzoneLabel="Drop your image here or click to open the file explorer"
        isModalOpen={isCreateModalOpen}
        onDocumentNameChange={value => setDocumentName(value)}
        onDocumentUpload={file => setDocumentFile(file)}
        onSubmit={() => createDocument()}
        setIsModalOpen={setIsCreateModalOpen}
        acceptedFileName={documentFile?.name}
        nameError={documentNameError}
        isPrivate={documentToCreatePrivate}
        setIsPrivate={setDocumentToCreatePrivate}
        closeModal={() => closeCreateModal()}
        dropzoneError={documentFileError}
        isDocumentCreating={isCreateLoading}
        acceptedFileTypes={dropzoneAcceptedFileTypes.filter(f =>
          f.includes("image")
        )}
        modalTitle="Add an Image"
        nameLabel="Image Name"
        disableFolderSelect
      />
      <ViewImageModal
        image={imageToView}
        isModalOpen={isViewModalOpen}
        setIsModalOpen={setIsViewModalOpen}
        deleteImage={documentId => deleteDocument(documentId)}
        currentFolder={currentFolder.id}
      />
      <div className="flex flex-col mt-8">
        <div>
          {!isOnline && (
            <p>
              You are currently offline. Only cached images will be displayed.
            </p>
          )}
          {loading ? (
            <div className="flex justify-center">
              <PulseLoader color="#00B2A9" />
            </div>
          ) : (
            <InfiniteScroll
              pageStart={1}
              threshold={250}
              hasMore={currentPage < maxPage}
              loadMore={() => setCurrentPage(currentPage + 1)}
              useWindow={false}
              element="div"
              className="mb-8 flex flex-wrap"
              getScrollParent={() => scrollRef.current}
              loader={
                <div className="w-full flex justify-center items-center">
                  <PulseLoader color="#00B2A9" />
                </div>
              }
            >
              {currentDocuments.length > 0 &&
                currentDocuments.map((document: any) => {
                  return (
                    <div
                      className="h-32 w-32 sm:h-56 sm:w-56 m-2 sm:m-4 cursor-pointer"
                      onClick={() => {
                        setImageToView(document);
                        setIsViewModalOpen(true);
                      }}
                    >
                      <img
                        src={`${
                          settings.engineUrl
                        }${endpoints.newfolders.downloadFile(
                          currentFolder.id,
                          document.id
                        )}`}
                        alt={document.name}
                        className="w-full h-full border-2 border-lightGrey "
                      />
                    </div>
                  );
                })}

              <div className="h-32 w-32 sm:h-56 sm:w-56 m-2 sm:m-4 flex justify-center items-center">
                <button
                  className="btn-circle"
                  onClick={() => setIsCreateModalOpen(true)}
                >
                  +
                </button>
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskImageTab;
