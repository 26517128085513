import React, { FunctionComponent, useState, useEffect, Fragment } from "react";
import { useToasts } from "react-toast-notifications";
import Header from "../../components/Header";
import { useAuth } from "../../state/AuthProvider";
import InlineEditableInput from "../../components/Input/InlineEditableInput";
import User from "../../types/User";
import { useForm } from "react-hook-form";
import { useMachine } from "@xstate/react";
import AccountPageStateMachine from "./stateMachine";
import PhoneIcon from "../../assets/img/phoneIcon.svg";
import EmailIcon from "../../assets/img/emailIcon.svg";
import InfoIcon from "../../assets/img/ui-checks.svg";
import { RouteChildrenProps } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import api from "../../api";
import { PulseLoader } from "react-spinners";
import ProtectedComponent from "../../components/ProtectedComponent";
import EditUserImageModal from "../../containers/User/EditUserImageModal";
import userRoleOptions from "../../helpers/userRoleOptions";
import InlineEditableTypeInput from "../../components/Input/InlineEditableTypeInput";

interface Match {
  id: string;
}

const UserPage: FunctionComponent<RouteChildrenProps<Match>> = ({ match }) => {
  const t = useTranslate();
  const { addToast } = useToasts();

  const { user: me } = useAuth();

  const [machine, send] = useMachine(AccountPageStateMachine, {
    actions: {
      showSuccessNotification: () => {
        addToast("User account has been updated", {
          appearance: "success",
          autoDismiss: true
        });
      },
      showErrorNotification: () =>
        addToast("An error has occured when updating the account", {
          appearance: "error",
          autoDismiss: true
        })
    }
  });
  const { register, errors, triggerValidation } = useForm();
  const [user, setUser] = useState<User>();
  const [userState, setUserState] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const {
    user: { role: currentUserRole }
  } = useAuth();
  const [isEditUserImageModalOpen, setIsEditUserImageModalOpen] = useState(
    false
  );
  const [updatedProfileImage, setUpdatedProfileImage] = useState<File>();

  const userId = match && match.params ? match.params.id : "";

  useEffect(() => {
    api.getUser(userId).then(({ data }) => {
      setUser(data);
      setUserState(data);
      setIsLoading(false);
    });
  }, [userId]);

  useEffect(() => {
    if (isLoading || JSON.stringify(user) === JSON.stringify(userState)) {
      return;
    }

    send("SUBMIT", {
      ...user,
      ...userState
    });
  }, [send, user, userState, isLoading]);

  if (isLoading || !userState) {
    return <PulseLoader color="#00B2A9" />;
  }

  return (
    <div className="h-screen overflow-auto bg-white">
      <Header title="Account" />
      <section className="p-8">
        <ProtectedComponent requiredRole="ADMINISTRATOR">
          <EditUserImageModal
            isModalOpen={isEditUserImageModalOpen}
            setIsModalOpen={setIsEditUserImageModalOpen}
            onConfirm={() => {
              if (updatedProfileImage) {
                setUserState({ ...userState, updatedProfileImage });
                setIsEditUserImageModalOpen(false);
                setUpdatedProfileImage(undefined);
              }
            }}
            updatedProfileImage={updatedProfileImage}
            setUpdatedProfileImage={setUpdatedProfileImage}
          />
        </ProtectedComponent>
        <img
          className="border-2 border-white mb-8 w-40 md:w-64 md:h-64 cursor-pointer object-cover"
          src={
            machine.context.profileImage ||
            userState?.profileImage ||
            `https://eu.ui-avatars.com/api/?name=${user?.firstName}+${user?.lastName}&size=256`
          }
          alt="Profile"
          onClick={() => {
            currentUserRole === "ADMINISTRATOR" &&
              setIsEditUserImageModalOpen(true);
          }}
        />
        <h1>
          {userState?.firstName} {userState?.lastName}
          <br />
          <small>{t(userState?.role)}</small>
        </h1>
        <div className="mt-4 border-t border-grey w-full lg:w-1/2">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="mt-8 flex flex-row">
              <img src={PhoneIcon} alt="phone icon" className="mr-4" />
              {currentUserRole === "ADMINISTRATOR" ? (
                <InlineEditableInput
                  type="tel"
                  pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
                  placeholder="Phone Number"
                  value={userState?.phoneNumber || ""}
                  inputRef={register({
                    required: true,
                    pattern: {
                      message: "Please enter a valid phone number",
                      value: /^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*/
                    }
                  })}
                  errors={errors["telephoneInput"]}
                  validationMessage="Please enter a valid phone number"
                  triggerValidation={triggerValidation}
                  name="telephoneInput"
                  onChange={value => {
                    if (!value) {
                      return;
                    }
                    userState &&
                      setUserState({ ...userState, phoneNumber: value });
                  }}
                />
              ) : (
                <p>{userState.phoneNumber}</p>
              )}
            </div>
            <div className="mt-8 flex flex-row">
              <img src={EmailIcon} alt="phone icon" className="mr-4" />
              {currentUserRole === "ADMINISTRATOR" ? (
                <InlineEditableInput
                  placeholder="Email Address"
                  value={userState?.email || ""}
                  type="email"
                  name="emailInput"
                  errors={errors["emailInput"]}
                  triggerValidation={triggerValidation}
                  pattern="^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                  validationMessage="Please enter a valid email address"
                  inputRef={register({
                    required: true
                  })}
                  onChange={(value: string | undefined) => {
                    if (!value) {
                      return;
                    }
                    userState && setUserState({ ...userState, email: value });
                  }}
                />
              ) : (
                <p>{userState.email}</p>
              )}
            </div>
            <div className="mt-8 flex flex-row">
              <img src={InfoIcon} alt="info icon" className="mr-4" />
              {currentUserRole === "ADMINISTRATOR" &&
              userState.email !== me?.email ? (
                <InlineEditableTypeInput
                  options={userRoleOptions(me.role)}
                  value={userState.role}
                  onChange={(value: string | undefined) => {
                    if (!value) {
                      return;
                    }
                    setUserState({ ...userState, role: value });
                  }}
                  className="flex-1"
                  previewClassName="font-normal"
                />
              ) : (
                <p>{t(userState?.role)}</p>
              )}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default UserPage;
