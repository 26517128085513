import endpoints, { axios } from "../../config/api";

async function createNewSubfolder(folderId: string, name: string) {
  return await (
    await axios.post(endpoints.newfolders.subfolders(folderId), {
      name
    })
  ).data;
}

export default createNewSubfolder;
