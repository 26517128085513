import React, { FC, useState, useEffect, useRef } from "react";
import folderIcon from "../../../assets/img/folderIcon.svg";
import Folder from "../../../types/Folder";
import { useHistory } from "react-router-dom";
import ProtectedComponent from "../../../components/ProtectedComponent";
import ActionIcon from "../../../assets/img/options.svg";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../../helpers/checkIsMobile";
import DeleteHoverIcon from "../../../assets/img/deleteHover.svg";
import Document from "../../../types/Document";
import { updateHistoryValue } from "xstate/lib/utils";

interface Props {
  folder: Document;
  setFolderToDelete: (folderId: string) => void;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  setFolderToUpdate: (folder: Document) => void;
  setIsUpdateModalOpen: (isOpen: boolean) => void;
  urlPrefix: string;
  subfolderClickOverride?: (folder: string) => void;
}

const ProjectSubfolderListItem: FC<Props> = ({
  folder,
  setFolderToDelete,
  setIsDeleteModalOpen,
  setIsUpdateModalOpen,
  setFolderToUpdate,
  urlPrefix,
  subfolderClickOverride
}) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  const click = () => {
    if (subfolderClickOverride) {
      subfolderClickOverride(folder.id);
    } else {
      history.push(`${urlPrefix}/${folder.id}`);
    }
  };

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <div className=" w-full sm:w-40 m-1 sm:m-4 border-grey border-2 flex flex-row sm:flex-col p-2 sm:p-4 items-center sm:items-start">
      <div className="flex order-3 sm:order-none sm:self-end ">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <Popover
            overlayColor="rgba(0,0,0,0)"
            arrowProps={{ className: "text-white " }}
            arrow={!CheckIsMobile()}
            action={null}
            open={isPopoverOpen}
            initZindex={0}
          >
            <button
              className="py-3 sm:m-0 pr-4 focus:outline-none h-full cursor-pointer w-8 min-w-8 sm:self-end  "
              onClick={() => setIsPopoverOpen(true)}
              onBlur={() => setIsPopoverOpen(false)}
            >
              <img
                className="h-full w-full align-middle"
                src={ActionIcon}
                alt="item options"
              />
            </button>
            <div
              ref={popoverRef}
              className="bg-white p-2 flex flex-col w-48 sm:h-32  justify-evenly shadow-xl"
            >
              <button
                className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                onMouseDown={() => {
                  setFolderToUpdate(folder);
                  setIsUpdateModalOpen(true);
                  setIsPopoverOpen(false);
                }}
              >
                Edit Folder
              </button>
              <ProtectedComponent requiredRole="ADMINISTRATOR">
                <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                <button
                  className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                  onMouseDown={() => {
                    setFolderToDelete(folder.id);
                    setIsDeleteModalOpen(true);
                    setIsPopoverOpen(false);
                  }}
                >
                  <img
                    className=" self-center  pr-4"
                    alt="delete"
                    src={DeleteHoverIcon}
                  />
                  Delete Folder
                </button>
              </ProtectedComponent>
            </div>
          </Popover>
        </ProtectedComponent>
      </div>
      <img
        className="sm:h-20 order-1 sm:order-none sm:w-20 h-10 w-10 self-center cursor-pointer "
        src={folderIcon}
        alt="Folder"
        onClick={click}
      />

      <p
        className="flex-wrap pt-2 flex-grow sm:flex-grow-0 flex order-2 sm:order-none sm:pt-6 text-xs px-2 sm:text-sm cursor-pointer leading-4"
        onClick={click}
      >
        {folder.name}
      </p>
    </div>
  );
};

export default ProjectSubfolderListItem;
