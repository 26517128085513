import endpoints, { axios } from "../../config/api";

async function updateFile(
  folderId: string,
  fileId: string,
  name?: string,
  parentFolderId?: string
) {
  return await (
    await axios.put(endpoints.newfolders.file(folderId, fileId), {
      name,
      parentFolderId
    })
  ).data;
}

export default updateFile;
