import endpoints, { axios } from "../config/api";

function addTaskTemplateDependancy(
  taskTemplateId: number,
  dependancyIds: number[]
) {
  return axios.post(
    endpoints.taskTemplates.addTaskTemplateDependancies(taskTemplateId),
    dependancyIds
  );
}

export default addTaskTemplateDependancy;
