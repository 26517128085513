import React, { useState, FC, useEffect, useRef } from "react";
import CreateDocumentModal from "../../DocumentPage/CreateDocumentModal";
import ListItem from "../../../components/List/ListItem";
import ProtectedComponent from "../../../components/ProtectedComponent";
import { useToasts } from "react-toast-notifications";
import useAxios from "axios-hooks";
import endpoints, { axios } from "../../../config/api";
import Document from "../../../types/Document";
import dropzoneAcceptedFileTypes from "../../../helpers/dropzoneAcceptedFileTypes";
import DeleteDocumentModal from "../../DocumentPage/DeleteDocumentModal";
import api from "../../../api";
import settings from "../../../config/settings";
import { useOnlineProvider } from "../../../state/OnlineProvider";
import ProjectSubfolderList from "../../ProjectOverviewPage/ProjectDocumentSubfolder/ProjectSubfolderList";
import ProjectDocumentTab from "../../ProjectOverviewPage/ProjectDocumentTab";
import { PulseLoader } from "react-spinners";

interface Props {
  projectTemplateId: number;
  // createdDocuments: Document[];
  // setCreatedDocuments: (documents: Document[]) => void;
  templateFolder: Document;
}

const TemplateProjectEditDocumentsTab: FC<Props> = ({
  projectTemplateId,
  // createdDocuments,
  // setCreatedDocuments,
  templateFolder
}) => {
  const [currentFolder, setCurrentFolder] = useState<Document>(templateFolder);
  const [loading, setLoading] = useState(false);

  if (loading)
    return (
      <div className="flex justify-center">
        <PulseLoader color="#00B2A9" />
      </div>
    );

  return (
    <ProjectDocumentTab
      entityId={currentFolder.id}
      hideTitle
      forceRootFolderId={templateFolder.id}
      subfolderClickOverride={async folderId => {
        setLoading(true);
        const folder = await api.newFolders.getNewFolder(folderId);
        setCurrentFolder(folder);
        setLoading(false);
      }}
    />
  );
};

export default TemplateProjectEditDocumentsTab;
