import React, { useState, FC, useRef, Fragment } from "react";
import Header from "../../components/Header";
import ProtectedComponent from "../../components/ProtectedComponent";
import { useHistory } from "react-router-dom";
import Modal from "react-responsive-modal";
import Tabs from "../../components/Tab/Tabs";
import Tab from "../../components/Tab/Tab";
import TemplateProjectsTab from "../../containers/TemplatesPage/TemplateProjectsTab";
import TemplateTasksTab from "../../containers/TemplatesPage/TemplateTaskTab";

const TemplatesPage: FC = () => {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [isSelectCreateModalOpen, setIsSelectCreateModalOpen] = useState(false);

  return (
    <div ref={scrollRef} className="h-screen overflow-auto bg-white">
      <div className=" sm:hidden absolute bottom-2 right-1 z-10">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <button
            onClick={() => setIsSelectCreateModalOpen(true)}
            className="btn-circle"
          >
            +
          </button>
        </ProtectedComponent>
      </div>
      <div className="hidden sm:block">
        <Header title="Templates">
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <Modal
              open={isSelectCreateModalOpen}
              onClose={() => setIsSelectCreateModalOpen(false)}
            >
              <div className="flex flex-col mt-6">
                <button
                  className="btn-secondary mb-4"
                  onClick={() => history.push("/templates/projects/create")}
                >
                  Create a Project Template
                </button>
                <button
                  className="btn-secondary"
                  onClick={() => history.push("/templates/tasks/create")}
                >
                  Create a Task Template
                </button>
              </div>
            </Modal>
            <button
              className="btn-primary"
              onClick={() => setIsSelectCreateModalOpen(true)}
            >
              + Create a template
            </button>
          </ProtectedComponent>
        </Header>
      </div>
      <div className="px-8 flex-1 sm:pt-8 bg-white ">
        <Tabs>
          <Tab label="Projects">
            <TemplateProjectsTab scrollRef={scrollRef} />
          </Tab>
          <Tab label="Tasks">
            <TemplateTasksTab scrollRef={scrollRef} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default TemplatesPage;
