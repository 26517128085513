import React, { useState, Fragment, FC, useEffect } from "react";
import useAxios from "axios-hooks";
import { useHistory, Link, RouteChildrenProps } from "react-router-dom";
import { DocumentToCreate } from "../ProjectTemplateWizard";
import endpoints from "../../../config/api";
import User from "../../../types/User";
import TaskTemplateSelectionPage from "../../../containers/TaskWizard/components/TaskTemplateSelectionPage";
import TaskDetailPage from "../../../containers/TaskWizard/components/TaskDetailPage";
import TaskDescriptionPage from "../../../containers/TaskWizard/components/TaskDescriptionPage";
import TaskDocumentUploadPage from "../../../containers/TaskWizard/components/TaskDocumentUploadPage";
import TaskAssignTeamMembersPage from "../../../containers/TaskWizard/components/TaskAssignTeamMembersPage";
import { useToasts } from "react-toast-notifications";
import TaskSubtaskPage from "../../../containers/TaskWizard/components/TaskSubTaskPage";
import api from "../../../api";
import TaskTemplate from "../../../types/TaskTemplate";
import Document from "../../../types/Document";
import SelectFromTemplateOrCreate from "../../../components/Wizards/common/SelectFromTemplateOrCreate";

interface Match {
  projectId: string;
}

export interface SubtaskToCreate {
  projectId: number;
  name: string;
  description: string;
  startDate: Date;
  expectedCompletionDate: Date;
}

const TaskWizard: FC<RouteChildrenProps<Match>> = ({ match }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [expectedCompletionDate, setExpectedCompletionDate] = useState(
    new Date()
  );
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [
    expectedCompletionDateError,
    setExpectedCompletionDateError
  ] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  const [teamMembersError, setTeamMembersError] = useState("");
  const [documentsToCreate, setDocumentsToCreate] = useState<
    DocumentToCreate[]
  >([]);
  const [existingDocuments, setExistingDocuments] = useState<Document[]>([]);
  const [projectId, setProjectId] = useState<number>(0);
  const [subtasksToCreate, setSubtasksToCreate] = useState<SubtaskToCreate[]>(
    []
  );
  const [selectedTemplate, setSelectedTemplate] = useState<TaskTemplate>();

  const [{ loading, error }, createTaskRequest] = useAxios(
    { url: endpoints.task.create(projectId), method: "POST" },
    { manual: true }
  );

  const history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    if (selectedTemplate) {
      setName(selectedTemplate.name);
      setDescription(selectedTemplate.description);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      api.getTaskTemplateSubtasks(selectedTemplate.id).then(({ data }) => {
        setSubtasksToCreate([...data.payload]);
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      api.newFolders.getRootFolderTemplateTask(selectedTemplate.id).then(x => {
        api.newFolders.getFolderContents(x.id).then(data => {
          setExistingDocuments(data.payload);
        });
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (match && match.params.projectId) {
      parseInt(match.params.projectId) > 0
        ? setProjectId(parseInt(match.params.projectId))
        : history.push("/tasks");
    }
  }, [match, history]);

  const createTask = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("startDate", startDate.toDateString());
    formData.append(
      "expectedCompletionDate",
      expectedCompletionDate.toDateString()
    );

    existingDocuments.forEach(d => {
      formData.append("existingDocuments", d.id.toString());
    });
    documentsToCreate.forEach((d: any) => {
      formData.append("newDocuments", d.file);
      formData.append("newDocumentNames", d.name);
    });
    teamMembers.forEach((m: any) => {
      formData.append("teamMemberIds", m.id);
    });

    formData.append("newSubtasks", JSON.stringify(subtasksToCreate));
    if (selectedTemplate) {
      formData.append("templateTaskId", JSON.stringify(selectedTemplate.id));
    }

    createTaskRequest({
      data: formData
    })
      .then(res => {
        if (res.status === 200) {
          addToast("Task Created Successfully", {
            appearance: "success",
            autoDismiss: true
          });
          history.push(`/projects/${projectId}/tasks/${res.data.id}`);
          return;
        }
      })
      .catch(() => {
        addToast("Task Creation Failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const validatePage = () => {
    switch (currentPage) {
      case 2:
        if (name.trim().length === 0) {
          setNameError("Task Name is a required field");

          return;
        }
        setNameError("");
        setCurrentPage(currentPage + 1);
        return;
      case 3:
        if (description.trim() === "") {
          setDescriptionError("Description is required");
          return;
        }
        setDescriptionError("");
        setCurrentPage(currentPage + 1);
        return;

      case 6:
        createTask();
        return;
      default:
        setCurrentPage(currentPage + 1);
    }
  };

  const pageToRender = () => {
    switch (currentPage) {
      case 0:
        return (
          <SelectFromTemplateOrCreate
            onCreateNew={() => setCurrentPage(currentPage + 2)}
            onSelectTemplate={() => setCurrentPage(currentPage + 1)}
          />
        );
      case 1:
        return (
          <TaskTemplateSelectionPage
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        );
      case 2:
        return (
          <TaskDetailPage
            name={name}
            setName={setName}
            nameError={nameError}
            startDate={startDate}
            setStartDate={setStartDate}
            startDateError={startDateError}
            expectedCompletionDate={expectedCompletionDate}
            setExpectedCompletionDate={setExpectedCompletionDate}
            expectedCompletionDateError={expectedCompletionDateError}
          />
        );
      case 3:
        return (
          <TaskDescriptionPage
            descriptionError={descriptionError}
            description={description}
            setDescription={setDescription}
          />
        );
      case 4:
        return (
          <TaskDocumentUploadPage
            documentsToCreate={documentsToCreate}
            setDocumentsToCreate={setDocumentsToCreate}
            existingDocuments={existingDocuments}
            setExistingDocuments={setExistingDocuments}
          />
        );

      case 5:
        return (
          <TaskAssignTeamMembersPage
            teamMembers={teamMembers}
            teamMembersError={teamMembersError}
            setTeamMembers={setTeamMembers}
            projectId={projectId}
          />
        );
      case 6:
        return (
          <TaskSubtaskPage
            expectedCompletionDate={expectedCompletionDate}
            startDate={startDate}
            projectId={projectId}
            subtasksToCreate={subtasksToCreate}
            setSubtasksToCreate={setSubtasksToCreate}
          />
        );
      default:
        return (
          <TaskTemplateSelectionPage
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        );
    }
  };
  return (
    <div className="h-screen overflow-auto  ">
      <div className="wizard">
        <div className="wizardHeader">
          <h1>{currentPage > 2 ? name : "Create A Task"}</h1>
        </div>

        <div className="wizardContent">{pageToRender()}</div>
        <div className="wizardButtons">
          {currentPage > 0 ? (
            <button
              className="btn-secondary sm:w-56 w-full mt-4 sm:mt-0"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Back
            </button>
          ) : (
            <Link to={`/projects/${projectId}`} className="mt-4 sm:mt-0">
              <button className="btn-secondary sm:w-56 w-full ">Cancel</button>
            </Link>
          )}
          {currentPage < 6 && currentPage > 0 ? (
            <button
              className="btn-primary w-full sm:w-56"
              onClick={() => validatePage()}
            >
              Next
            </button>
          ) : (
            currentPage === 6 && (
              <button
                className={`${
                  loading ? "btn-disabled" : "btn-primary"
                } w-full sm:w-56`}
                onClick={() => validatePage()}
              >
                Create Task
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskWizard;
