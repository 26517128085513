import React, { FC, useState } from "react";
import Modal from "react-responsive-modal";
import api from "../../api";
import Document from "../../types/Document";
import Dropzone from "../../components/Dropzone";
import { PulseLoader } from "react-spinners";
import { useToasts } from "react-toast-notifications";

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  folder: string;
  document: Document;
}

const ReplaceDocumentModal: FC<Props> = ({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  folder,
  document
}) => {
  const [documentFile, setDocumentFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const upload = async () => {
    setLoading(true);
    try {
      await api.newFolders.replaceFile(folder, document.id, documentFile!);
      addToast("Your file was uploaded", {
        appearance: "success",
        autoDismiss: true
      });
    } catch {
      addToast("Failed to update commercial document. Try again.", {
        appearance: "error",
        autoDismiss: true
      });
    }
    onConfirm();
    setLoading(false);
  };

  return (
    <Modal
      center={true}
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
      showCloseIcon={false}
    >
      {loading ? (
        <div>
          Uploading...
          <PulseLoader />
        </div>
      ) : (
        <>
          <h2>
            Update {document.name} {document.fileType}
          </h2>
          <Dropzone
            label="Drop your updated commercial document here or click to open the file explorer."
            acceptedFileName={documentFile?.name}
            onDrop={file => {
              setDocumentFile(file);
            }}
            acceptedFileTypes={[
              document.name.substring(document.name.lastIndexOf("."))
            ]}
          />

          <div className="flex flex-col-reverse sm:flex-row sm:justify-between mt-8">
            <button
              className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className={`${
                documentFile === undefined ? "btn-disabled" : "btn-primary"
              } w-full sm:w-56`}
              onClick={upload}
              disabled={documentFile === undefined}
            >
              Upload
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ReplaceDocumentModal;
