import React, { useState, FC, useEffect, useRef, useCallback } from "react";
import useAxios from "axios-hooks";
import endpoints from "../../config/api";
import InfiniteScroll from "react-infinite-scroller";
import { PulseLoader } from "react-spinners";
import ListItem from "../../components/List/ListItem";
import ProtectedComponent from "../../components/ProtectedComponent";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import api from "../../api";
import { useToasts } from "react-toast-notifications";
import TaskTemplate, {
  TaskTemplateListResponse
} from "../../types/TaskTemplate";
import TemplateTaskListItem from "./TemplateTaskListItem";

interface Props {
  scrollRef: React.MutableRefObject<null>;
}

const TemplateTasksTab: FC<Props> = ({ scrollRef }) => {
  const [currentTaskTemplates, setCurrentTaskTemplates] = useState<
    TaskTemplate[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [taskTemplateToDelete, setTaskTemplateToDelete] = useState<number>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { addToast } = useToasts();

  const [{ loading, data }, getTaskTemplateRequest] = useAxios<
    TaskTemplateListResponse
  >(
    {
      url: `${endpoints.taskTemplates.getPage(currentPage)}`,
      method: "GET"
    },
    { manual: true }
  );

  const deleteTaskTemplate = useCallback(() => {
    if (taskTemplateToDelete) {
      api
        .deleteTaskTemplate(taskTemplateToDelete)
        .then(() => {
          addToast("Task Template Deleted Successfully", {
            appearance: "success",
            autoDismiss: true
          });
          setCurrentTaskTemplates(
            currentTaskTemplates.filter(p => p.id !== taskTemplateToDelete)
          );
          setTaskTemplateToDelete(undefined);
          setIsDeleteModalOpen(false);
        })
        .catch(() => {
          addToast("Failed to delete Task Template. Try again.", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  }, [addToast, taskTemplateToDelete, currentTaskTemplates]);

  useEffect(() => {
    getTaskTemplateRequest();
  }, [getTaskTemplateRequest, currentPage]);

  useEffect(() => {
    if (data) {
      setCurrentPage(data.currentPage);
      setMaxPage(data.maxPage);
      setCurrentTaskTemplates([...currentTaskTemplates, ...data.payload]);
    }
  }, [data]);

  return (
    <div className="flex flex-1 bg-white ">
      <div className="flex-1  pt-8 flex flex-col">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <ConfirmDeleteModal
            entityLabel="Task Template"
            isModalOpen={isDeleteModalOpen}
            onConfirm={() => taskTemplateToDelete && deleteTaskTemplate()}
            setIsModalOpen={setIsDeleteModalOpen}
          />
        </ProtectedComponent>
        <h5 className="text-lightBlue">Name</h5>
        <div>
          <InfiniteScroll
            pageStart={1}
            threshold={250}
            hasMore={currentPage < maxPage}
            loadMore={() => setCurrentPage(currentPage + 1)}
            useWindow={false}
            className="mb-16"
            element="div"
            getScrollParent={() => scrollRef.current}
            loader={
              <div className="w-full flex justify-center items-center">
                <PulseLoader color="#00B2A9" />
              </div>
            }
          >
            {loading ? (
              <div className="w-full flex items-center justify-center">
                <PulseLoader color="#00B2A9" />
              </div>
            ) : currentTaskTemplates.length > 0 ? (
              currentTaskTemplates
                .sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                .map(taskTemplate => {
                  return (
                    <TemplateTaskListItem
                      taskTemplate={taskTemplate}
                      setIsDeleteModalOpen={setIsDeleteModalOpen}
                      setTaskTemplateToDelete={setTaskTemplateToDelete}
                    />
                  );
                })
            ) : (
              <ListItem
                containerStyle="bg-white hover:bg-lightGrey"
                label="No Task Templates found"
              />
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default TemplateTasksTab;
