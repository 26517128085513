import React, { useState, useRef, useEffect, Fragment } from "react";
import { PulseLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroller";
import ProtectedComponent from "../../components/ProtectedComponent";
import Document, { FileType } from "../../types/Document";
import { useToasts } from "react-toast-notifications";
import CreateDocumentModal from "../DocumentPage/CreateDocumentModal";
import DeleteDocumentModal from "../DocumentPage/DeleteDocumentModal";
import ListItem from "../../components/List/ListItem";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ActionIcon from "../../assets/img/options.svg";
import api from "../../api";
import { useOnlineProvider } from "../../state/OnlineProvider";
import classnames from "classnames";
import ProjectSubfolderList from "./ProjectDocumentSubfolder/ProjectSubfolderList";
import Folder, { FolderPath } from "../../types/Folder";
import Button from "../../components/Button";
import { useHistory } from "react-router-dom";
import MoveDocumentModal from "../../containers/DocumentPage/MoveDocumentModal";
import ProjectDocumentListItem from "./ProjectDocumentSubfolder/ProjectDocumentListItem";

interface Props {
  projectId?: number;
  entityId: string;
  hideTitle?: boolean;
  forceRootFolderId?: string;
  subfolderClickOverride?: (folder: string) => void;
}

const ProjectDocumentTab = ({
  projectId,
  entityId,
  hideTitle,
  forceRootFolderId,
  subfolderClickOverride
}: Props) => {
  const urlPrefix = projectId
    ? `/projects/${projectId}/documents`
    : `/documentation`;

  const { isOnline } = useOnlineProvider();
  const { addToast } = useToasts();
  const [currentDocuments, setCurrentDocuments] = useState<Document[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const scrollRef = useRef(null);
  const [currentFolder, setCurrentFolder] = useState<Document>({} as Document);
  const { push } = useHistory();
  const [documentFile, setDocumentFile] = useState<File>();
  const [documentFileError, setDocumentFileError] = useState<string>("");
  const [documentName, setDocumentName] = useState<string>("");
  const [documentNameError, setDocumentNameError] = useState<string>("");
  const [documentIsPrivate, setDocumentPrivate] = useState<boolean>(false);
  const [documentFolder, setDocumentFolder] = useState<Document>();
  const [documentToDelete, setDocumentToDelete] = useState<string>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false);
  const [documentToMove, setDocumentToMove] = useState<string>();
  const [documentToMoveFolder, setDocumentToMoveFolder] = useState<Document>();
  const [isCreateSubfolderModalOpen, setIsCreateSubfolderModalOpen] = useState(
    false
  );
  const [rootFolderId, setRootFolderId] = useState<string>();

  useEffect(() => {
    const getData = async () => {
      if (entityId) {
        setLoading(true);
        const folder = await api.newFolders.getNewFolder(entityId);
        setCurrentFolder(folder);
        const data = await api.newFolders.getFolderContents(entityId);
        setCurrentDocuments(data.payload);
        setMaxPage(data.maxPage);
        setCurrentPage(data.currentPage);
        setLoading(false);
      } else if (projectId) {
        setLoading(true);
        const folder = await api.newFolders.getRootFolderProject(projectId);
        setCurrentFolder(folder);
        setRootFolderId(folder.id);
        const data = await api.newFolders.getFolderContents(folder.id);
        setCurrentDocuments(data.payload);
        setMaxPage(data.maxPage);
        setCurrentPage(data.currentPage);
        setLoading(false);
      } else {
        setLoading(true);
        addToast("Failed to retrieve Folder", {
          appearance: "error",
          autoDismiss: true
        });
      }
    };
    getData();
  }, [projectId, entityId]);

  const loadMoreDocuments = (page: any) => {};

  const createDocument = () => {
    if (!documentName || documentName.trim().length === 0) {
      setDocumentNameError("Please provide a file name");
      return;
    }

    if (!documentFile) {
      setDocumentFileError("Please provide a file to upload");
      return;
    }

    setDocumentNameError("");
    setDocumentFileError("");

    setCreateLoading(true);
    api.newFolders
      .uploadFile(
        currentFolder.id,
        documentName,
        documentIsPrivate,
        documentFile
      )
      .then(createDocumentResponse => {
        setCurrentDocuments([...currentDocuments, createDocumentResponse]);
        closeCreateModal();
        addToast("Document Created Successfully", {
          appearance: "success",
          autoDismiss: true
        });

        setCreateLoading(false);
      })
      .catch(() => {
        addToast(
          "Failed to upload document. Try again with a different name.",
          {
            appearance: "error",
            autoDismiss: true
          }
        );
        setCreateLoading(false);
      });
  };

  const moveDocument = () => {
    if (documentToMove) {
      api.newFolders
        .updateFile(
          currentFolder.id,
          documentToMove,
          undefined,
          documentToMoveFolder?.id
        )
        .then(() => {
          setCurrentDocuments(
            currentDocuments.filter(d => d.id !== documentToMove)
          );
          setDocumentToMove(undefined);
          setDocumentToMoveFolder(undefined);
          setIsMoveModalOpen(false);

          addToast("Document Successfully Moved", {
            appearance: "success",
            autoDismiss: true
          });
        })
        .catch(() => {
          addToast("Failed to move document", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  };

  const deleteDocument = (documentId: string) => {
    api.newFolders
      .deleteFile(currentFolder.id, documentId)
      .then(() => {
        addToast("Document Successfully Deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setCurrentDocuments(
          currentDocuments.filter(doc => doc.id !== documentId)
        );
        setIsDeleteModalOpen(false);
        setDocumentToDelete(undefined);
      })
      .catch(() => {
        addToast("Failed to delete document. Try again", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setDocumentFile(undefined);
    setDocumentName("");
    setDocumentPrivate(false);
  };

  const downloadDocument = (location: string) => {
    window.open(location, "_blank");
  };

  return (
    <div>
      <CreateDocumentModal
        documentName={documentName}
        dropzoneLabel="Drop your document here or click to open the file explorer"
        isModalOpen={isCreateModalOpen}
        onDocumentNameChange={value => setDocumentName(value)}
        onDocumentUpload={file => setDocumentFile(file)}
        onSubmit={() => createDocument()}
        setIsModalOpen={setIsCreateModalOpen}
        acceptedFileName={documentFile?.name}
        nameError={documentNameError}
        isPrivate={documentIsPrivate}
        setIsPrivate={setDocumentPrivate}
        closeModal={() => closeCreateModal()}
        dropzoneError={documentFileError}
        isDocumentCreating={isCreateLoading}
        projectId={projectId}
        selectedFolder={documentFolder}
        setSelectedFolder={setDocumentFolder}
        defaultFolder={currentFolder.id}
        rootFolderId={forceRootFolderId}
      />
      <DeleteDocumentModal
        setIsModalOpen={setIsDeleteModalOpen}
        isModalOpen={isDeleteModalOpen}
        onConfirm={() => documentToDelete && deleteDocument(documentToDelete)}
      />
      <div
        className={`flex flex-col lg:flex-row justify-between ${
          hideTitle ? "" : "mt-12"
        }`}
      >
        {!currentFolder.name?.startsWith("__") &&
        entityId &&
        !isLoading &&
        !hideTitle &&
        rootFolderId !== entityId ? (
          <div>
            <h1>{currentFolder.name}</h1>
            <Button
              label="< back"
              onClick={() =>
                push(
                  `${urlPrefix}${
                    currentFolder.parentFolderId
                      ? `/${currentFolder.parentFolderId}`
                      : ""
                  }`
                )
              }
            />
          </div>
        ) : (
          <div>
            {subfolderClickOverride && (
              <div>
                <h1>{currentFolder.path}</h1>
                {currentFolder.id !== forceRootFolderId && (
                  <Button
                    label="< parent folder"
                    onClick={() => {
                      subfolderClickOverride(currentFolder.parentFolderId);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
        {isOnline && (
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <div className="flex md:self-end justify-between flex-col lg:flex-row">
              <button
                className="btn-primary w-full md:w-56 mt-1 mr-0 mb-4 lg:mb-0 lg:mr-8"
                onClick={() => setIsCreateModalOpen(true)}
              >
                + Upload a Document
              </button>
              {isOnline && (
                <button
                  className="btn-primary w-full md:w-56 mr-0 mb-4  lg:mb-0 lg:mr-8 mt-4 lg:mt-1"
                  onClick={() => setIsCreateSubfolderModalOpen(true)}
                >
                  + Add a folder
                </button>
              )}
            </div>
          </ProtectedComponent>
        )}
      </div>

      <ProjectSubfolderList
        isCreateSubfolderModalOpen={isCreateSubfolderModalOpen}
        setIsCreateSubfolderModalOpen={setIsCreateSubfolderModalOpen}
        scrollRef={scrollRef}
        folderId={currentFolder.id}
        setSubfolders={setCurrentDocuments}
        subfolders={isLoading ? [] : currentDocuments}
        urlPrefix={urlPrefix}
        subfolderClickOverride={subfolderClickOverride}
      />
      {isOnline && (
        <div className="flex flex-col mt-8">
          <h5 className="text-lightBlue">Name</h5>
          <div>
            {isLoading ? (
              <div className="w-full flex justify-center items-center">
                <PulseLoader color="#00B2A9" />
              </div>
            ) : (
              <InfiniteScroll
                pageStart={1}
                threshold={250}
                hasMore={currentPage < maxPage}
                loadMore={loadMoreDocuments}
                useWindow={false}
                element="div"
                className="mb-8"
                getScrollParent={() => scrollRef.current}
                loader={
                  <div className="w-full flex justify-center items-center">
                    <PulseLoader color="#00B2A9" />
                  </div>
                }
              >
                <table>
                  <thead>
                    <tr className="w-full">
                      <th className=""></th>
                      <th className="w-auto"></th>
                      <th className="w-1/3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={3}>
                          <div className="w-full flex items-center justify-center">
                            <PulseLoader color="#00B2A9" />
                          </div>
                        </td>
                      </tr>
                    ) : currentDocuments.length > 0 ? (
                      currentDocuments
                        .filter(x => x.fileType === FileType.FILE)
                        .sort((a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map(document => {
                          return (
                            <Fragment>
                              <ProjectDocumentListItem
                                item={document}
                                key={document.id}
                                downloadDocument={downloadDocument}
                                isMoveModalOpen={isMoveModalOpen}
                                moveDocument={moveDocument}
                                setIsMoveModalOpen={setIsMoveModalOpen}
                                documentToMoveFolder={documentToMoveFolder}
                                setDocumentToMoveFolder={
                                  setDocumentToMoveFolder
                                }
                                projectId={projectId}
                                setDocumentToDelete={setDocumentToDelete}
                                setDocumentToMove={setDocumentToMove}
                                setIsDeleteModalOpen={setIsDeleteModalOpen}
                                currentFolder={currentFolder}
                              />
                            </Fragment>
                          );
                        })
                    ) : (
                      <ListItem
                        containerStyle="bg-white hover:bg-lightGrey "
                        label="No Documents found"
                      />
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDocumentTab;
