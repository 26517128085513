import React, { useState, FunctionComponent } from "react";
import EditIcon from "../../assets/img/editIcon.svg";
import EditIconHover from "../../assets/img/editIconHover.svg";
import ReactDatePicker from "react-datepicker";
import { useAuth } from "../../state/AuthProvider";
import { useOnlineProvider } from "../../state/OnlineProvider";
import checkUserRole from "../../helpers/checkUserRole";

interface Props {
  value: Date;
  placeholder: string;
  onChange?: (input: Date) => void;
  minDate?: Date;
}

const InlineEditableDateInput: FunctionComponent<Props> = ({
  onChange,
  value,
  placeholder,
  minDate
}) => {
  const [icon, setIcon] = useState(EditIcon);
  const [isEditing, setEditing] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const { isOnline } = useOnlineProvider();
  const onEditingChange = () => {
    setEditing(!isEditing);
  };

  return (
    <div className="flex flex-row justify-between items-center">
      {isEditing &&
      isAuthenticated &&
      checkUserRole("PROJECTMANAGER", user.role) ? (
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          minDate={minDate && new Date(minDate)}
          id={placeholder}
          onChange={e => {
            if (e && onChange) {
              onChange(e);
            }
          }}
          selected={new Date(value)}
        />
      ) : (
        <h4>{`${new Date(value).getDate()}/${new Date(value).getMonth() +
          1}/${new Date(value)
          .getFullYear()
          .toString()
          .slice(2)}`}</h4>
      )}
      {isOnline && checkUserRole("PROJECTMANAGER", user.role) && (
        <p onClick={e => onEditingChange()} className="InlineEditIconContainer">
          {isEditing ? (
            <svg className="ml-2" height="22px" width="22px" version="1.1">
              <g fill="#00B2A9">
                <path d="M6,10 L2.5,6.5 L0.5,8.5 L6,14 L15.5,4.5 L13.5,2.5 L6,10 Z"></path>
              </g>
            </svg>
          ) : (
            <img
              src={icon}
              alt="editable input icon"
              onMouseEnter={() => setIcon(EditIconHover)}
              onMouseLeave={() => setIcon(EditIcon)}
            />
          )}
        </p>
      )}
    </div>
  );
};

export default InlineEditableDateInput;
