import React, { useState, FunctionComponent } from "react";
import EditIcon from "../../assets/img/editIcon.svg";
import EditIconHover from "../../assets/img/editIconHover.svg";
import Dropdown from "react-dropdown";
import { useOnlineProvider } from "../../state/OnlineProvider";
import { useAuth } from "../../state/AuthProvider";
import checkUserRole from "../../helpers/checkUserRole";

interface Props {
  value: string;
  onChange?: (input: string) => void;
  options: any[];
  className?: string;
  previewClassName?: string;
}

const InlineEditableTypeInput: FunctionComponent<Props> = ({
  onChange,
  value,
  options,
  className,
  previewClassName
}) => {
  const { isOnline } = useOnlineProvider();
  const [icon, setIcon] = useState(EditIcon);
  const [isEditing, setEditing] = useState(false);
  const [input, setInput] = useState(value);
  const { user } = useAuth();

  const onEditingChange = () => {
    if (isEditing && onChange) {
      onChange(input);
    }
    setEditing(!isEditing);
  };

  var inputValue = options?.find(x => x.value === input)?.label;

  return (
    <div className={`flex flex-row justify-between items-center ${className}`}>
      {isEditing && checkUserRole("PROJECTMANAGER", user.role) ? (
        <Dropdown
          className="min-w-32"
          options={options}
          value={input}
          onChange={e => {
            setInput(e.value);
            if (onChange) {
              onChange(e.value);
              setEditing(false);
            }
          }}
        />
      ) : previewClassName ? (
        <p className={previewClassName}>{inputValue ?? input}</p>
      ) : (
        <h4>{inputValue ?? input}</h4>
      )}
      {isOnline && checkUserRole("PROJECTMANAGER", user.role) && (
        <p onClick={e => onEditingChange()} className="InlineEditIconContainer">
          {isEditing ? (
            <svg className="ml-2" height="22px" width="22px" version="1.1">
              <g fill="#00B2A9">
                <path d="M6,10 L2.5,6.5 L0.5,8.5 L6,14 L15.5,4.5 L13.5,2.5 L6,10 Z"></path>
              </g>
            </svg>
          ) : (
            <img
              src={icon}
              alt="editable input icon"
              onMouseEnter={() => setIcon(EditIconHover)}
              onMouseLeave={() => setIcon(EditIcon)}
            />
          )}
        </p>
      )}
    </div>
  );
};

export default InlineEditableTypeInput;
