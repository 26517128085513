import React, { FC, useState, useRef, useEffect } from "react";
import Task from "../../types/Task";
import classnames from "classnames";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ActionIcon from "../../assets/img/options.svg";
import ProtectedComponent from "../../components/ProtectedComponent";
import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import deleteIcon from "../../assets/img/delete.svg";
import { useHistory } from "react-router-dom";

interface Props {
  item: Task;
  projectId: number;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  setDependancyToDelete: (dependancyToDelete: Task) => void;
}

const TaskDependancyItem: FC<Props> = ({
  item,
  setIsDeleteModalOpen,
  setDependancyToDelete,
  projectId
}) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <tr className=" w-full  cursor-pointer" key={item.id}>
      <td
        className="pl-2"
        onClick={() => history.push(`/projects/${projectId}/tasks/${item.id}`)}
      >
        <p
          className={classnames({
            "text-grey": item.isCompleted
          })}
        >
          {item.name}
        </p>
      </td>

      <td>
        <div className="flex justify-end">
          <Popover
            overlayColor="rgba(0,0,0,0)"
            placement="left-center"
            arrowProps={{ className: "text-white " }}
            arrow={!CheckIsMobile()}
            action={null}
            open={isPopoverOpen}
            initZindex={0}
          >
            <button
              className="py-3 m-0 pr-4 focus:outline-none cursor-pointer "
              onClick={() => setIsPopoverOpen(true)}
              onBlur={() => setIsPopoverOpen(false)}
            >
              <img
                className="h-full w-full"
                src={ActionIcon}
                alt="item options"
              />
            </button>
            <div
              ref={popoverRef}
              className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-xl"
            >
              <button
                className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                onMouseDown={() =>
                  history.push(`/projects/${projectId}/tasks/${item.id}`)
                }
              >
                View Task
              </button>
              <div className="h-1 w-full border-t-2 border-lightGrey"></div>
              <ProtectedComponent requiredRole="PROJECTMANAGER">
                {!item.isCompleted ? (
                  <button
                    className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                    onMouseDown={() => {
                      setDependancyToDelete(item);
                      setIsDeleteModalOpen(true);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <img src={deleteHoverIcon} className="pr-4 " alt="Delete" />
                    Delete Dependancy
                  </button>
                ) : (
                  <button
                    className="btn-tertiary mt-2 cursor-not-allowed text-grey hover:text-grey flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                    disabled={true}
                  >
                    <img src={deleteIcon} className="pr-4 " alt="Delete" />
                    Delete Task
                  </button>
                )}
              </ProtectedComponent>
            </div>
          </Popover>
        </div>
      </td>
    </tr>
  );
};

export default TaskDependancyItem;
