import React, { FC, useEffect, useRef, useState } from "react";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../../helpers/checkIsMobile";
import classnames from "classnames";
import ActionIcon from "../../../assets/img/options.svg";
import { useOnlineProvider } from "../../../state/OnlineProvider";
import ProtectedComponent from "../../../components/ProtectedComponent";
import MoveDocumentModal from "../../DocumentPage/MoveDocumentModal";
import { FolderPath } from "../../../types/Folder";
import Document from "../../../types/Document";
import settings from "../../../config/settings";
import endpoints from "../../../config/api";

interface Props {
  item: Document;
  downloadDocument: (location: string) => void;
  isMoveModalOpen: boolean;
  moveDocument: () => void;
  setIsMoveModalOpen: (isOpen: boolean) => void;
  documentToMoveFolder: Document | undefined;
  setDocumentToMoveFolder: (folderPath: Document) => void;
  projectId?: number;
  setDocumentToMove: (id: string) => void;
  setDocumentToDelete: (id: string) => void;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  currentFolder: Document;
}

const ProjectDocumentListItem: FC<Props> = ({
  item,
  downloadDocument,
  isMoveModalOpen,
  moveDocument,
  setIsMoveModalOpen,
  documentToMoveFolder,
  setDocumentToMoveFolder,
  projectId,
  setDocumentToMove,
  setDocumentToDelete,
  setIsDeleteModalOpen,
  currentFolder
}) => {
  const { isOnline } = useOnlineProvider();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <tr className=" w-full  cursor-pointer" key={item.id}>
      <td>
        <h4
          className={classnames(
            `no-underline text-darkGrey font-normal break-all`,
            {
              "cursor-pointer": isOnline
            }
          )}
          onClick={() =>
            isOnline &&
            downloadDocument(
              `${settings.engineUrl}${endpoints.newfolders.downloadFile(
                currentFolder.id,
                item.id
              )}`
            )
          }
        >
          {item.name}
        </h4>
      </td>
      <td
        className=""
        onClick={() =>
          isOnline &&
          downloadDocument(
            `${settings.engineUrl}${endpoints.newfolders.downloadFile(
              currentFolder.id,
              item.id
            )}`
          )
        }
      >
        {new Date(item.createdAt).toLocaleDateString()}
      </td>
      <td>
        <div className="flex justify-end h-full ">
          {isOnline && (
            <div>
              <div>
                <ProtectedComponent requiredRole="PROJECTMANAGER">
                  <MoveDocumentModal
                    isModalOpen={isMoveModalOpen}
                    onConfirm={moveDocument}
                    setIsModalOpen={setIsMoveModalOpen}
                    selectedFolder={documentToMoveFolder}
                    setSelectedFolder={setDocumentToMoveFolder}
                    projectId={projectId}
                  />
                </ProtectedComponent>
                <Popover
                  overlayColor="rgba(0,0,0,0)"
                  placement="left-center"
                  arrowProps={{
                    className: "text-white "
                  }}
                  arrow={!CheckIsMobile()}
                  open={isPopoverOpen}
                  initZindex={0}
                  action={null}
                >
                  <button
                    onClick={() => setIsPopoverOpen(true)}
                    onBlur={() => setIsPopoverOpen(false)}
                    className="py-3 m-0 pr-4 focus:outline-none cursor-pointer "
                  >
                    <img
                      className="h-full w-full"
                      src={ActionIcon}
                      alt="item options"
                    />
                  </button>
                  <div
                    ref={popoverRef}
                    className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-xl"
                  >
                    <button
                      className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                      onMouseDown={() => {
                        isOnline &&
                          downloadDocument(
                            `${
                              settings.engineUrl
                            }${endpoints.newfolders.downloadFile(
                              currentFolder.id,
                              item.id
                            )}`
                          );
                        setIsPopoverOpen(false);
                      }}
                    >
                      Download Document
                    </button>
                    <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                    <ProtectedComponent requiredRole="PROJECTMANAGER">
                      <button
                        className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                        onMouseDown={() => {
                          setDocumentToMove(item.id);
                          setIsMoveModalOpen(true);
                          setIsPopoverOpen(false);
                        }}
                      >
                        Move Document
                      </button>

                      <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                      <button
                        className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                        onMouseDown={() => {
                          if (!isOnline) return;
                          setDocumentToDelete(item.id);
                          setIsDeleteModalOpen(true);
                          setIsPopoverOpen(false);
                        }}
                      >
                        <div
                          id="deleteIcon"
                          className="cursor-pointer pr-4"
                          onMouseDown={() => {
                            if (!isOnline) return;
                            setDocumentToDelete(item.id);
                            setIsDeleteModalOpen(true);
                            setIsPopoverOpen(false);
                          }}
                        />
                        Delete Document
                      </button>
                    </ProtectedComponent>
                  </div>
                </Popover>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ProjectDocumentListItem;
