import { Machine, assign } from "xstate";
import endpoints, { axios } from "../../../config/api";

const createProjectTemplateRequest = (projectTemplate: any) => {
  const formData = new FormData();
  formData.append("name", projectTemplate.name);
  formData.append("description", projectTemplate.description);
  formData.append("type", projectTemplate.typeIndex);

  projectTemplate.documentsToCreate.forEach((d: any) => {
    formData.append("files", d.file);
    formData.append("fileNames", d.name);
  });

  projectTemplate.existingTasks.forEach((t: any) => {
    formData.append("existingTaskIds", t.id);
  });

  return axios.post(endpoints.projectTemplates.create, formData);
};

const updateProjectTemplateRequest = (projectTemplate: any) => {
  const formData = new FormData();

  formData.append("name", projectTemplate.name);
  formData.append("description", projectTemplate.description);
  formData.append("type", projectTemplate.typeIndex);
  return axios.put(
    endpoints.projectTemplates.update(projectTemplate.id),
    formData
  );
};

const ProjectTemplateWizardStateMachine = Machine({
  id: "ProjectTemplateWizardStateMachine",
  initial: "step1",
  context: {
    success: false,
    errors: []
  },
  states: {
    step1: {
      on: {
        NEXT: "step2"
      }
    },
    step2: {
      on: {
        NEXT: "step3",
        PREVIOUS: "step1"
      }
    },
    step3: {
      on: {
        PREVIOUS: "step2",
        NEXT: "step4"
      }
    },
    step4: {
      on: {
        PREVIOUS: "step3",
        SUBMIT: { target: "loading" }
      }
    },
    loading: {
      invoke: {
        id: "createProjectTemplate",
        src: (_, projectTemplate) =>
          projectTemplate.id
            ? updateProjectTemplateRequest(projectTemplate)
            : createProjectTemplateRequest(projectTemplate),
        onDone: {
          target: "success",
          actions: assign({ success: true, errors: [] })
        },
        onError: {
          target: "error",
          actions: assign({ success: false, errors: [] })
        }
      }
    },
    success: {
      entry: "redirectToTemplatePage"
    },
    error: {
      entry: "showErrorNotification",
      after: {
        1000: "step3"
      }
    }
  }
});

export default ProjectTemplateWizardStateMachine;
