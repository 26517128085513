import { getOnlineStatus } from "../helpers/useOnlineStatus";
import endpoints, { axios } from "../config/api";
import database from "../database";
import createOfflineEvent from "../database/common/createOfflineEvent";

async function createSubtask(
  projectId: number,
  taskId: number,
  subtask: any,
  isCompleted: boolean = false
) {
  if (!getOnlineStatus()) {
    const storedTask: any = await database.get(
      `projects:${projectId}:tasks:${taskId}`
    );
    const tempId = Math.floor(Math.random() * 9999999) + 1;
    const updatedTask = {
      ...storedTask,
      subtasks: [{ ...subtask, id: tempId }, ...storedTask.subtasks]
    };

    await createOfflineEvent("task", "CREATE", {
      id: tempId,
      name: subtask.name,
      description: subtask.description,
      parentTaskId: taskId.toString(),
      expectedCompletionDate: storedTask.expectedCompletionDate,
      projectId,
      isCompleted
    });
    await database.put(updatedTask, { force: true });
    return subtask;
  }

  const formData = new FormData();
  formData.append("name", subtask.name);
  formData.append("description", subtask.description);
  formData.append("parentTaskId", taskId.toString());
  formData.append("isCompleted", isCompleted.toString());

  const response = await axios.post(endpoints.task.create(projectId), formData);
  return response.data;
}

export default createSubtask;
