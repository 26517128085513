function setItem(key: string, value: any, ttl: number): void {
  const now = new Date();

  const item = {
    value,
    expiry: now.getTime() + ttl
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getItem(key: string): any {
  const storedItem = localStorage.getItem(key);
  if (!storedItem) {
    return null;
  }

  const item = JSON.parse(storedItem);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export { setItem, getItem };
