import React from "react";

interface Props {
  onSelectTemplate: () => void;
  onCreateNew: () => void;
}

const SelectFromTemplateOrCreate = ({
  onSelectTemplate,
  onCreateNew
}: Props) => {
  return (
    <div>
      <p>Would you like to prefill fields from a template </p>
      <div className="flex flex-col">
        <button
          className={` btn-primary w-full justify-start`}
          onClick={() => onSelectTemplate()}
        >
          Select From Template
        </button>
        <button
          className={`btn-primary w-full justify-start my-3`}
          onClick={() => onCreateNew()}
        >
          Create New
        </button>
      </div>
    </div>
  );
};

export default SelectFromTemplateOrCreate;
