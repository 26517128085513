import React, { useState, useEffect } from "react";
import SideBackground from "../../assets/img/logo.svg";
import AppleBridgeLogo from "../../assets/img/logoWithBlackText.svg";
import TextInput from "../../components/Input/TextInput";
import LoginLayout from "../../layouts/LoginLayout";
import Button from "../../components/Button";
import useAxois from "axios-hooks";
import useQuery from "../../helpers/useQuery";
import api from "../../config/api";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Alert, { AlertType } from "../../components/Alert";

function passwordsMatch(a: string, b: string) {
  return a === b;
}

function PasswordResetPage() {
  const history = useHistory();
  const query = useQuery();
  const resetToken = useLocation()
    .search.split("&")
    .reduce((a, b) => {
      let [key, val] = b.split("=");
      a[key] = val;
      return a;
    }, {} as any)["?token"];

  const [isValid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [username, setUsername] = useState(query.get("user"));
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [{ loading, error }, submit] = useAxois(
    { url: api.authentication.reset, method: "POST" },
    { manual: true }
  );

  useEffect(() => {
    if (!username) {
      setValid(false);
      return;
    }

    if (password.length < 8) {
      setValid(false);
      return;
    }

    if (password !== confirmPassword) {
      setValid(false);
      return;
    }

    setValid(true);
  }, [username, password, confirmPassword]);

  if (!resetToken || !username) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout>
      <section className="flex flex-1 justify-center pt-8 md:pt-0 md:items-center">
        <div className="w-screen px-4 sm:px-0 sm:w-2/3 lg:w-2/5">
          <div className="my-16 flex justify-center md:justify-start">
            <img
              className="h-16"
              src={AppleBridgeLogo}
              alt="Applebridge logo"
            />
          </div>
          <div className="mb-4">
            {error && error.response?.status === 400 && (
              <Alert
                label={
                  error.response.data.NewPassword ||
                  "Invalid password reset request. Please request a new email"
                }
                type={AlertType.Warning}
              />
            )}
            {success ? (
              <Alert
                label="Password successfully reset"
                type={AlertType.Info}
              />
            ) : (
              <TextInput
                label="Username"
                placeholder="Enter your username"
                value={username}
                type="email"
                onChange={e => setUsername(e.target.value)}
              />
            )}
          </div>
          {!success && (
            <>
              <div className="mb-4">
                <TextInput
                  label="New Password"
                  placeholder="Enter your new password"
                  value={password}
                  errorMessage={
                    !passwordsMatch(password, confirmPassword)
                      ? "Passwords do not match"
                      : ""
                  }
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <TextInput
                  label="Confirm Password"
                  placeholder="Enter your new password"
                  type="password"
                  errorMessage={
                    !passwordsMatch(password, confirmPassword)
                      ? "Passwords do not match"
                      : ""
                  }
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
            </>
          )}
          <div className="flex justify-between w-full items-center">
            {success ? (
              <button
                className={loading ? "btn-disabled" : "btn-primary"}
                onClick={() => history.push("/login")}
              >
                Go to Login
              </button>
            ) : (
              <button
                className={!isValid ? "btn-disabled" : "btn-primary"}
                disabled={!isValid}
                onClick={() =>
                  submit({
                    data: {
                      username,
                      newPassword: password,
                      token: decodeURIComponent(resetToken)
                    }
                  })
                    .then(() => setSuccess(true))
                    .catch(console.error)
                }
              >
                Reset Password
              </button>
            )}
          </div>
        </div>
      </section>
      <section className="flex flex-1">
        <img
          className="h-full w-full object-cover"
          src={SideBackground}
          alt="Applebridge Background"
        />
      </section>
    </LoginLayout>
  );
}

export default PasswordResetPage;
