import React, {
  useContext,
  FunctionComponent,
  useState,
  useEffect
} from "react";
import api from "../api";
import { useAuth } from "./AuthProvider";

interface NotificationProps {
  totalNotifications: number;
  removeNotification: () => void;
  getCurrentNotifications: () => void;
}

export const NotificationContext = React.createContext<NotificationProps>({
  totalNotifications: 0,
  removeNotification: () => {},
  getCurrentNotifications: () => {}
});

export const useNotifications = () => useContext(NotificationContext);

const NotificationProvider: FunctionComponent = ({ children }) => {
  const [currentNotifications, setCurrentNotifications] = useState(0);
  const { isAuthenticated } = useAuth();
  const getCurrentNotifications = () => {
    api
      .getTotalUserNotifications()
      .then(data => {
        setCurrentNotifications(data);
      })
      .catch(e => console.error(e));
  };

  const removeNotification = () => {
    setCurrentNotifications(currentNotifications - 1);
  };
  useEffect(() => {
    if (isAuthenticated) {
      getCurrentNotifications();
    }
  }, [isAuthenticated]);

  return (
    <NotificationContext.Provider
      value={{
        totalNotifications: currentNotifications,
        removeNotification,
        getCurrentNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
