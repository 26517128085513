import React, { useContext, FunctionComponent, useState } from "react";
import CheckIsMobile from "../helpers/checkIsMobile";

interface SidebarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  closeSidebar: () => void;
}

export const SidebarContext = React.createContext<SidebarProps>({
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  closeSidebar: () => {}
});

export const useSidebar = () => useContext(SidebarContext);

const SidebarProvider: FunctionComponent = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const closeSidebar = () => {
    if (CheckIsMobile() && isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, setIsSidebarOpen, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
