import React, { FC, Fragment } from "react";

const OfflinePage: FC = () => {
  return (
    <Fragment>
      <div className="flex justify-center items-center h-screen">
        <h1>This page is not available offline</h1>
      </div>
    </Fragment>
  );
};

export default OfflinePage;
