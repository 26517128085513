import React from "react";
import { useDropzone } from "react-dropzone";
import dropzoneAcceptedFileTypes from "../../helpers/dropzoneAcceptedFileTypes";

interface Props {
  label: string;
  onDrop: (file: File) => void;
  acceptedFileName?: string;
  dropzoneError?: string;
  acceptedFileTypes?: string[];
}

const Dropzone = ({
  onDrop,
  label,
  acceptedFileName,
  dropzoneError,
  acceptedFileTypes = dropzoneAcceptedFileTypes
}: Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject
  } = useDropzone({
    accept: acceptedFileTypes,
    multiple: false,
    onDrop: (acceptedFiles, files) => {
      onDrop(acceptedFiles[0]);
    }
  });

  return (
    <div
      {...getRootProps()}
      className={`border ${
        (dropzoneError && dropzoneError.length > 0) || isDragReject
          ? "border-red"
          : "border-lightBlue"
      } border-dashed my-8 h-24 justify-center align-middle w-full focus:outline-none`}
    >
      <input {...getInputProps()} />
      {}
      {isDragActive ? (
        <p
          className={`flex align-middle justify-center h-full items-center px-2 text-center focus:outline-none ${
            isDragReject ? "text-red" : ""
          }`}
        >
          {isDragReject ? "Filetype not allowed" : "Drop the file here ..."}
        </p>
      ) : (
        <p
          className={`flex align-middle justify-center h-full items-center px-2 text-center ${
            dropzoneError && dropzoneError.length > 0 ? "text-red" : ""
          } focus:outline-none`}
        >
          {acceptedFileName
            ? acceptedFileName
            : dropzoneError && dropzoneError.length > 0
            ? dropzoneError
            : label}
        </p>
      )}
    </div>
  );
};

export default Dropzone;
