import endpoints, { axios } from "../../config/api";

async function uploadFile(
  folderId: string,
  name: string,
  isPrivate: boolean,
  file: File
) {
  const documentToCreate = new FormData();
  documentToCreate.set("name", name);
  documentToCreate.set("isPrivate", isPrivate ? "true" : "false");
  documentToCreate.append("file", file);

  const response = await axios.post(
    endpoints.newfolders.folderContents(folderId),
    documentToCreate
  );

  return response.data;
}

export default uploadFile;
