import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";

async function getTaskMembers(
  projectId: number,
  taskId: number,
  search: string
) {
  if (!getOnlineStatus()) {
    const tasks = (await database.get(
      `projects:${projectId}:tasks:${taskId}`
    )) as any;

    return {
      payload: tasks.members,
      maxPage: 1,
      currentPage: 1
    };
  }

  const response = await axios.get(
    endpoints.task.getUsers(projectId, taskId, search)
  );

  return {
    payload: response.data,
    maxPage: 1,
    currentPage: 1
  };
}

export default getTaskMembers;
