import endpoints, { axios } from "../config/api";

async function getProjectCommercials(projectId: number, currentPage: number) {
  const response = await axios.get(
    endpoints.project.getCommercials(projectId, currentPage)
  );
  return response.data;
}

export default getProjectCommercials;
