import React, { ReactType, FunctionComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import checkUserRole from "../../helpers/checkUserRole";
import { useAuth } from "../../state/AuthProvider";

import OfflinePage from "../../pages/OfflinePage";
import { getOnlineStatus } from "../../helpers/useOnlineStatus";

interface Props {
  component: ReactType;
  requiredRole: string;
  allowOffline?: boolean;
  [x: string]: any;
}
const PrivateRoute: FunctionComponent<Props> = ({
  component: Component,
  requiredRole = "STANDARDUSER",
  allowOffline,
  ...rest
}) => {
  const { user, isAuthenticated } = useAuth();
  const roleCheck = user ? checkUserRole(requiredRole, user?.role) : false;

  if (!getOnlineStatus() && !allowOffline) {
    return <OfflinePage />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true && roleCheck ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
