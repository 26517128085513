import endpoints, { axios } from "../config/api";

function removeDependancyFromTask(
  projectId: number,
  taskId: number,
  dependancyId: number
) {
  return axios.delete(endpoints.task.addTaskDependancy(projectId, taskId), {
    data: [dependancyId]
  });
}

export default removeDependancyFromTask;
