import endpoints, { axios } from "../../config/api";

async function getRootFolderTemplateTask(taskId: number) {
  return await (
    await axios.get(endpoints.taskTemplates.getRootFolder(taskId), {
      maxRedirects: 1
    })
  ).data;
}

export default getRootFolderTemplateTask;
