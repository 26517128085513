import endpoints, { axios } from "../config/api";

async function createCommercialDocument(
  projectId: number,
  templateId: number,
  name: string
) {
  const response = await axios.post(endpoints.commercials.create(projectId), {
    name,
    templateId
  });

  return response.data;
}

export default createCommercialDocument;
