import React, { useState, useRef, useEffect, Fragment } from "react";
import { PulseLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroller";
import ProtectedComponent from "../../components/ProtectedComponent";
import Document from "../../types/Document";
import { useToasts } from "react-toast-notifications";
import CreateDocumentModal from "../DocumentPage/CreateDocumentModal";
import DeleteDocumentModal from "../DocumentPage/DeleteDocumentModal";
import ListItem from "../../components/List/ListItem";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ActionIcon from "../../assets/img/options.svg";
import Popover from "react-awesome-popover";
import api from "../../api";
import { useOnlineProvider } from "../../state/OnlineProvider";
import { useAuth } from "../../state/AuthProvider";
import classnames from "classnames";
import settings from "../../config/settings";
import endpoints from "../../config/api";

interface Props {
  projectId: number;
  taskId: number;
}

const TaskDocumentTab = ({ projectId, taskId }: Props) => {
  const { addToast } = useToasts();
  const [currentDocuments, setCurrentDocuments] = useState<Document[]>([]);
  const [currentFolder, setCurrentFolder] = useState<Document>({} as Document);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const scrollRef = useRef(null);
  const { isOnline } = useOnlineProvider();
  const { user } = useAuth();

  const [documentFile, setDocumentFile] = useState<File>();
  const [documentFileError, setDocumentFileError] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentNameError, setDocumentNameError] = useState("");
  const [documentIsPrivate, setDocumentIsPrivate] = useState(false);
  const [loading, setLoading] = useState(true);

  const [documentToDelete, setDocumentToDelete] = useState<string>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateLoading, setCreateLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const folder = await api.newFolders.getRootFolderTask(projectId, taskId);

      setCurrentFolder(folder);

      const response = await api.newFolders.getFolderContents(folder.id);

      setCurrentDocuments(
        response.payload.filter(x => !/\.(jpe?g|png|gif|bmp)$/i.test(x.name))
      );
      setMaxPage(response.maxPage);
      setCurrentPage(response.currentPage);
      setLoading(false);
    };
    getData();
  }, [currentPage, projectId, taskId]);

  const createDocument = () => {
    if (!documentName || documentName.trim().length === 0) {
      setDocumentNameError("Please provide a file name");
      return;
    }
    setDocumentNameError("");
    if (!documentFile) {
      setDocumentFileError("Please provide a file to upload");
      return;
    }
    setDocumentFileError("");

    setCreateLoading(true);
    api.newFolders
      .uploadFile(
        currentFolder.id,
        documentName,
        documentIsPrivate,
        documentFile
      )
      .then((response: Document) => {
        setCurrentDocuments([response, ...currentDocuments]);
        closeCreateModal();
        addToast("Document Created Successfully", {
          appearance: "success",
          autoDismiss: true
        });
        setCreateLoading(false);
      })
      .catch((error: any) => {
        setCreateLoading(false);
        addToast("Error uploading document. Try again.", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const deleteDocument = (documentId: string) => {
    api.newFolders
      .deleteFile(currentFolder.id, documentId)
      .then(res => {
        addToast("Document Successfully Deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setCurrentDocuments(
          currentDocuments.filter(doc => doc.id !== documentId)
        );
        setIsDeleteModalOpen(false);
        setDocumentToDelete(undefined);
      })
      .catch(() => {
        addToast("Failed to delete document. Try again", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setDocumentFile(undefined);
    setDocumentName("");
    setDocumentIsPrivate(false);
  };

  const downloadDocument = (location: string) => {
    window.open(location, "_blank");
  };

  return (
    <div>
      <CreateDocumentModal
        documentName={documentName}
        dropzoneLabel="Drop your document here or click to open the file explorer"
        isModalOpen={isCreateModalOpen}
        onDocumentNameChange={value => setDocumentName(value)}
        onDocumentUpload={file => setDocumentFile(file)}
        onSubmit={() => createDocument()}
        setIsModalOpen={setIsCreateModalOpen}
        acceptedFileName={documentFile?.name}
        nameError={documentNameError}
        isPrivate={documentIsPrivate}
        setIsPrivate={setDocumentIsPrivate}
        closeModal={() => closeCreateModal()}
        dropzoneError={documentFileError}
        isDocumentCreating={isCreateLoading}
        disableFolderSelect
      />

      <DeleteDocumentModal
        setIsModalOpen={setIsDeleteModalOpen}
        isModalOpen={isDeleteModalOpen}
        onConfirm={() => documentToDelete && deleteDocument(documentToDelete)}
      />
      {isOnline && (
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <button
            className="btn-secondary w-full md:w-56 mt-12"
            onClick={() => setIsCreateModalOpen(true)}
          >
            + Upload a Document
          </button>
        </ProtectedComponent>
      )}

      {isOnline ? (
        <div className="flex flex-col mt-8">
          <h5 className="text-lightBlue">Name</h5>
          <div>
            <InfiniteScroll
              pageStart={1}
              threshold={250}
              hasMore={currentPage < maxPage}
              loadMore={() => setCurrentPage(currentPage + 1)}
              useWindow={false}
              element="div"
              className="mb-8"
              getScrollParent={() => scrollRef.current}
              loader={
                <div className="w-full flex justify-center items-center">
                  <PulseLoader color="#00B2A9" />
                </div>
              }
            >
              <table>
                <thead>
                  <tr className="w-full">
                    <th className=""></th>
                    <th className="w-auto"></th>
                    <th className="w-1/3"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentDocuments.length > 0 ? (
                    currentDocuments
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map(document => {
                        return (
                          <Fragment>
                            <tr
                              className=" w-full  cursor-pointer"
                              key={document.id}
                            >
                              <td>
                                <h4
                                  className={classnames(
                                    `no-underline text-darkGrey font-normal break-all`,
                                    {
                                      "cursor-pointer": isOnline
                                    }
                                  )}
                                  onClick={() =>
                                    isOnline &&
                                    downloadDocument(
                                      `${
                                        settings.engineUrl
                                      }${endpoints.newfolders.downloadFile(
                                        currentFolder.id,
                                        document.id
                                      )}`
                                    )
                                  }
                                >
                                  {document.name}
                                </h4>
                              </td>
                              <td
                                className=""
                                onClick={() =>
                                  isOnline &&
                                  downloadDocument(
                                    `${
                                      settings.engineUrl
                                    }${endpoints.newfolders.downloadFile(
                                      currentFolder.id,
                                      document.id
                                    )}`
                                  )
                                }
                              >
                                {new Date(
                                  document.createdAt
                                ).toLocaleDateString()}
                              </td>
                              <td>
                                <div className="flex justify-between h-full ">
                                  {isOnline && (
                                    <ProtectedComponent requiredRole="PROJECTMANAGER">
                                      <div
                                        id="deleteIcon"
                                        className="cursor-pointer hidden sm:block"
                                        onClick={() => {
                                          setDocumentToDelete(document.id);
                                          setIsDeleteModalOpen(true);
                                        }}
                                      />
                                    </ProtectedComponent>
                                  )}
                                  {isOnline && (
                                    <div className="flex justify-center align-middle">
                                      <div
                                        className="cursor-pointer hidden sm:block"
                                        id="downloadIcon"
                                        onClick={() =>
                                          downloadDocument(
                                            `${
                                              settings.engineUrl
                                            }${endpoints.newfolders.downloadFile(
                                              currentFolder.id,
                                              document.id
                                            )}`
                                          )
                                        }
                                      />
                                      <div className="block sm:hidden">
                                        <Popover
                                          overlayColor="rgba(0,0,0,0)"
                                          placement="left-center"
                                          arrowProps={{
                                            className: "text-white "
                                          }}
                                          arrow={!CheckIsMobile()}
                                          initZindex={0}
                                        >
                                          <button className="py-3 m-0 pr-4 focus:outline-none cursor-pointer ">
                                            <img
                                              className="h-full w-full"
                                              src={ActionIcon}
                                              alt="item options"
                                            />
                                          </button>
                                          <div className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-xl">
                                            <button
                                              className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                                              onClick={() =>
                                                downloadDocument(
                                                  `${
                                                    settings.engineUrl
                                                  }${endpoints.newfolders.downloadFile(
                                                    currentFolder.id,
                                                    document.id
                                                  )}`
                                                )
                                              }
                                            >
                                              Download Document
                                            </button>
                                            <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                                            <ProtectedComponent requiredRole="PROJECTMANAGER">
                                              <button
                                                className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                                                onClick={() => {
                                                  setDocumentToDelete(
                                                    document.id
                                                  );
                                                  setIsDeleteModalOpen(true);
                                                }}
                                              >
                                                <div
                                                  id="deleteIcon"
                                                  className="cursor-pointer  pr-4"
                                                  onClick={() => {
                                                    setDocumentToDelete(
                                                      document.id
                                                    );
                                                    setIsDeleteModalOpen(true);
                                                  }}
                                                />
                                                Delete Document
                                              </button>
                                            </ProtectedComponent>
                                          </div>
                                        </Popover>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })
                  ) : loading ? (
                    <div className="flex justify-center">
                      <PulseLoader color="#00B2A9" />
                    </div>
                  ) : (
                    <ListItem
                      containerStyle="bg-white hover:bg-lightGrey "
                      label="No Documents found"
                    />
                  )}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <p className="mt-8">
          An internet connection is required for this section
        </p>
      )}
    </div>
  );
};

export default TaskDocumentTab;
