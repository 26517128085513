import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";

async function getTaskById(projectId: number, taskId: number) {
  if (!getOnlineStatus()) {
    const task = await database.get(`projects:${projectId}:tasks:${taskId}`);
    return task;
  }

  const response = await axios.get(endpoints.task.get(projectId, taskId));
  return response.data;
}

export default getTaskById;
