import DataEvent from "../types/Event";
import shortid from "shortid";
import database from "..";

async function createOfflineEvent(
  entity: any,
  action: any,
  payload: any,
  id: any = null
) {
  try {
    const event: DataEvent = {
      entity,
      action,
      created: new Date(),
      payload
    };

    const dbId = id || shortid.generate();

    database.put({
      _id: `offline:${dbId}`,
      ...event
    });
  } catch (error) {
    console.error(error);
  }
}

export default createOfflineEvent;
