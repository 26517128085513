import endpoints, { axios } from "../config/api";

function login(username: string, password: string) {
  return axios.post(endpoints.authentication.login, {
    username,
    password
  });
}

export default login;
