import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import TextInput from "../../components/Input/TextInput";
import Dropzone from "../../components/Dropzone";
import { useAuth } from "../../state/AuthProvider";
import checkUserRole from "../../helpers/checkUserRole";
import CheckboxInput from "../../components/Input/CheckboxInput";
import { PulseLoader } from "react-spinners";
import dropzoneAcceptedFileTypes from "../../helpers/dropzoneAcceptedFileTypes";
import api from "../../api";
import SelectInput from "../../components/Input/SelectInput";
import { FolderPath } from "../../types/Folder";
import { useToasts } from "react-toast-notifications";
import Document from "../../types/Document";

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  documentName: string;
  onDocumentNameChange: (value: string) => void;
  dropzoneLabel: string;
  onDocumentUpload: (file: File) => void;
  onSubmit: () => void;
  acceptedFileName?: string;
  nameError: string;
  dropzoneError: string;
  isPrivate: boolean;
  setIsPrivate: (isPrivate: boolean) => void;
  closeModal: () => void;
  isDocumentCreating: boolean;
  acceptedFileTypes?: string[];
  modalTitle?: string;
  nameLabel?: string;
  setSelectedFolder?: (folderPath: Document | undefined) => void;
  selectedFolder?: Document;
  defaultFolder?: string;
  projectId?: number;
  disableFolderSelect?: boolean;
  disablePrivateSelect?: boolean;
  rootFolderId?: string;
}

const CreateDocumentModal = ({
  documentName,
  isModalOpen,
  setIsModalOpen,
  dropzoneLabel,
  onDocumentNameChange,
  onDocumentUpload,
  onSubmit,
  acceptedFileName,
  nameError,
  isPrivate,
  setIsPrivate,
  dropzoneError,
  closeModal,
  isDocumentCreating,
  acceptedFileTypes = dropzoneAcceptedFileTypes,
  modalTitle = "Add a Document",
  nameLabel = "Document Name",
  setSelectedFolder,
  selectedFolder,
  defaultFolder,
  projectId,
  disableFolderSelect,
  disablePrivateSelect,
  rootFolderId
}: Props) => {
  const { user, isAuthenticated } = useAuth();
  const [folderPaths, setFolderPaths] = useState<Document[]>([]);
  const { addToast } = useToasts();
  const [nameRegexError, setNameRegexError] = useState("");

  useEffect(() => {
    (async () => {
      if (isModalOpen) {
        const rootFolder = rootFolderId
          ? await api.newFolders.getNewFolder(rootFolderId)
          : await (projectId
              ? api.newFolders.getRootFolderProject(projectId)
              : api.newFolders.getGlobalFolder());

        api.newFolders.getSubfolders(rootFolder.id).then((data: Document[]) => {
          console.log(data, defaultFolder);
          if (defaultFolder) {
            var folders = data.filter(x => x.id === defaultFolder);
            if (folders.length && setSelectedFolder) {
              setSelectedFolder(folders[0]);
            }
          }
          setFolderPaths([rootFolder, ...data]);
        });
      }
    })();
  }, [defaultFolder, isModalOpen, projectId]);

  return (
    <Modal
      onClose={() => closeModal()}
      open={isModalOpen}
      center={true}
      showCloseIcon={false}
    >
      <h2>{modalTitle}</h2>
      <TextInput
        label={nameLabel}
        errorMessage={nameRegexError || nameError}
        value={documentName}
        onChange={e => {
          const regex = RegExp("^[a-zA-Z . 0-9_-]+$");
          if (!regex.test(e.target.value)) {
            setNameRegexError(
              "Document names can not contain special characters."
            );
          } else {
            setNameRegexError("");
          }
          onDocumentNameChange(e.target.value);
        }}
        maxLength={256}
      />
      <Dropzone
        label={dropzoneLabel}
        onDrop={file => onDocumentUpload(file)}
        acceptedFileName={acceptedFileName}
        dropzoneError={dropzoneError}
        acceptedFileTypes={acceptedFileTypes}
      />
      {!disableFolderSelect && (
        <SelectInput
          className="mb-8"
          label="Select a folder for your document"
          placeholder="Select a folder for your document"
          value={
            selectedFolder
              ? {
                  value: selectedFolder.id,
                  label: selectedFolder.path,
                  data: selectedFolder
                }
              : {}
          }
          options={folderPaths.map(fp => {
            return {
              value: fp.id?.toString(),
              label: fp.path,
              data: fp
            };
          })}
          onChange={e => {
            setSelectedFolder && setSelectedFolder((e as any).data);
          }}
        />
      )}
      {!disablePrivateSelect &&
        user &&
        isAuthenticated &&
        checkUserRole("ADMINISTRATOR", user.role) && (
          <CheckboxInput
            label="Private"
            className="w-6 h-6"
            value=""
            checked={isPrivate}
            onChange={e => setIsPrivate(e.target.checked)}
          />
        )}
      <div className="flex flex-col-reverse sm:flex-row  mt-8">
        <button
          className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          disabled={isDocumentCreating || nameRegexError !== ""}
          className={`btn-${
            isDocumentCreating ? "disabled" : "primary"
          }  w-full sm:w-56 `}
          onClick={() => onSubmit()}
        >
          {isDocumentCreating ? <PulseLoader color="#00B2A9" /> : "Create"}
        </button>
      </div>
    </Modal>
  );
};

export default CreateDocumentModal;
