import React, { useState, FC, Fragment } from "react";
import ProtectedComponent from "../../../components/ProtectedComponent";
import ListItem from "../../../components/List/ListItem";
import ProjectTemplateAddTaskModal from "./ProjectTemplateAddTaskModal";
import TaskTemplate, { TaskTemplateWithKey } from "../../../types/TaskTemplate";
import { useToasts } from "react-toast-notifications";
import api from "../../../api";
import ConfirmDeleteModal from "../../../components/Modal/ConfirmDeleteModal";
import Task from "../../../types/Task";
import shortid from "shortid";

interface Props {
  tasks: TaskTemplateWithKey[];
  setTasks: (selectedTasks: TaskTemplateWithKey[]) => void;
  templateId: number;
  taskDependencies: any[];
  setTaskDependencies: (dependencies: any[]) => void;
}

const ProjectTemplateEditTaskPage: FC<Props> = ({
  setTasks,
  tasks,
  templateId,
  setTaskDependencies,
  taskDependencies
}) => {
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<TaskTemplateWithKey>();
  const { addToast } = useToasts();

  const addTask = (
    newTask: TaskTemplate,
    dependenciesToDisplay: TaskTemplate[]
  ) => {
    api
      .addTaskToProjectTemplate(templateId, newTask.id)
      .then(() => {
        const newKey = shortid.generate();
        setTasks([...tasks, { ...newTask, key: newKey }]);
        setTaskDependencies([
          ...taskDependencies,
          { id: newKey, dependencies: dependenciesToDisplay }
        ]);
        setIsAddTaskModalOpen(false);
        addToast(`Task Added Successfully`, {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        addToast(`Failed to Add Task`, {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const deleteTask = (task: TaskTemplateWithKey) => {
    api
      .deleteTaskFromProjectTemplate(templateId, task.id)
      .then(() => {
        setTasks(tasks.filter(t => t.key !== task.key));
        setIsDeleteTaskModalOpen(false);
        addToast(`Task Deleted Successfully`, {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        setIsDeleteTaskModalOpen(false);
        addToast(`Failed to Delete Task`, {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  return (
    <div>
      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        <div>
          {tasks.length > 0 ? (
            tasks.map(task => (
              <Fragment>
                <ListItem
                  isClickable={false}
                  containerStyle="bg-lightGrey hover:bg-white "
                  label={task.name}
                  middleIcon={<div></div>}
                  popover={
                    <div
                      id="deleteIcon"
                      className="cursor-pointer invisible sm:visible"
                      onClick={() => {
                        setTaskToDelete(task);
                        setIsDeleteTaskModalOpen(true);
                      }}
                    />
                  }
                />
                {taskDependencies.find(x => x.id === task.key) &&
                  taskDependencies
                    .find(x => x.id === task.key)
                    .dependencies.map((dependency: any) => {
                      return (
                        <ListItem
                          containerStyle="bg-lightGrey hover:bg-white pl-4"
                          label={`> ${dependency.name}`}
                          middleIcon={<div></div>}
                          popover={<div></div>}
                          isClickable={false}
                        />
                      );
                    })}
              </Fragment>
            ))
          ) : (
            <ListItem
              containerStyle="bg-lightGrey hover:bg-white "
              label="No Tasks Found"
            />
          )}
        </div>
        <div>
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <ConfirmDeleteModal
              isModalOpen={isDeleteTaskModalOpen}
              setIsModalOpen={setIsDeleteTaskModalOpen}
              onConfirm={() => taskToDelete && deleteTask(taskToDelete)}
              entityLabel="Task from the Template"
            />
            <ProjectTemplateAddTaskModal
              isModalOpen={isAddTaskModalOpen}
              closeModal={() => setIsAddTaskModalOpen(false)}
              onSubmit={(newTask, d) => {
                addTask(newTask, d);
              }}
            />
            <button
              className="btn-tertiary mt-8 "
              onClick={() => setIsAddTaskModalOpen(true)}
            >
              + Add a task
            </button>
          </ProtectedComponent>
        </div>
      </div>
    </div>
  );
};

export default ProjectTemplateEditTaskPage;
