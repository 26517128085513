const CheckIsMobile = () => {
  if (
    (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)) &&
    !window.matchMedia("(min-width: 640px)").matches
  ) {
    return true;
  }
  return false;
};

export default CheckIsMobile;
