import React, { FC, useState, useRef, useEffect } from "react";
import User from "../../types/User";
import { useHistory } from "react-router-dom";
import { useTranslate } from "react-polyglot";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ProtectedComponent from "../../components/ProtectedComponent";
import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import ActionIcon from "../../assets/img/options.svg";

interface Props {
  item: User;
  onAccountClicked: (userId: string) => void;
  setUserToDelete: (userId: string) => void;
  setIsModalOpen: (isOpen: boolean) => void;
}

const TeamMemberListItem: FC<Props> = ({
  item,
  onAccountClicked,
  setIsModalOpen,
  setUserToDelete
}) => {
  const history = useHistory();
  const t = useTranslate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <tr
      className="bg-lightGrey w-full hover:bg-white my-2 cursor-pointer "
      key={item.id}
    >
      <td
        className="w-12 pl-0 md:pl-2 py-1"
        onClick={() => history.push(`/users/${item.id}`)}
      >
        <img
          className="rounded-full w-10 h-10"
          src={
            item.profileImage
              ? item.profileImage
              : `https://eu.ui-avatars.com/api/?name=${item.firstName}+${item.lastName}&size=64`
          }
          alt="profile iamge"
        />
      </td>
      <td className="pl-2" onClick={() => history.push(`/users/${item.id}`)}>
        {item.firstName} {item.lastName}
      </td>
      <td
        className="invisible sm:visible"
        onClick={() => history.push(`/users/${item.id}`)}
      >
        {t(item.role)}
      </td>
      <td>
        <div className="flex justify-end min-w-10">
          <Popover
            overlayColor="rgba(0,0,0,0)"
            placement="left-center"
            arrowProps={{ className: "text-white " }}
            arrow={!CheckIsMobile()}
            action={null}
            open={isPopoverOpen}
            initZindex={0}
          >
            <button
              className="py-3 m-0 pr-4  focus:outline-none cursor-pointer "
              onClick={() => setIsPopoverOpen(true)}
              onBlur={() => setIsPopoverOpen(false)}
            >
              <img
                className="h-full w-full"
                src={ActionIcon}
                alt="item options"
              />
            </button>
            <div
              ref={popoverRef}
              className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-x"
            >
              <button
                className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                onMouseDown={() => {
                  onAccountClicked(item.id);
                  setIsPopoverOpen(false);
                }}
              >
                View User
              </button>
              <ProtectedComponent requiredRole="ADMINISTRATOR">
                <div className="h-1 w-full border-t-2 border-lightGrey"></div>
                <button
                  className="btn-tertiary mt-2  text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                  onMouseDown={() => {
                    setUserToDelete(item.id);
                    setIsModalOpen(true);
                    setIsPopoverOpen(false);
                  }}
                >
                  <img src={deleteHoverIcon} className="pr-4 " alt="Delete" />
                  Delete User
                </button>
              </ProtectedComponent>
            </div>
          </Popover>
        </div>
      </td>
    </tr>
  );
};

export default TeamMemberListItem;
