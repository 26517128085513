import React, { useState, FC } from "react";
import ProtectedComponent from "../../../components/ProtectedComponent";
import { useToasts } from "react-toast-notifications";
import CreateDocumentModal from "../../DocumentPage/CreateDocumentModal";
import ListItem from "../../../components/List/ListItem";
import dropzoneAcceptedFileTypes from "../../../helpers/dropzoneAcceptedFileTypes";
import { DocumentToCreate } from "../../../pages/Wizards/ProjectTemplateWizard/index";
import Document from "../../../types/Document";

interface Props {
  documentsToCreate: DocumentToCreate[];
  existingDocuments?: Document[];
  setDocumentsToCreate: (newDocuments: DocumentToCreate[]) => void;
  setExistingDocuments?: (existingDocuments: Document[]) => void;
}

interface IDocumentToCreate {
  name: string;
  file: File;
  isGlobal: boolean;
  isPrivate: boolean;
}

const ProjectDocumentUploadPage: FC<Props> = ({
  documentsToCreate,
  setDocumentsToCreate,
  existingDocuments,
  setExistingDocuments
}) => {
  const { addToast } = useToasts();

  const [documentToCreateFile, setDocumentToCreateFile] = useState<File>();
  const [documentToCreateFileError, setDocumentToCreateFileError] = useState(
    ""
  );

  const [documentToCreateName, setDocumentToCreateName] = useState("");
  const [documentToCreateNameError, setDocumentToCreateNameError] = useState(
    ""
  );
  const [documentToCreatePrivate, setDocumentToCreatePrivate] = useState(false);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const createDocument = () => {
    if (!documentToCreateName || documentToCreateName.trim().length === 0) {
      setDocumentToCreateNameError("Please provide a file name");
      return;
    }
    setDocumentToCreateNameError("");
    if (!documentToCreateFile) {
      setDocumentToCreateFileError("Please provide a file to upload");
      return;
    }
    setDocumentToCreateFileError("");

    setDocumentsToCreate([
      ...documentsToCreate,
      {
        isGlobal: false,
        name: documentToCreateName,
        file: documentToCreateFile,
        isPrivate: documentToCreatePrivate
      }
    ]);
    closeCreateModal();
    addToast("Document Created Successfully", {
      appearance: "success",
      autoDismiss: true
    });
  };

  const deleteDocument = (document: IDocumentToCreate) => {
    setDocumentsToCreate(documentsToCreate.filter(d => d !== document));
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setDocumentToCreateFile(undefined);
    setDocumentToCreateName("");
    setDocumentToCreatePrivate(false);
  };

  return (
    <div>
      <CreateDocumentModal
        documentName={documentToCreateName}
        dropzoneLabel="Drop your document here or click to open the file explorer"
        isModalOpen={isCreateModalOpen}
        onDocumentNameChange={value => setDocumentToCreateName(value)}
        onDocumentUpload={file => setDocumentToCreateFile(file)}
        onSubmit={() => createDocument()}
        setIsModalOpen={setIsCreateModalOpen}
        acceptedFileName={documentToCreateFile?.name}
        nameError={documentToCreateNameError}
        isPrivate={documentToCreatePrivate}
        setIsPrivate={setDocumentToCreatePrivate}
        closeModal={() => closeCreateModal()}
        dropzoneError={documentToCreateFileError}
        isDocumentCreating={false}
        acceptedFileTypes={dropzoneAcceptedFileTypes}
      />

      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        <div>
          {existingDocuments &&
            existingDocuments
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map(document => {
                return (
                  <ListItem
                    containerStyle="bg-lightGrey hover:bg-white"
                    label={document.name}
                    labelStyle="no-underline"
                    popover={
                      <ProtectedComponent requiredRole="PROJECTMANAGER">
                        <div
                          id="deleteIcon"
                          className="cursor-pointer hidden sm:block"
                          onClick={() => {
                            setExistingDocuments &&
                              setExistingDocuments(
                                existingDocuments.filter(
                                  e => e.id !== document.id
                                )
                              );
                          }}
                        />
                      </ProtectedComponent>
                    }
                  />
                );
              })}
          {documentsToCreate.length > 0
            ? documentsToCreate
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map(document => {
                  return (
                    <ListItem
                      containerStyle="bg-lightGrey hover:bg-white"
                      label={document.name}
                      labelStyle="no-underline"
                      popover={
                        <ProtectedComponent requiredRole="PROJECTMANAGER">
                          <div
                            id="deleteIcon"
                            className="cursor-pointer hidden sm:block"
                            onClick={() => {
                              deleteDocument(document);
                            }}
                          />
                        </ProtectedComponent>
                      }
                    />
                  );
                })
            : (!existingDocuments || existingDocuments.length === 0) && (
                <ListItem
                  containerStyle="bg-lightGrey hover:bg-white "
                  label="No Documents found"
                />
              )}

          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <button
              className="btn-tertiary mt-8"
              onClick={() => setIsCreateModalOpen(true)}
            >
              + Upload a Document
            </button>
          </ProtectedComponent>
        </div>
      </div>
    </div>
  );
};

export default ProjectDocumentUploadPage;
