import React, { useState, useEffect, FC } from "react";
import Modal from "react-responsive-modal";
import classnames from "classnames";
import { PulseLoader } from "react-spinners";
import TextInput from "../Input/TextInput";
import useDebounce from "../../helpers/useDebounce";
import endpoints, { axios } from "../../config/api";
import User from "../../types/User";

interface Props {
  isModalOpen: boolean;
  onSubmit: (selectedUser: User) => void;
  closeModal: () => void;
  modalHeader?: string;
  projectId?: number;
  taskId?: number;
  searchAllUsers?: boolean;
}

const AddTeamMemberModal: FC<Props> = ({
  closeModal,
  isModalOpen,
  onSubmit,
  modalHeader = "Add a Team Member",
  projectId,
  taskId,
  searchAllUsers = false
}) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (projectId && !taskId) {
      setLoading(true);
      setUsers([]);
      axios
        .get(
          searchAllUsers
            ? endpoints.users.search(debouncedSearchTerm)
            : endpoints.project.searchAddableMembers(
                projectId,
                debouncedSearchTerm
              )
        )
        .then(({ data }) => {
          setUsers([...data.payload]);
          setLoading(false);
        })
        .catch(error => console.log(error));
    }
  }, [debouncedSearchTerm, projectId, taskId, searchAllUsers]);

  useEffect(() => {
    if (projectId && taskId) {
      setLoading(true);
      setUsers([]);
      axios
        .get(
          endpoints.task.searchAddableMembers(
            projectId,
            taskId,
            debouncedSearchTerm
          )
        )
        .then(({ data }) => {
          setUsers([...data.payload]);
          setLoading(false);
        })
        .catch(error => console.log(error));
    }
  }, [debouncedSearchTerm, projectId, taskId]);

  return (
    <Modal
      onClose={() => {
        setSearchTerm("");
        setSelectedUser(undefined);
        closeModal();
      }}
      open={isModalOpen}
    >
      <h2>{modalHeader}</h2>
      <div>
        <TextInput
          className="border-2"
          label="Search for a user to add"
          placeholder="Enter a user's name"
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          maxLength={256}
        />

        <div className="h-full pt-4">
          {loading ? (
            <div className="flex justify-center">
              <PulseLoader color="#00B2A9" />
            </div>
          ) : (
            users.slice(0, 10).map(u => (
              <div
                className={`flex flex-row py-1 px-2 cursor-pointer ${
                  selectedUser === u ? "bg-lightGrey" : ""
                }`}
                onClick={() => setSelectedUser(u)}
              >
                <img
                  className="rounded-full w-6 h-6"
                  src={
                    u.profileImage
                      ? u.profileImage
                      : `https://eu.ui-avatars.com/api/?name=${u.firstName}+${u.lastName}&size=64`
                  }
                  alt="profile iamge"
                />
                <p className="pl-4">{u.firstName + " " + u.lastName}</p>
              </div>
            ))
          )}
        </div>
        <div className="pt-4">
          <p>
            Selected User:{" "}
            {selectedUser &&
              selectedUser.firstName + " " + selectedUser.lastName}
          </p>
        </div>
        <div className="flex flex-row justify-between pt-4">
          <button
            className="btn-secondary border-red text-red hover:border-red hover:text-red w-32"
            onClick={() => {
              setSearchTerm("");
              setSelectedUser(undefined);
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            className={classnames("w-32", {
              "btn-primary": selectedUser,
              "btn-disabled": !selectedUser
            })}
            onClick={() => onSubmit && selectedUser && onSubmit(selectedUser)}
          >
            Add User
          </button>
        </div>
        <input type="text" autoFocus={true} className="hidden" />
      </div>
    </Modal>
  );
};

export default AddTeamMemberModal;
