import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";

async function getProjectTasks(
  projectId: number,
  page: number = 1,
  search?: string
) {
  if (!getOnlineStatus()) {
    const tasks = await database.find({
      selector: {
        _id: { $regex: `^projects:${projectId}:tasks:[0-9]+$` }
      }
    });

    return {
      payload: tasks.docs,
      maxPage: 1,
      currentPage: 1
    };
  }

  const response = await axios.get(
    endpoints.task.getProjectTasks(projectId, page, search)
  );
  return response.data;
}

export default getProjectTasks;
