import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import User from "../../types/User";
import { useHistory } from "react-router-dom";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ActionIcon from "../../assets/img/options.svg";
import { useTranslate } from "react-polyglot";
import ProtectedComponent from "../../components/ProtectedComponent";
import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import deleteIcon from "../../assets/img/delete.svg";
import { useOnlineProvider } from "../../state/OnlineProvider";
import profileIcon from "../../assets/img/profileIconGreen.svg";
import api from "../../api";
import { useAuth } from "../../state/AuthProvider";
import { useToasts } from "react-toast-notifications";

interface Props {
  item: User;
  projectId: number;
  setUserToRemove: (userId: string) => void;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
}

const ProjectTeamTabItem = ({
  item,
  setUserToRemove,
  setIsDeleteModalOpen,
  projectId
}: Props) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const t = useTranslate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { isOnline } = useOnlineProvider();
  const popoverRef = useRef<any>();
  const [newVal, setNewVal] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    user: { role: currentUserRole }
  } = useAuth();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  const updateCommercialNotifications = async (
    userId: string,
    receiveCommericalDocNotifications: boolean
  ) => {
    setLoading(true);
    try {
      await api.updateProjectUser(projectId, [
        {
          userId,
          receiveCommericalDocNotifications
        }
      ]);
      addToast("Member commercial notifications status updated", {
        appearance: "success",
        autoDismiss: true
      });
      item.receiveCommericalDocNotifications = receiveCommericalDocNotifications;
    } catch {
      addToast("Failed to create commercial document. Try again.", {
        appearance: "error",
        autoDismiss: true
      });
    }
    setLoading(false);
  };

  return (
    <tr
      className="bg-white hover:bg-lightGrey my-2 cursor-pointer "
      key={item.id}
    >
      <td
        className="w-12 pl-0 md:pl-2 py-1"
        onClick={() => history.push(`/users/${item.id}`)}
      >
        <img
          className={classnames("w-10 h-10", { "rounded-full": isOnline })}
          src={
            !isOnline
              ? profileIcon
              : item.profileImage
              ? item.profileImage
              : `https://eu.ui-avatars.com/api/?name=${item.firstName}+${item.lastName}&size=64`
          }
          alt="profile iamge"
        />
      </td>
      <td className="pl-2" onClick={() => history.push(`/users/${item.id}`)}>
        {item.firstName} {item.lastName}
      </td>
      <td
        className="invisible sm:visible"
        onClick={() => history.push(`/users/${item.id}`)}
      >
        {t(item.role)}
      </td>
      <td className=" " onClick={() => history.push(`/users/${item.id}`)}>
        {currentUserRole === "STANDARDUSER" ? (
          <span>{item.receiveCommericalDocNotifications ? "YES" : "NO"}</span>
        ) : (
          <input
            className={`checkbox appearance-none ${
              loading ? "border-grey" : "border-lightBlue"
            } border-2 cursor-pointer h-6 w-6`}
            type="checkbox"
            placeholder=""
            checked={item.receiveCommericalDocNotifications}
            onChange={e => {
              updateCommercialNotifications(
                item.id,
                !item.receiveCommericalDocNotifications
              );
            }}
            disabled={loading}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        )}
      </td>
      <td className="flex justify-end">
        <Popover
          overlayColor="rgba(0,0,0,0)"
          placement="left-center"
          arrowProps={{ className: "text-white " }}
          arrow={!CheckIsMobile()}
          action={null}
          open={isPopoverOpen}
          initZindex={0}
        >
          <button
            className="py-3 m-0 pr-4 focus:outline-none cursor-pointer "
            onClick={() => setIsPopoverOpen(true)}
            onBlur={() => setIsPopoverOpen(false)}
          >
            <img
              className="h-full w-full"
              src={ActionIcon}
              alt="item options"
            />
          </button>
          <div
            ref={popoverRef}
            className="bg-white p-2 flex flex-col w-56 h-32 justify-evenly shadow-xl"
          >
            <button
              className={classnames(
                "btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center sm:justify-start",
                {
                  "btn-tertiary": isOnline,
                  "text-darkGrey hover:text-darkGrey cursor-not-allowed": !isOnline
                }
              )}
              onClick={() => history.push(`/users/${item.id}`)}
              disabled={!isOnline}
            >
              View User
            </button>
            <div className="h-1 w-full border-t-2 border-lightGrey"></div>
            <ProtectedComponent requiredRole="PROJECTMANAGER">
              <button
                className={classnames(
                  "btn-tertiary mt-2  flex flex-row sm:pl-6 font-normal justify-center sm:justify-start",
                  {
                    " text-red hover:text-red": isOnline,
                    "text-darkGrey hover:text-darkGrey cursor-not-allowed": !isOnline
                  }
                )}
                disabled={!isOnline}
                onMouseDown={() => {
                  setIsPopoverOpen(false);
                  setUserToRemove(item.id);
                  setIsDeleteModalOpen(true);
                }}
              >
                <img
                  src={isOnline ? deleteHoverIcon : deleteIcon}
                  className="pr-4 "
                  alt="Delete"
                />
                Remove User
              </button>
            </ProtectedComponent>
          </div>
        </Popover>
      </td>
    </tr>
  );
};

export default ProjectTeamTabItem;
