import React, { FunctionComponent } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { useAuth } from "../state/AuthProvider";
import { Redirect } from "react-router-dom";

interface Props {
  forwardRef?: any;
}

const MainLayout: FunctionComponent<Props> = ({ children, forwardRef }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  if (!isAuthenticated && !isLoading) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="App bg-lightGrey">
      <Sidebar userName={user.firstName} />
      <div ref={forwardRef} className="main  flex flex-col">
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
