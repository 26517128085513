import React from "react";
import TextInput from "../../components/Input/TextInput";
import TextAreaInput from "../../components/Input/TextAreaInput";

interface Props {
  name: string;
  setName: (name: string) => void;
  nameError: string;
  description: string;
  setDescription: (description: string) => void;
  descriptionError: string;
}

const TaskTemplateDetailPage = ({
  name,
  setName,
  description,
  setDescription,
  nameError,
  descriptionError
}: Props) => {
  return (
    <div>
      <TextInput
        name="name"
        className="mb-8"
        label="Give your task template a name"
        placeholder="Give your task a name"
        errorMessage={nameError}
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        maxLength={256}
      />
      <TextAreaInput
        name="description"
        label="Enter a detailed description"
        errorMessage={descriptionError}
        className="h-40"
        onChange={({ target: { value } }) => setDescription(value)}
        value={description}
        maxLength={1024}
      />
    </div>
  );
};

export default TaskTemplateDetailPage;
