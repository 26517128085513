import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";

async function deleteSubTask(
  projectId: number,
  taskId: number,
  subtaskId: number
) {
  if (!getOnlineStatus()) {
    const storedTask: any = await database.get(
      `projects:${projectId}:tasks:${taskId}`
    );

    const filteredTasks = storedTask.subtasks.filter(
      (t: any) => t.id !== subtaskId
    );

    const updatedTask = {
      ...storedTask,
      subtasks: filteredTasks
    };

    await database.put(updatedTask, { force: true });
    return storedTask;
  }

  const response = await axios.delete(
    endpoints.task.delete(projectId, subtaskId)
  );
  return response.data;
}

export default deleteSubTask;
