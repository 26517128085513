import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import checkUserRole from "../../helpers/checkUserRole";
import { useAuth } from "../../state/AuthProvider";
import { useOnlineProvider } from "../../state/OnlineProvider";
import InlineEditableH1 from "../Input/InlineEditableH1";

interface LinkProps {
  label: string;
  location: string;
}

interface Props {
  title: string;
  previousPageLabel?: string;
  previousPageLocation?: string;
  isEditable?: boolean;
  onEdit?: (title: string) => void;
}

const Header: FunctionComponent<Props> = ({
  title,
  previousPageLabel,
  previousPageLocation,
  children,
  isEditable,
  onEdit
}) => {
  const { push } = useHistory();
  const { user } = useAuth();
  const { isOnline } = useOnlineProvider();

  return (
    <div className="wizardHeader block sm:flex">
      <div>
        {isEditable && checkUserRole("ADMINISTRATOR", user.role) ? (
          <InlineEditableH1
            placeholder=""
            value={title}
            onChange={t => {
              t && onEdit && onEdit(t);
              return;
            }}
            name="title"
          />
        ) : (
          <h1>{title}</h1>
        )}
        {!isOnline && <small className="text-red block">Offline Mode</small>}
        {previousPageLabel && previousPageLocation && (
          <button
            className="btn-tertiary text-darkGrey text-xxs font-normal"
            onClick={() => push(previousPageLocation)}
          >
            {"< " + previousPageLabel}
          </button>
        )}
      </div>

      {children}
    </div>
  );
};

export default Header;
