import React, { useEffect, useState, useRef, Fragment } from "react";
import Header from "../../components/Header";
import api from "../../api";
import NotificationListItem from "../../containers/NotificationsPage/NotificationListItem";
import Notification from "../../types/Notification";
import InfiniteScroll from "react-infinite-scroller";
import { PulseLoader } from "react-spinners";
import NotificationTypes from "../../helpers/NotificationTypes";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ListItem from "../../components/List/ListItem";
import CheckIsMobile from "../../helpers/checkIsMobile";
import classnames from "classnames";
import { useNotifications } from "../../state/NotificationProvider";

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const scrollRef = useRef(null);
  const weekAgoDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const monthAgoDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
  const olderDate = new Date(Date.now() - 31 * 24 * 60 * 60 * 1000);
  const history = useHistory();
  const { addToast } = useToasts();
  const { removeNotification, getCurrentNotifications } = useNotifications();

  useEffect(() => {
    api.getUserNotifications(0).then(({ data }) => {
      setNotifications([...data.payload]);
      setCurrentPage(data.currentPage);
      setMaxPage(data.maxPage);
    });
  }, []);

  useEffect(() => {
    getCurrentNotifications()
  }, [getCurrentNotifications]);

  const loadMore = (page: any) => {
    api
      .getUserNotifications(page)
      .then(({ data }) => {
        setNotifications([...notifications, ...data.payload]);
        setCurrentPage(data.currentPage);
        setMaxPage(data.maxPage);
      })
      .catch(error => console.log(error));
  };

  const markNotificationAsRead = (notificationId: number) => {
    api
      .markNotificationAsRead(notificationId)
      .then(() => {
        const updatedNotifications = [...notifications];
        const notificationToUpdate = updatedNotifications.findIndex(
          n => n.id === notificationId
        );
        updatedNotifications[notificationToUpdate] = {
          ...updatedNotifications[notificationToUpdate],
          isRead: true
        };
        setNotifications(updatedNotifications);
        removeNotification();
        addToast("Notification Marked as Read", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        addToast("Failed to Mark Notification as Read", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const closeNotification = (notificationId: number) => {
    api
      .deleteNotification(notificationId)
      .then(() => {
        setNotifications(notifications.filter(n => n.id !== notificationId));
        addToast("Notification Closed", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        addToast("Failed to Close Notification", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const getNotificationLink = async (type: string, entityId: number) => {
    if (type === "project") {
      return `/projects/${entityId}`;
    }
    if (type === "task") {
      return await api
        .getTaskByTaskId(entityId)
        .then(({ data }) => {
          return `/projects/${data.projectId}/tasks/${entityId}`;
        })
        .catch(() => {
          addToast("This task no longer exists", {
            appearance: "error",
            autoDismiss: true
          });
          return "/";
        });
    }
    if (type === "user") {
      return `/users/${entityId}`;
    }
    return "/notifications";
  };

  const handleClick = async (notification: Notification) => {
    if (!notification.isRead) {
      markNotificationAsRead(notification.id);
    }
    const link = getNotificationLink(
      NotificationTypes[notification.type],
      notification.entityId
    );

    history.push(await link);
  };

  const pastWeekNotifications = notifications
    .filter(n => new Date(n.createdDate) >= weekAgoDate)
    .map(notification => {
      return (
        <NotificationListItem
          notification={notification}
          onClick={handleClick}
          markNotificationAsRead={markNotificationAsRead}
          onClose={closeNotification}
        />
      );
    });

  const pastMonthNotifications = notifications
    .filter(
      n =>
        new Date(n.createdDate) >= monthAgoDate &&
        new Date(n.createdDate) < weekAgoDate
    )
    .map(notification => {
      return (
        <NotificationListItem
          notification={notification}
          onClick={handleClick}
          markNotificationAsRead={markNotificationAsRead}
          onClose={closeNotification}
        />
      );
    });

  const olderNotifications = notifications
    .filter(n => new Date(n.createdDate) < olderDate)
    .map(notification => {
      return (
        <NotificationListItem
          notification={notification}
          onClick={handleClick}
          markNotificationAsRead={markNotificationAsRead}
          onClose={closeNotification}
        />
      );
    });
  return (
    <div ref={scrollRef} className="h-screen overflow-auto  bg-white">
      <Header title="Notifications"></Header>
      <div className="px-8">
        <InfiniteScroll
          pageStart={1}
          threshold={250}
          hasMore={currentPage < maxPage}
          loadMore={loadMore}
          useWindow={false}
          className="mb-16"
          element="div"
          getScrollParent={() => scrollRef.current}
          loader={
            <div className="w-full flex justify-center items-center">
              <PulseLoader color="#00B2A9" />
            </div>
          }
        >
          {pastWeekNotifications.length > 0 && (
            <Fragment>
              <h4 className="mt-8">This Week</h4>
              <table className="w-full">
                <thead>
                  <tr className="w-full">
                    <th className=""></th>
                    <th className="w-auto"></th>
                    <th
                      className={classnames("w-1/3", {
                        hidden: CheckIsMobile()
                      })}
                    ></th>
                    <th className="w-1/6"></th>
                  </tr>
                </thead>
                <tbody>{pastWeekNotifications}</tbody>
              </table>
            </Fragment>
          )}
          {pastMonthNotifications.length > 0 && (
            <Fragment>
              <h4 className="mt-8">This Month</h4>
              <table>
                <thead>
                  <tr className="w-full">
                    <th className=""></th>
                    <th className="w-auto"></th>
                    <th
                      className={classnames("w-1/3", {
                        hidden: CheckIsMobile()
                      })}
                    ></th>
                    <th className="w-1/6"></th>
                  </tr>
                </thead>
                <tbody>{pastMonthNotifications}</tbody>
              </table>
            </Fragment>
          )}
          {olderNotifications.length > 0 && (
            <Fragment>
              <h4 className="mt-8">Older</h4>
              <table>
                <thead>
                  <tr className="w-full">
                    <th className=""></th>
                    <th className="w-auto"></th>
                    <th
                      className={classnames("w-1/3", {
                        hidden: CheckIsMobile()
                      })}
                    ></th>
                    <th className="w-1/6"></th>
                  </tr>
                </thead>
                <tbody>{olderNotifications}</tbody>
              </table>
            </Fragment>
          )}
          {pastWeekNotifications.length === 0 &&
            pastMonthNotifications.length === 0 &&
            olderNotifications.length === 0 && (
              <ListItem
                containerStyle="mt-8 bg-white hover:bg-lightGrey"
                label="No Notifications found"
              />
            )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default NotificationsPage;
