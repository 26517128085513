import React from "react";
import Modal from "react-responsive-modal";

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  entityLabel: string;
  warningLabel?: string;
}

const ConfirmDeleteModal = ({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  entityLabel,
  warningLabel = " This is a permanent action and cannot be reversed"
}: Props) => {
  return (
    <Modal
      center={true}
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
      showCloseIcon={false}
    >
      <h2>Are you sure you want to delete this {entityLabel}?</h2>
      <h4 className="font-normal">{warningLabel}</h4>
      <div className="flex flex-col-reverse sm:flex-row justify-between mt-8">
        <button
          className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className="btn-primary  w-full sm:w-56"
          onClick={() => onConfirm()}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
