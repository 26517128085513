import React, { FunctionComponent, useState } from "react";
import Modal from "react-responsive-modal";
import classnames from "classnames";
import TextInput from "../Input/TextInput";
import SelectInput from "../Input/SelectInput";
import { PulseLoader } from "react-spinners";
import Document from "../../types/Document";
import { Option } from "react-select/src/filters";

interface Props {
  templates: Document[];
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (name: string, template: Document) => void;
  isLoading: boolean;
}

const CreateCommercialModal: FunctionComponent<Props> = ({
  templates,
  isOpen,
  closeModal,
  onSubmit,
  isLoading
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Document>();
  const [documentName, setDocumentName] = useState<string>("");

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        setDocumentName("");
        setSelectedTemplate(undefined);
      }}
    >
      <h2>Create Commercial Document</h2>
      <div className="mt-4">
        <SelectInput
          label="Select commercial document template"
          placeholder="Select commercial document template"
          value={
            selectedTemplate
              ? {
                  value: selectedTemplate.id,
                  label: selectedTemplate.name,
                  data: selectedTemplate
                }
              : {}
          }
          options={templates.map(tt => {
            return {
              value: tt.id.toString(),
              label: tt.name,
              data: tt
            };
          })}
          onChange={e => {
            setSelectedTemplate((e as Option).data);
          }}
        />
      </div>
      <div className="my-4">
        <TextInput
          className="border-2"
          label="Enter name of document to create"
          placeholder="Enter document name"
          onChange={e => {
            setDocumentName(e.target.value);
          }}
          value={documentName}
          maxLength={256}
        />
      </div>
      <div className="flex flex-row justify-between pt-4">
        <button
          className="btn-secondary border-red text-red hover:border-red hover:text-red w-32"
          onClick={() => {
            setDocumentName("");
            setSelectedTemplate(undefined);
            closeModal();
          }}
        >
          Cancel
        </button>
        <button
          className={classnames("w-40", {
            "btn-primary": selectedTemplate && documentName && !isLoading,
            "btn-disabled": !selectedTemplate || !documentName || isLoading
          })}
          disabled={isLoading}
          onClick={() => {
            setDocumentName("");
            setSelectedTemplate(undefined);
            onSubmit &&
              selectedTemplate &&
              onSubmit(documentName, selectedTemplate);
          }}
        >
          {isLoading ? (
            <PulseLoader color="#D1D1D1" size="8" />
          ) : (
            <span>Create Document</span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default CreateCommercialModal;
