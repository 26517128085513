import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "axios-hooks";
import SideBackground from "../../assets/img/logo.svg";
import LoginLayout from "../../layouts/LoginLayout";
import AppleBridgeLogo from "../../assets/img/logoWithBlackText.svg";
import TextInput from "../../components/Input/TextInput";
import Button from "../../components/Button";
import api from "../../config/api";
import useQuery from "../../helpers/useQuery";
import Alert, { AlertType } from "../../components/Alert";
import { useForm } from "react-hook-form";

type RecoveryFormInputs = {
  username: string;
};

function PasswordRecoveryPage() {
  const query = useQuery();
  const { register, handleSubmit, errors } = useForm<RecoveryFormInputs>();
  const [successfull, setSuccess] = useState(false);
  const [username, setUsername] = useState(query.get("username") || "");
  const [{ loading, error }, submit] = useAxios(
    { url: api.authentication.recovery, method: "POST" },
    { manual: true }
  );

  const submitRecovery = () => {
    submit({
      data: {
        username
      }
    })
      .then(() => setSuccess(true))
      .catch(console.error);
  };

  return (
    <LoginLayout>
      <section className="flex flex-1 justify-center pt-8 md:pt-0 md:items-center">
        <form
          className="w-screen px-4 sm:px-0 sm:w-2/3 lg:w-2/5"
          onSubmit={handleSubmit(submitRecovery)}
        >
          <div className="my-16 flex justify-center md:justify-start">
            <img
              className="h-16"
              src={AppleBridgeLogo}
              alt="Applebridge logo"
            />
          </div>
          <div className="mb-4">
            {error && (
              <Alert
                label="An error has occured. Please try again."
                type={AlertType.Warning}
              />
            )}
            {successfull && (
              <Alert
                label="A password reset email has been sent."
                type={AlertType.Info}
              />
            )}
            <TextInput
              label="Email Address"
              placeholder="Enter your email address"
              value={username}
              type="email"
              onChange={e => setUsername(e.target.value)}
              name="username"
              inputRef={register({ required: true })}
              errorMessage={errors.username && "Email adddress is required"}
            />
          </div>
          <div className="flex justify-between w-full items-center ">
            <button
              className={`${
                loading ? "btn-disabled" : "btn-primary"
              } w-full md:w-56`}
            >
              Reset Password
            </button>
          </div>
          <div className="flex justify-center md:justify-start mt-10 md:mt-16">
            <Link to={`/login`}>Back to login</Link>
          </div>
        </form>
      </section>
      <section className="flex flex-1">
        <img
          className="h-full w-full object-cover"
          src={SideBackground}
          alt="Applebridge Background"
        />
      </section>
    </LoginLayout>
  );
}

export default PasswordRecoveryPage;
