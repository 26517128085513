import React from "react";
import TextAreaInput from "../../../components/Input/TextAreaInput";

interface Props {
  description: string;
  setDescription: (description: string) => void;
  descriptionError: string;
}

const ProjectDescriptionPage = ({
  description,
  setDescription,
  descriptionError
}: Props) => {
  return (
    <div>
      <TextAreaInput
        label="Enter a detailed description"
        errorMessage={descriptionError}
        className="h-40"
        onChange={({ target: { value } }) => setDescription(value)}
        value={description}
        maxLength={1024}
      />
    </div>
  );
};

export default ProjectDescriptionPage;
