import React, { FC, useState } from "react";
import Modal from "react-responsive-modal";
import Dropzone from "../../components/Dropzone";
import dropzoneAcceptedFileTypes from "../../helpers/dropzoneAcceptedFileTypes";
interface Props {
  setUpdatedProfileImage: (updatedProfileImage: File | undefined) => void;
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  updatedProfileImage: File | undefined;
}

const EditUserImageModal: FC<Props> = ({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  setUpdatedProfileImage,
  updatedProfileImage
}) => {
  const closeEditModal = () => {
    setUpdatedProfileImage(undefined);
    setIsModalOpen(false);
  };

  return (
    <Modal
      center={true}
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
      showCloseIcon={false}
      classNames={{ modal: "w-84" }}
    >
      <h2>Update Profile Image</h2>
      <Dropzone
        label="Drop a profile image here or click to open the explorer"
        onDrop={file => setUpdatedProfileImage(file)}
        acceptedFileName={updatedProfileImage?.name}
        acceptedFileTypes={dropzoneAcceptedFileTypes.filter(t =>
          t.includes("image")
        )}
      />
      <div className="flex flex-col-reverse  md:flex-row justify-between md:pt-4 ">
        <button
          className="btn-secondary border-red text-red hover:border-red hover:text-red w-full md:w-32 mt-4 md:mt-0 "
          onClick={() => {
            closeEditModal();
          }}
        >
          Cancel
        </button>
        <button className={" btn-primary w-full md:w-32"} onClick={onConfirm}>
          Update Image
        </button>
      </div>
    </Modal>
  );
};

export default EditUserImageModal;
