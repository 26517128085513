import endpoints, { axios } from "../../config/api";

async function updateNewFolder(
  folderId: string,
  name?: string,
  parentFolderId?: string
) {
  return await (
    await axios.put(endpoints.newfolders.byId(folderId), {
      name,
      parentFolderId
    })
  ).data;
}

export default updateNewFolder;
