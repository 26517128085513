import React, { useState, Fragment, ReactElement } from "react";
import classnames from "classnames";
import TabLink from "./TabLink";
import OfflineTab from "./OfflineTab";
import { useOnlineProvider } from "../../state/OnlineProvider";

interface Props {
  children: ReactElement[];
  fluid?: boolean;
  defaultTab?: number;
}

const Tabs = ({ children, fluid, defaultTab = 0 }: Props) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const { isOnline } = useOnlineProvider();

  return (
    <Fragment>
      <div
        className={classnames(
          "tabContainer -mx-8 px-4 sm:px-8 overflow-x-auto overflow-y-hidden"
        )}
      >
        {children.map(
          (el, i) =>
            el.props.label && (
              <TabLink
                label={el.props.label}
                key={i}
                isActive={activeTab === i}
                onClick={() => setActiveTab(i)}
                fluid={fluid}
              >
                {el.props.label}
              </TabLink>
            )
        )}
      </div>
      {!children[activeTab].props.allowedOffline && !isOnline ? (
        <OfflineTab />
      ) : (
        <div>{children[activeTab].props.children}</div>
      )}
    </Fragment>
  );
};
export default Tabs;
