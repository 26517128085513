import React, { FC, useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import api from "../../../api";
import TaskTemplate from "../../../types/TaskTemplate";
import ListItem from "../../../components/List/ListItem";

interface Props {
  isModalOpen: boolean;
  onSubmit: () => void;
  closeModal: () => void;
  dependanciesToAdd: TaskTemplate[];
  onCancel?: () => void;
}

const ProjectTemplateAddTaskDependencyConfirmModal: FC<Props> = ({
  closeModal,
  isModalOpen,
  onSubmit,
  dependanciesToAdd,
  onCancel
}) => {
  return (
    <Modal
      onClose={() => {
        closeModal();
      }}
      open={isModalOpen}
      center={true}
    >
      <h2>Dependant Tasks</h2>
      <p>The following tasks will also be added</p>
      <div className="mt-8">
        {dependanciesToAdd.map(dependancy => (
          <ListItem
            containerStyle="bg-white hover:bg-lightGrey "
            label={dependancy.name}
            key={dependancy.id}
          />
        ))}
      </div>
      <div className="flex flex-row justify-between pt-4">
        <button
          className="btn-secondary border-red text-red hover:border-red hover:text-red w-32 mr-4"
          onClick={() => {
            closeModal();
            onCancel && onCancel();
          }}
        >
          Cancel
        </button>
        <button
          className=" btn-primary w-32"
          onClick={() => {
            onSubmit();
            closeModal();
          }}
        >
          Add Tasks
        </button>
      </div>
    </Modal>
  );
};

export default ProjectTemplateAddTaskDependencyConfirmModal;
