import React, { useState, useEffect, FC, Fragment } from "react";

import { Option } from "react-select/src/filters";
import SelectInput from "../../components/Input/SelectInput";
import api from "../../api";
import useDebounce from "../../helpers/useDebounce";
import TaskTemplate, { TaskTemplateWithKey } from "../../types/TaskTemplate";
import ListItem from "../../components/List/ListItem";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import TaskTemplateAddDependencyModal from "./TaskTemplateAddDependencyModal";
import TaskTemplateCreateDependencyModal from "./TaskTemplateCreateDependencyModal";
import shortid from "shortid";

interface Props {
  selectedTemplateDependancies: TaskTemplateWithKey[];
  setSelectedDependancies: (templates: TaskTemplateWithKey[]) => void;
  taskDependencies: any[];
  setTaskDependencies: (dependencies: any[]) => void;
}

const TaskTemplateDependancySelectionPage: FC<Props> = ({
  selectedTemplateDependancies,
  setSelectedDependancies,
  setTaskDependencies,
  taskDependencies
}) => {
  const [isAddDependancyModalOpen, setIsAddDependancyModalOpen] = useState(
    false
  );
  return (
    <div>
      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        {selectedTemplateDependancies.length > 0 ? (
          selectedTemplateDependancies.map(dependancy => (
            <Fragment>
              <ListItem
                isClickable={false}
                label={dependancy.name}
                key={dependancy.id}
                middleIcon={<div></div>}
                popover={
                  <div
                    id="deleteIcon"
                    className="cursor-pointer invisible sm:visible"
                    onClick={() => {
                      setSelectedDependancies(
                        selectedTemplateDependancies.filter(
                          d => d !== dependancy
                        )
                      );
                    }}
                  />
                }
              />
              {taskDependencies.find(x => x.id === dependancy.id) &&
                taskDependencies
                  .find(x => x.id === dependancy.id)
                  .dependencies.map((dependency: any) => {
                    return (
                      <ListItem
                        containerStyle="bg-lightGrey hover:bg-white pl-4"
                        label={`> ${dependency.name}`}
                        middleIcon={<div></div>}
                        popover={<div></div>}
                      />
                    );
                  })}
            </Fragment>
          ))
        ) : (
          <ListItem
            containerStyle="bg-lightGrey hover:bg-white "
            label="No Dependencies found"
          />
        )}
      </div>

      <TaskTemplateCreateDependencyModal
        isModalOpen={isAddDependancyModalOpen}
        onSubmit={(selectedTemplate, d) => {
          setIsAddDependancyModalOpen(false);
          setSelectedDependancies([
            ...selectedTemplateDependancies,
            { ...selectedTemplate, key: shortid.generate() }
          ]);
          setTaskDependencies([
            ...taskDependencies,
            { id: selectedTemplate.id, dependencies: d }
          ]);
        }}
        closeModal={() => setIsAddDependancyModalOpen(false)}
        selectedTemplateDependancies={selectedTemplateDependancies}
      />
      {
        <div>
          <button
            className="btn-tertiary mt-8"
            onClick={() => {
              setIsAddDependancyModalOpen(true);
            }}
          >
            + Add a Dependency
          </button>
        </div>
      }
    </div>
  );
};

export default TaskTemplateDependancySelectionPage;
