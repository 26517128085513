import React, { useEffect, useState, useRef } from "react";

import { useToasts } from "react-toast-notifications";

import ProtectedComponent from "../../components/ProtectedComponent";
import { SubtaskTemplateToCreate } from "../../pages/Wizards/TaskTemplateWizard";
import ListItem from "../../components/List/ListItem";

interface Props {
  subtasksToCreate: SubtaskTemplateToCreate[];
  setSubtasksToCreate: (subtasks: SubtaskTemplateToCreate[]) => void;
}

const TaskTemplateSubtaskPage = ({
  subtasksToCreate,
  setSubtasksToCreate
}: Props) => {
  const [isCreatingSubtask, setIsCreatingSubtask] = useState(false);
  const [subtaskToCreateName, setSubtaskToCreateName] = useState("");
  const subtaskRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToasts();

  const deleteSubTask = (item: SubtaskTemplateToCreate) => {
    setSubtasksToCreate(subtasksToCreate.filter(t => t !== item));
  };

  const createSubtask = () => {
    if (subtaskToCreateName.length === 0) {
      setIsCreatingSubtask(false);
      setSubtaskToCreateName("");
      return;
    }
    const subtaskToCreate = {
      name: subtaskToCreateName,
      description: subtaskToCreateName
    };
    addToast("Successfully Created Subtask", {
      appearance: "success",
      autoDismiss: true
    });
    setSubtasksToCreate([...subtasksToCreate, subtaskToCreate]);
    setIsCreatingSubtask(false);
    setSubtaskToCreateName("");
  };

  useEffect(() => {
    if (isCreatingSubtask && subtaskRef.current) {
      subtaskRef.current && subtaskRef.current.focus();
    }
  }, [subtaskRef, isCreatingSubtask]);

  return (
    <div>
      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        {subtasksToCreate.length > 0
          ? subtasksToCreate.map(item => (
              <ListItem
                containerStyle="bg-lightGrey hover:bg-white"
                label={item.name}
                labelStyle="no-underline"
                middleIcon={<div></div>}
                popover={
                  <ProtectedComponent requiredRole="PROJECTMANAGER">
                    {
                      <div
                        id="deleteIcon"
                        className="cursor-pointer"
                        onClick={() => deleteSubTask(item)}
                      />
                    }
                  </ProtectedComponent>
                }
              />
            ))
          : !isCreatingSubtask && (
              <ListItem
                containerStyle="bg-lightGrey hover:bg-white "
                label="No Subtasks found"
              />
            )}
        <div
          className={`ListItem  px-0 sm:pr-8 h-full 
          ${isCreatingSubtask ? "flex" : "hidden"} 
            `}
        >
          <div className="sm:pl-2 pl-0 flex-1 justify-between items-center">
            <div className="pl-2">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createSubtask();
                }}
              >
                <input
                  className="w-full"
                  onBlur={() => createSubtask()}
                  placeholder="Subtask Name"
                  value={subtaskToCreateName}
                  onChange={({ target: { value } }) =>
                    setSubtaskToCreateName(value)
                  }
                  autoFocus={true}
                  maxLength={256}
                  ref={subtaskRef}
                />
              </form>
            </div>
          </div>
          <div className="flex justify-end h-full w-3/12 min-w-3/12">
            <div
              id="deleteIcon"
              className="cursor-pointer"
              onClick={() => {
                setIsCreatingSubtask(false);
                setSubtaskToCreateName("");
              }}
            />
          </div>
        </div>
      </div>

      <ProtectedComponent requiredRole="PROJECTMANAGER">
        {
          <div>
            <button
              className="btn-tertiary my-6"
              onClick={() => {
                setIsCreatingSubtask(true);
              }}
            >
              + Add a subtask
            </button>
          </div>
        }
      </ProtectedComponent>
    </div>
  );
};

export default TaskTemplateSubtaskPage;
