interface IIndexable {
  [key: string]: any;
}

const UserRoles: IIndexable = {
  STANDARDUSER: { name: "STANDARDUSER", weight: 0 },
  PROJECTMANAGER: { name: "PROJECTMANAGER", weight: 1 },
  ADMINISTRATOR: { name: "ADMINISTRATOR", weight: 2 }
};

export default UserRoles;
