import React, { useEffect, useState } from "react";
import ListItem from "../../../components/List/ListItem";
import User from "../../../types/User";
import useDebounce from "../../../helpers/useDebounce";
import endpoints, { axios } from "../../../config/api";
import SelectInput from "../../../components/Input/SelectInput";
import { Option } from "react-select/src/filters";
import { useTranslate } from "react-polyglot";

interface Props {
  siteManager: User | undefined;
  setSiteManager: (siteManager: User) => void;
  siteManagerError: string;
}

const ProjectAssignSiteManagerPage = ({
  setSiteManager,
  siteManager,
  siteManagerError
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const t = useTranslate();

  useEffect(() => {
    setLoading(true);
    setUsers([]);
    axios
      .get(endpoints.users.search(debouncedSearchTerm))
      .then(({ data }) => {
        setUsers([...data.payload]);
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [debouncedSearchTerm]);

  return (
    <div>
      <SelectInput
        className="mb-12"
        label="Assign a site manager"
        placeholder="Assign a site manager"
        errorMessage={siteManagerError}
        onSearchChange={e => {
          setSearchTerm(e);
        }}
        onChange={e => {
          setSiteManager((e as Option).data);
        }}
        options={users.map(u => {
          return {
            value: u.id,
            label: `${u.firstName + " " + u.lastName}`,
            data: u
          };
        })}
        value={
          siteManager
            ? {
                value: siteManager.id,
                label: `${siteManager.firstName + " " + siteManager.lastName}`,
                data: siteManager
              }
            : {}
        }
        searchValue={searchTerm}
        isLoading={loading}
      />

      {siteManager && (
        <ListItem
          containerStyle="mt-8"
          label={siteManager.firstName + " " + siteManager.lastName}
          meta={t(siteManager.role)}
          imageUrl={
            siteManager.profileImage
              ? siteManager.profileImage
              : `https://eu.ui-avatars.com/api/?name=${siteManager.firstName}+${siteManager.lastName}&size=64`
          }
        />
      )}
    </div>
  );
};
export default ProjectAssignSiteManagerPage;
