import database from "../database";
import endpoints, { axios } from "../config/api";
import Project from "../types/Project";
import { getOnlineStatus } from "../helpers/useOnlineStatus";

async function getProjectList(page: number = 0, searchTerm: string = "") {
  if (!getOnlineStatus()) {
    const projects = await database.find({
      selector: {
        _id: { $regex: "^projects:[0-9]+$" },
        name: { $regex: `^.*${searchTerm}.*$` }
      }
    });

    return {
      payload: projects.docs,
      maxPage: 1,
      currentPage: 1
    };
  }

  const response = await axios.get(endpoints.project.search(searchTerm, page));
  const formattedProjects = await Promise.all(
    response.data.payload.map(async (p: Project) => {
      return database
        .get(`project:${p.id}`)
        .then(() => ({ ...p, offline: true }))
        .catch(() => ({ ...p, offline: false }));
    })
  );

  return { ...response.data, payload: formattedProjects };
}

export default getProjectList;
