import userRoles from "./userRoles";

const checkUserRole = (requiredRole: string, userRole: string) => {
  const requiredWeight = userRoles[requiredRole]
    ? userRoles[requiredRole].weight
    : -1;
  const userWeight = userRoles[userRole] ? userRoles[userRole].weight : -1;

  if (
    requiredWeight === -1 ||
    userWeight === -1 ||
    userWeight < requiredWeight
  ) {
    return false;
  }
  if (userWeight >= requiredWeight) {
    return true;
  }
};

export default checkUserRole;
