import endpoints, { axios } from "../config/api";
import { UserToCreate } from "../containers/Team/CreateUserModal";

function createUser(user: UserToCreate) {
  const formData = new FormData();
  formData.append("firstName", user.firstName);
  formData.append("lastName", user.lastName);
  formData.append("role", user.role);
  if (user.profileImage) {
    formData.append("profileImage", user.profileImage);
  }
  if (user.phoneNumber) {
    formData.append("phoneNumber", user.phoneNumber);
  }
  formData.append("email", user.email);

  return axios.post(endpoints.users.create, formData);
}

export default createUser;
