import React from "react";
import logoWithName from "../../assets/img/logoWithName.svg";
import logo from "../../assets/img/logo.svg";
import mobileClose from "../../assets/img/mobileClose.svg";

interface Props {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

const SidebarHeader = ({ isSidebarOpen, setIsSidebarOpen }: Props) => (
  <div className="sidebarHeader">
    {isSidebarOpen && (
      <div className="innerMobileHeader">
        <img className="w-8" src={logo} alt="Applebridge Logo" />

        <img
          onClick={() => setIsSidebarOpen(false)}
          id="mobileClose"
          src={mobileClose}
          alt="X"
        />
      </div>
    )}

    <img
      id="sidebarLogo"
      src={logoWithName}
      className={isSidebarOpen ? "open" : "hidden"}
      alt="Applebridge Logo"
    />
    <img
      id="sidebarLogo"
      src={logo}
      className={`${isSidebarOpen ? "hidden" : ""}`}
      alt="Applebridge Logo"
    />
  </div>
);

export default SidebarHeader;
