import React, { FC, useState, Fragment, useEffect } from "react";
import { useMachine } from "@xstate/react";
import classnames from "classnames";
import Modal from "react-responsive-modal";
import {
  Redirect,
  RouteChildrenProps,
  useParams,
  useHistory
} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import Header from "../../components/Header";
import InlineEditableDateInput from "../../components/Input/InlineEditableDateInput";
import Tab from "../../components/Tab/Tab";
import Tabs from "../../components/Tab/Tabs";
import TaskDocumentTab from "../../containers/TaskOverview/TaskDocumentTab";
import TaskImageTab from "../../containers/TaskOverview/TaskImageTab";
import TaskSubtaskTab from "../../containers/TaskOverview/TaskSubtaskTab";
import TaskTeamTab from "../../containers/TaskOverview/TaskTeamTab";
import checkUserRole from "../../helpers/checkUserRole";
import formatDate from "../../helpers/formatDate";
import trimText from "../../helpers/trimText";
import { useAuth } from "../../state/AuthProvider";
import { useOnlineProvider } from "../../state/OnlineProvider";
import TaskPageStateMachine from "./stateMachine";
import TaskDependancyTab from "../../containers/TaskOverview/TaskDependancyTab";
import Task from "../../types/Task";
import InlineEditableTextAreaInput from "../../components/Input/InlineEditableTextAreaInput";

interface Match {
  taskId: string;
  projectId: string;
}

const TaskOverview = () => {
  const match = useParams<Match>();
  const { user } = useAuth();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [readingAllDescription, setReadingAllDescription] = useState(false);
  const [canComplete, setCanComplete] = useState(false);
  const projectId = match ? parseInt(match.projectId) : 0;
  const taskId = match ? parseInt(match.taskId) : 0;
  const [isAllSubtasksComplete, setIsAllSubtasksComplete] = useState(false);
  const { addToast } = useToasts();
  const { isOnline } = useOnlineProvider();
  const history = useHistory();
  const [machine, send] = useMachine(TaskPageStateMachine, {
    context: {
      projectId,
      taskId,
      userName: `${user.firstName} ${user.lastName}`
    },
    actions: {
      showSuccessNotification: () =>
        addToast("Task has been updated", {
          appearance: "success",
          autoDismiss: true
        }),
      showErrorNotification: () =>
        addToast("An error has occured when updating the task", {
          appearance: "error",
          autoDismiss: true
        }),
      showDeleteErrorNotification: () =>
        addToast("An error has occured when deleting the task", {
          appearance: "error",
          autoDismiss: true
        }),
      handleDeleteSuccess: () => {
        addToast("Task has been deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setIsDeleteModalOpen(false);
        history.push(`/projects/${projectId}`);
      }
    }
  });
  const { context } = machine;

  useEffect(() => {
    send("CHANGE_TASK", { taskId, projectId });
  }, [match.taskId, match.projectId]);

  const { task, project, dependancies, isUserAssignedToTask } = context;

  useEffect(() => {
    const incompleteDependancies = dependancies
      .map((d: Task) => d.isCompleted)
      .filter(d => !d).length;

    const dependanciesCheck =
      dependancies.length > 0
        ? incompleteDependancies > 0
          ? false
          : true
        : true;

    if (dependanciesCheck && isAllSubtasksComplete) {
      setCanComplete(true);

      return;
    }

    setCanComplete(false);
  }, [dependancies, isAllSubtasksComplete]);

  if (projectId === 0 || taskId === 0) {
    return <Redirect to="/projects" />;
  }

  if (machine.value === "load") {
    return <p>loading</p>;
  }

  return (
    <div className="h-screen overflow-auto bg-white">
      <Header
        title={task.name}
        previousPageLabel="Back to Project"
        previousPageLocation={`/projects/${projectId}`}
      />
      <div className="flex-1 bg-white">
        <div className="flex flex-col w-full  py-10 bg-lightGrey">
          <div className=" flex justify-between px-8 flex-col lg:flex-row  w-full ">
            <div className="flex flex-col mb-4 md:mb-0 md:w-64 md:mr-32 ">
              <div className="flex flex-col ">
                <h5 className="text-lightBlue">Start Date</h5>
                {task.isCompleted ||
                !checkUserRole("PROJECTMANAGER", user.role) ? (
                  <h4>{formatDate(task.startDate)}</h4>
                ) : (
                  <InlineEditableDateInput
                    placeholder=""
                    value={task.startDate}
                    onChange={e => send("SET_START_DATE", { startDate: e })}
                  />
                )}
              </div>
              <div className="flex flex-col mt-2">
                <h5 className="text-lightBlue">
                  {!task.isCompleted && "Expected "}Completion Date
                </h5>
                {task.isCompleted ? (
                  <h4>{formatDate(task.actualCompletionDate)}</h4>
                ) : (
                  <InlineEditableDateInput
                    placeholder=""
                    minDate={task.startDate}
                    value={task.expectedCompletionDate}
                    onChange={e =>
                      send("SET_COMPLETED_DATE", { completionDate: e })
                    }
                  />
                )}
              </div>
              <div className="flex flex-col mt-2">
                <h5 className="text-lightBlue">Project Type</h5>
                <h4>{project.type}</h4>
              </div>
              <div className="flex flex-col mt-2">
                <h5 className="text-lightBlue">Site Manager</h5>
                <div className="flex flex-row justify-between items-center">
                  <h4>
                    {project.siteManager.firstName +
                      " " +
                      project.siteManager.lastName}
                  </h4>
                </div>
              </div>
              {user &&
                checkUserRole("PROJECTMANAGER", user.role) &&
                !task.isCompleted && (
                  <div className="flex flex-row pt-4 md:pt-8">
                    <button
                      className={classnames(
                        "btn-tertiary flex flex-row  font-normal",
                        {
                          "text-red hover:text-red": isOnline,
                          "text-darkGrey hover:text-darkGrey cursor-not-allowed": !isOnline
                        }
                      )}
                      disabled={!isOnline}
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      <img
                        src={deleteHoverIcon}
                        className="pr-4 "
                        alt="Delete"
                      />
                      Delete Task
                    </button>
                    <Modal
                      center={true}
                      onClose={() => setIsDeleteModalOpen(false)}
                      open={isDeleteModalOpen}
                      showCloseIcon={false}
                    >
                      <h2>Are you sure you want to delete this Task?</h2>
                      <h4 className="font-normal">
                        This is a permanent action and cannot be reversed
                      </h4>
                      <div className="flex flex-col-reverse sm:flex-row  mt-8">
                        <button
                          className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
                          onClick={() => setIsDeleteModalOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn-primary  w-full sm:w-56"
                          onClick={() => send("DELETE")}
                        >
                          Delete
                        </button>
                      </div>
                    </Modal>
                  </div>
                )}
            </div>
            <div className="flex-1 flex flex-col  mr-0 sm:mr-16  mt-4 sm:mt-0">
              <h5 className="text-lightBlue">Task Description</h5>
              <div className="flex-1 mt-4">
                <InlineEditableTextAreaInput
                  value={task.description}
                  placeholder="Description"
                  onChange={value =>
                    send("SET_DESCRIPTION", { description: value })
                  }
                  readingAllDescription={readingAllDescription}
                />
                <p className="whitespace-pre-wrap break-words"></p>
                {task?.description.length > 80 && (
                  <button
                    className="btn-tertiary mt-4"
                    onClick={() =>
                      setReadingAllDescription(!readingAllDescription)
                    }
                  >
                    Read {readingAllDescription ? "Less" : "More"}...
                  </button>
                )}
              </div>
              <button
                onClick={() => send("COMPLETE")}
                disabled={
                  task.isCompleted ||
                  !isOnline ||
                  !(
                    canComplete &&
                    (checkUserRole("PROJECTMANAGER", user.role) ||
                      isUserAssignedToTask)
                  )
                }
                className={`btn-${
                  task.isCompleted || !isOnline
                    ? "disabled"
                    : canComplete &&
                      (checkUserRole("PROJECTMANAGER", user.role) ||
                        isUserAssignedToTask)
                    ? "secondary"
                    : "disabled"
                } w-full items-end mt-8 sm:mt-0`}
              >
                {task.isCompleted ? "Task Complete" : "Complete this task"}
              </button>
              {!task.isCompleted && !canComplete && isOnline && (
                <p className="font-bold text-xs mt-4 text-red">
                  This task cannot be completed until all{" "}
                  {!isAllSubtasksComplete && "subtasks"}{" "}
                  {!isAllSubtasksComplete &&
                    dependancies.map((d: Task) => d.isCompleted).filter(d => !d)
                      .length > 0 &&
                    "and"}{" "}
                  {dependancies.map((d: Task) => d.isCompleted).filter(d => !d)
                    .length > 0 && "dependencies"}{" "}
                  are completed
                </p>
              )}
              {!isOnline && (
                <p className="text-red font-bold text-xs mt-4">
                  This task cannot be completed whilst offline
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="flex-1 bg-white px-8 mb-20 md:mb-0">
          <Tabs fluid>
            <Tab label="Sub-tasks" allowedOffline={true}>
              <TaskSubtaskTab
                projectId={projectId}
                taskId={taskId}
                setIsAllSubtasksComplete={setIsAllSubtasksComplete}
                parentTask={task}
                canCompleteSubtasks={
                  checkUserRole("PROJECTMANAGER", user.role) ||
                  isUserAssignedToTask
                }
              />
            </Tab>
            <Tab label="Dependencies" allowedOffline={true}>
              <TaskDependancyTab
                taskId={taskId}
                projectId={projectId}
                dependancies={dependancies}
                setDependancies={d =>
                  send("SET_DEPENDANCIES", { dependancies: d })
                }
                isCompleted={task.isCompleted}
              />
            </Tab>
            <Tab label="Team" allowedOffline={true}>
              <TaskTeamTab
                projectId={projectId}
                taskId={taskId}
                isTaskComplete={false}
              />
            </Tab>
            <Tab label="Images" allowedOffline={true}>
              <TaskImageTab taskId={taskId} projectId={projectId} />
            </Tab>
            <Tab label="Documents" allowedOffline={true}>
              <TaskDocumentTab taskId={taskId} projectId={projectId} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TaskOverview;
