import endpoints, { axios } from "../config/api";

function updateProjectUser(
  projectId: number,
  updates: {
    userId: string;
    receiveCommericalDocNotifications: boolean;
  }[]
) {
  return axios.patch(endpoints.project.updateMember(projectId), updates);
}

export default updateProjectUser;
