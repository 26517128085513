interface IIndexable {
  [key: string]: any;
}

const ProjectTypeMap: IIndexable = {
  Plots: "Plots",
  Infrastructure: "Infrastructure",
  "Infrastructure and Plots": "InfrastructureAndPlots",
  Civils: "Civil",
  Utilities: "Utilities",
  Earthworks: "Earthworks"
};

export default ProjectTypeMap;
