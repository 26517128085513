import React, { useState, Fragment, useEffect } from "react";
import useAxios from "axios-hooks";
import closeIcon from "../../assets/img/mobileClose.svg";
import ProjectDetailPage from "./components/ProjectDetailPage";
import ProjectTemplateSelectionPage from "./components/ProjectTemplateSelectionPage";
import ProjectTypeSelectionPage from "../../components/Wizards/common/ProjectTypeSelectionPage";
import ProjectDescriptionPage from "./components/ProjectDescriptionPage";
import ProjectDocumentUploadPage from "./components/ProjectDocumentUploadPage";
import ProjectAssignSiteManagerPage from "./components/ProjectAssignSiteManagerPage";
import ProjectAssignTeamMembersPage from "./components/ProjectAssignTeamMembersPage";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../state/AuthProvider";
import User from "../../types/User";
import { DocumentToCreate } from "../../pages/Wizards/ProjectTemplateWizard/index";
import SelectFromTemplateOrCreate from "../../components/Wizards/common/SelectFromTemplateOrCreate";
import ProjectTemplate from "../../types/ProjectTemplate";
import api from "../../api";
import endpoints from "../../config/api";
import Document from "../../types/Document";
import TaskTemplate from "../../types/TaskTemplate";

const ProjectWizard = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [expectedCompletionDate, setExpectedCompletionDate] = useState(
    new Date()
  );
  const [type, setType] = useState(0);
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [
    expectedCompletionDateError,
    setExpectedCompletionDateError
  ] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [siteManager, setSiteManager] = useState<User>();
  const [siteManagerError, setSiteManagerError] = useState("");
  const [teamMembers, setTeamMembers] = useState<User[]>([]);
  const [teamMembersError, setTeamMembersError] = useState("");
  const [documentsToCreate, setDocumentsToCreate] = useState<
    DocumentToCreate[]
  >([]);
  const { user } = useAuth();
  const [selectedTemplate, setSelectedTemplate] = useState<ProjectTemplate>();
  const [existingDocuments, setExistingDocuments] = useState<Document[]>([]);
  const [tasksToCreate, setTasksToCreate] = useState<TaskTemplate[]>([]);

  const [{ loading, error }, createProjectRequest] = useAxios(
    { url: endpoints.project.create, method: "POST" },
    { manual: true }
  );

  const history = useHistory();

  useEffect(() => {
    if (selectedTemplate) {
      setName(selectedTemplate.name);
      setDescription(selectedTemplate.description);
      setType(selectedTemplate.type);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      api.newFolders
        .getRootFolderTemplateProject(selectedTemplate.id)
        .then(x => {
          api.newFolders.getFolderContents(x.id).then(data => {
            console.log(data.payload);
            setExistingDocuments(data.payload);
          });
        });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedTemplate) {
      api.getProjectTemplateTasks(selectedTemplate.id).then(({ data }) => {
        setTasksToCreate([...data]);
      });
    }
  }, [selectedTemplate]);

  const createProject = async () => {
    if (!name || name.trim() === "") {
      setNameError("Name is required");
      return;
    }

    if (!startDate || name.trim() === "") {
      setStartDateError("Start Date is required");
      return;
    }
    setStartDateError("");
    if (!expectedCompletionDate || name.trim() === "") {
      setExpectedCompletionDateError("Completion Date is required");
      return;
    }
    setExpectedCompletionDateError("");
    if (!description || description.trim() === "") {
      setDescriptionError("Description is required");
      return;
    }
    setDescriptionError("");
    if (!siteManager) {
      setSiteManagerError("Site Manager is required");
      return;
    }
    setSiteManagerError("");

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("type", type.toString());
    formData.append("startDate", startDate.toDateString());
    formData.append(
      "expectedCompletionDate",
      expectedCompletionDate.toDateString()
    );
    formData.append("siteManagerId", siteManager.id);

    existingDocuments.forEach(d => {
      formData.append("existingDocumentIds", d.id.toString());
      formData.append("existingDocumentTypes", d.fileType.toString());
    });

    documentsToCreate.forEach((d: any) => {
      formData.append("newDocuments", d.file);
      formData.append("newDocumentNames", d.name);
    });
    teamMembers.forEach((m: any) => {
      formData.append("teamMemberIds", m.id);
    });

    tasksToCreate.forEach(t => {
      formData.append("taskTemplateIds", t.id.toString());
    });

    createProjectRequest({
      data: formData
    })
      .then(res => {
        if (res.status === 200) {
          history.push(`/projects/${res.data.id}/tasks`);
          return;
        }
      })
      .catch(console.error);
  };

  const validatePage = () => {
    switch (currentPage) {
      case 2:
        if (name.trim().length === 0) {
          setNameError("Project Name is a required field");

          return;
        }
        setNameError("");
        setCurrentPage(currentPage + 1);
        return;
      case 4:
        if (description.trim() === "") {
          setDescriptionError("Description is required");
          return;
        }
        setDescriptionError("");
        setCurrentPage(currentPage + 1);
        return;
      case 6:
        if (!siteManager) {
          setSiteManagerError("Site Manager is required");
          return;
        }
        setSiteManagerError("");
        setCurrentPage(currentPage + 1);
        return;
      case 7:
        if (teamMembers.length === 0) {
          setTeamMembersError("One Team member is required");
          return;
        }
        setTeamMembersError("");
        createProject();
        return;
      default:
        setCurrentPage(currentPage + 1);
    }
  };

  const pageToRender = () => {
    switch (currentPage) {
      case 0:
        return (
          <SelectFromTemplateOrCreate
            onCreateNew={() => setCurrentPage(currentPage + 2)}
            onSelectTemplate={() => setCurrentPage(currentPage + 1)}
          />
        );
      case 1:
        return (
          <ProjectTemplateSelectionPage
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        );
      case 2:
        return (
          <ProjectDetailPage
            name={name}
            setName={setName}
            nameError={nameError}
            startDate={startDate}
            setStartDate={setStartDate}
            startDateError={startDateError}
            expectedCompletionDate={expectedCompletionDate}
            setExpectedCompletionDate={setExpectedCompletionDate}
            expectedCompletionDateError={expectedCompletionDateError}
          />
        );
      case 3:
        return <ProjectTypeSelectionPage type={type} setType={setType} />;
      case 4:
        return (
          <ProjectDescriptionPage
            descriptionError={descriptionError}
            description={description}
            setDescription={setDescription}
          />
        );
      case 5:
        return (
          <ProjectDocumentUploadPage
            documentsToCreate={documentsToCreate}
            setDocumentsToCreate={setDocumentsToCreate}
            existingDocuments={existingDocuments}
            setExistingDocuments={setExistingDocuments}
          />
        );
      case 6:
        return (
          <ProjectAssignSiteManagerPage
            siteManager={siteManager}
            setSiteManager={setSiteManager}
            siteManagerError={siteManagerError}
          />
        );
      case 7:
        return (
          <ProjectAssignTeamMembersPage
            teamMembers={teamMembers}
            teamMembersError={teamMembersError}
            setTeamMembers={setTeamMembers}
          />
        );
      default:
        return (
          <ProjectTemplateSelectionPage
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        );
    }
  };
  return (
    <div className="h-screen overflow-y-scroll ">
      <div className="wizard ">
        <div className="wizardHeader">
          <h1>{currentPage > 3 ? name : "Create A Project"}</h1>

          <Link to="/projects">
            <img id="wizardClose" src={closeIcon} alt="X" />
          </Link>
        </div>

        <div className="wizardContent">{pageToRender()}</div>
        <div className="wizardButtons">
          {currentPage > 1 ? (
            <button
              className="btn-secondary sm:w-56 w-full mt-4 sm:mt-0"
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Back
            </button>
          ) : (
            <Link to="/projects" className="mt-4 sm:mt-0">
              <button className="btn-secondary sm:w-56 w-full ">Cancel</button>
            </Link>
          )}
          {currentPage > 0 && currentPage < 7 ? (
            <button
              className="btn-primary w-full sm:w-56"
              onClick={() => validatePage()}
            >
              Next
            </button>
          ) : (
            currentPage === 7 && (
              <button
                className={`${
                  loading ? "btn-disabled" : "btn-primary"
                } w-full sm:w-56`}
                onClick={() => validatePage()}
              >
                Create Project
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectWizard;
