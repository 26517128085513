import React, { useEffect, useState, useRef } from "react";

import { useToasts } from "react-toast-notifications";
import ProtectedComponent from "../../../components/ProtectedComponent";
import { SubtaskToCreate } from "../../../pages/Wizards/TaskWizard";

interface Props {
  projectId: number;
  startDate: Date;
  expectedCompletionDate: Date;
  subtasksToCreate: SubtaskToCreate[];
  setSubtasksToCreate: (subtasks: SubtaskToCreate[]) => void;
}

const TaskSubtaskPage = ({
  projectId,
  startDate,
  expectedCompletionDate,
  subtasksToCreate,
  setSubtasksToCreate
}: Props) => {
  const [isCreatingSubtask, setIsCreatingSubtask] = useState(false);
  const [subtaskToCreateName, setSubtaskToCreateName] = useState("");
  const subtaskRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToasts();

  const deleteSubTask = (item: SubtaskToCreate) => {
    setSubtasksToCreate(subtasksToCreate.filter(t => t !== item));
  };

  const createSubtask = () => {
    if (subtaskToCreateName.length === 0) {
      setIsCreatingSubtask(false);
      setSubtaskToCreateName("");
      return;
    }
    const subtaskToCreate = {
      projectId,
      name: subtaskToCreateName,
      description: subtaskToCreateName,
      startDate,
      expectedCompletionDate
    };
    addToast("Successfully Created Subtask", {
      appearance: "success",
      autoDismiss: true
    });
    setSubtasksToCreate([...subtasksToCreate, subtaskToCreate]);
    setIsCreatingSubtask(false);
    setSubtaskToCreateName("");
  };

  useEffect(() => {
    if (isCreatingSubtask && subtaskRef.current) {
      subtaskRef.current && subtaskRef.current.focus();
    }
  }, [subtaskRef, isCreatingSubtask]);

  return (
    <div className="mt-8 block">
      <table className="border-collapse space-x-1 mb-8">
        <thead>
          <tr className="bg-lightGrey">
            <th className="w-auto md:w-64 pb-3" colSpan={2}>
              Sub-task Name
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {subtasksToCreate &&
            subtasksToCreate.length === 0 &&
            !isCreatingSubtask && (
              <tr className="text-left bg-lightGrey ">
                <td colSpan={4}>No Subtasks Found</td>
              </tr>
            )}
          {subtasksToCreate &&
            subtasksToCreate.map((item: SubtaskToCreate, i) => (
              <tr
                className="bg-lightGrey hover:bg-white my-2  "
                key={item.name + "." + i}
              >
                <td className={`pl-2 `}>{item.name}</td>
                <td className="flex justify-end">
                  <ProtectedComponent requiredRole="PROJECTMANAGER">
                    {
                      <div
                        id="deleteIcon"
                        className="cursor-pointer"
                        onClick={() => deleteSubTask(item)}
                      />
                    }
                  </ProtectedComponent>
                </td>
              </tr>
            ))}
          <tr
            className={`${
              isCreatingSubtask ? "table-row" : "hidden"
            }  hover:bg-white`}
          >
            <td className={`pl-2 text-grey`}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createSubtask();
                }}
              >
                <input
                  onBlur={() => createSubtask()}
                  placeholder="Subtask Name"
                  value={subtaskToCreateName}
                  onChange={({ target: { value } }) =>
                    setSubtaskToCreateName(value)
                  }
                  autoFocus={true}
                  maxLength={256}
                  ref={subtaskRef}
                />
              </form>
            </td>
            <td>
              <div className="flex justify-end">
                <div
                  id="deleteIcon"
                  className="cursor-pointer"
                  onClick={() => {
                    setIsCreatingSubtask(false);
                    setSubtaskToCreateName("");
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ProtectedComponent requiredRole="PROJECTMANAGER">
        {
          <div>
            <button
              className="btn-tertiary my-6"
              onClick={() => {
                setIsCreatingSubtask(true);
              }}
            >
              + Add a subtask
            </button>
          </div>
        }
      </ProtectedComponent>
    </div>
  );
};

export default TaskSubtaskPage;
