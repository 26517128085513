import React, { useState, FC, Fragment } from "react";
import ProtectedComponent from "../../../components/ProtectedComponent";
import ListItem from "../../../components/List/ListItem";
import ProjectTemplateAddTaskModal from "./ProjectTemplateAddTaskModal";
import TaskTemplate, { TaskTemplateWithKey } from "../../../types/TaskTemplate";
import { useToasts } from "react-toast-notifications";
import shortid from "shortid";

interface Props {
  tasksToCreate: TaskTemplateWithKey[];
  setTasksToCreate: (selectedTasks: TaskTemplateWithKey[]) => void;
  taskDependencies: any[];
  setTaskDependencies: (dependencies: any[]) => void;
}

const ProjectTemplateTaskPage: FC<Props> = ({
  setTasksToCreate,
  tasksToCreate,
  setTaskDependencies,
  taskDependencies
}) => {
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const { addToast } = useToasts();

  return (
    <div>
      <div className="flex flex-col ">
        <h5 className="text-lightBlue">Name</h5>
        <div>
          {tasksToCreate.length > 0 ? (
            tasksToCreate.map(task => (
              <Fragment>
                <ListItem
                  isClickable={false}
                  containerStyle="bg-lightGrey hover:bg-white "
                  label={task.name}
                  middleIcon={<div></div>}
                  popover={
                    <div
                      id="deleteIcon"
                      className="cursor-pointer invisible sm:visible"
                      onClick={() => {
                        setTasksToCreate(
                          tasksToCreate.filter(t => t.key !== task.key)
                        );
                      }}
                    />
                  }
                />
                {taskDependencies.find(x => x.id === task.id) &&
                  taskDependencies
                    .find(x => x.id === task.id)
                    .dependencies.map((dependency: any) => (
                      <ListItem
                        containerStyle="bg-lightGrey hover:bg-white pl-4"
                        label={`> ${dependency.name}`}
                        middleIcon={<div></div>}
                        popover={<div></div>}
                      />
                    ))}
              </Fragment>
            ))
          ) : (
            <ListItem
              containerStyle="bg-lightGrey hover:bg-white "
              label="No Tasks Found"
            />
          )}
        </div>
        <div>
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <ProjectTemplateAddTaskModal
              isModalOpen={isAddTaskModalOpen}
              closeModal={() => setIsAddTaskModalOpen(false)}
              onSubmit={(newTask, d) => {
                const newKey = shortid.generate();
                setTasksToCreate([
                  ...tasksToCreate,
                  { ...newTask, key: newKey }
                ]);
                setTaskDependencies([
                  ...taskDependencies,
                  { id: newKey, dependencies: d }
                ]);
                setIsAddTaskModalOpen(false);
                addToast(`Task Added Successfully`, {
                  appearance: "success",
                  autoDismiss: true
                });
              }}
            />
            <button
              className="btn-tertiary mt-8 "
              onClick={() => setIsAddTaskModalOpen(true)}
            >
              + Add a task
            </button>
          </ProtectedComponent>
        </div>
      </div>
    </div>
  );
};

export default ProjectTemplateTaskPage;
