import React, { useRef } from "react";

interface Props {
  className?: string;
  errorMessage?: string;
  inputRef?: any;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value: string;
  pattern?: string;
  maxLength?: number;
}

const TextInput = ({
  className,
  errorMessage,
  inputRef,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  pattern,
  maxLength
}: Props) => {
  const scrollRef: any = useRef(null);

  return (
    <fieldset className="w-full" ref={scrollRef}>
      <label className="text-sm" htmlFor={label}>
        {label}
      </label>

      <input
        maxLength={maxLength}
        id={`form-input-${label}`}
        className={`${className} ${errorMessage && "error"}`}
        type={type}
        placeholder={placeholder}
        defaultValue={value}
        onFocus={() => {
          if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView();
          }
        }}
        onChange={e => onChange && onChange(e)}
        onKeyPress={e => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
        ref={inputRef}
        name={name}
        pattern={pattern}
      />
      <div className="inputErrorMessage">{errorMessage}</div>
    </fieldset>
  );
};

export default TextInput;
