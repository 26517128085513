import { Machine, assign } from "xstate";
import history from "../../helpers/history";
import getProjectById from "../../api/getProjectById";
import deleteProject from "../../api/deleteProject";
import updateProject from "../../api/updateProject";
import endpoints, { axios } from "../../config/api";

interface ProjectOverviewContext {
  projectId: number;
  project: any;
}

const getProjectProgressRequest = (projectId: number) => {
  return axios.get(endpoints.project.get(projectId));
};

const ProjectOverviewMachine = Machine<ProjectOverviewContext>(
  {
    id: "ProjectOverviewMachine",
    initial: "loading",
    context: {
      projectId: 0,
      project: null
    },
    states: {
      idle: {
        on: {
          DELETE: {
            target: "delete"
          },
          GET_PROJECT_PROGRESS: {
            target: "getProjectProgress"
          },
          SET_START_DATE: {
            actions: assign({
              project: (context, event) => ({
                ...context.project,
                startDate: event.startDate
              })
            }),
            target: "update"
          },
          SET_EXPECTED_COMPLETED_DATE: {
            actions: assign({
              project: (context, event) => ({
                ...context.project,
                expectedCompletionDate: event.expectedCompletionDate
              })
            }),
            target: "update"
          },
          SET_PROJECT_TYPE: {
            actions: assign({
              project: (context, event) => ({
                ...context.project,
                type: event.projectType
              })
            }),
            target: "update"
          },
          SET_DESCRIPTION: {
            actions: assign({
              project: (context, event) => ({
                ...context.project,
                description: event.description
              })
            }),
            target: "update"
          },
          SET_NAME: {
            actions: assign({
              project: (context, event) => ({
                ...context.project,
                name: event.name
              })
            }),
            target: "update"
          },
          SET_SITE_MANAGER: {
            actions: assign({
              project: (context, event) => ({
                ...context.project,
                siteManagerId: event.siteManager.id,
                siteManager: event.siteManager
              })
            }),
            target: "update"
          }
        }
      },
      loading: {
        invoke: {
          id: "LoadProject",
          src: context => getProjectById(context.projectId),
          onDone: {
            target: "idle",
            actions: assign({
              project: (_, event) => {
                return {
                  ...event.data,
                  siteManagerId: event.data.siteManager.id
                };
              }
            })
          },
          onError: {
            actions: ["projectNotFoundNotification", "handleProjectLoadError"]
          }
        }
      },
      update: {
        invoke: {
          id: "UpdateProject",
          src: context => updateProject(context.project),
          onDone: {
            target: "idle",
            actions: ["successfullyUpdatedProjectNotification"]
          },
          onError: {
            target: "idle",
            actions: ["failedToUpdateProjectNotification"]
          }
        }
      },
      delete: {
        invoke: {
          id: "DeleteProject",
          src: context => deleteProject(context.projectId),
          onDone: {
            target: "idle",
            actions: [
              "successfullyDeletedProjectNotification",
              "redirectToDashboard"
            ]
          }
        }
      },
      getProjectProgress: {
        invoke: {
          id: "GetProjectProgress",
          src: context => getProjectProgressRequest(context.projectId),
          onDone: {
            target: "idle",
            actions: assign({
              project: (_, event) => {
                return event.data.data;
              }
            })
          }
        }
      }
    }
  },
  {
    actions: {
      handleProjectLoadError: (_, event) => {
        if (event.data.status === 404) {
          history.push("/projects");
        }
      },
      redirectToDashboard: () => {
        history.push("/projects");
      }
    }
  }
);

export default ProjectOverviewMachine;
