import React, { useState, useEffect, FC } from "react";
import ProjectTemplate from "../../../types/ProjectTemplate";
import api from "../../../api";
import useDebounce from "../../../helpers/useDebounce";
import SelectInput from "../../../components/Input/SelectInput";
import { Option } from "react-select/src/filters";

interface Props {
  selectedTemplate: ProjectTemplate | undefined;
  setSelectedTemplate: (template: ProjectTemplate) => void;
}

const ProjectTemplateSelectionPage: FC<Props> = ({
  selectedTemplate,
  setSelectedTemplate
}) => {
  const [templateProjects, setTemplateProjects] = useState<ProjectTemplate[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    api.getProjectTemplates(1, debouncedSearchTerm).then(({ data }) => {
      setTemplateProjects([...data.payload]);
    });
  }, [debouncedSearchTerm]);

  return (
    <div>
      <SelectInput
        label="Select a template project"
        placeholder="Select a template project"
        value={
          selectedTemplate
            ? {
                value: selectedTemplate.id,
                label: selectedTemplate.name,
                data: selectedTemplate
              }
            : {}
        }
        options={templateProjects.map(tt => {
          return {
            value: tt.id.toString(),
            label: tt.name,
            data: tt
          };
        })}
        onChange={e => {
          setSelectedTemplate((e as Option).data);
        }}
        onSearchChange={e => {
          setSearchTerm(e);
        }}
      />
    </div>
  );
};

export default ProjectTemplateSelectionPage;
