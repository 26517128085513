import React, { useState, FunctionComponent } from "react";
import EditIcon from "../../assets/img/editIcon.svg";
import EditIconHover from "../../assets/img/editIconHover.svg";
import { useOnlineProvider } from "../../state/OnlineProvider";
import trimText from "../../helpers/trimText";
import checkUserRole from "../../helpers/checkUserRole";
import { useAuth } from "../../state/AuthProvider";

interface Props {
  value: string;
  placeholder: string;
  onChange?: (input: string) => void;
  readingAllDescription?: boolean;
}

const InlineEditableTextAreaInput: FunctionComponent<Props> = ({
  onChange,
  value,
  placeholder,
  readingAllDescription = false
}) => {
  const { isOnline } = useOnlineProvider();
  const [icon, setIcon] = useState(EditIcon);
  const [isEditing, setEditing] = useState(false);
  const [input, setInput] = useState(value);
  const { user } = useAuth();

  const onEditingChange = () => {
    if (isEditing && onChange) {
      onChange(input);
    }

    setEditing(!isEditing);
  };

  return (
    <div className="flex flex-row justify-between items-center">
      {isEditing && checkUserRole("PROJECTMANAGER", user.role) ? (
        <textarea
          placeholder={placeholder}
          defaultValue={value}
          onChange={e => setInput(e.target.value)}
          className="mr-2"
        />
      ) : (
        <p className="mr-2 whitespace-pre-wrap break-words">
          {readingAllDescription ? trimText(input) : trimText(input)[0]}
        </p>
      )}
      {isOnline && checkUserRole("PROJECTMANAGER", user.role) && (
        <p onClick={e => onEditingChange()} className="InlineEditIconContainer">
          {isEditing ? (
            <svg className="ml-2" height="22px" width="22px" version="1.1">
              <g fill="#00B2A9">
                <path d="M6,10 L2.5,6.5 L0.5,8.5 L6,14 L15.5,4.5 L13.5,2.5 L6,10 Z"></path>
              </g>
            </svg>
          ) : (
            <img
              src={icon}
              alt="editable input icon"
              onMouseEnter={() => setIcon(EditIconHover)}
              onMouseLeave={() => setIcon(EditIcon)}
            />
          )}
        </p>
      )}
    </div>
  );
};

export default InlineEditableTextAreaInput;
