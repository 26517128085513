import React, { FC, useState, useEffect } from "react";
import TaskTemplate, { TaskTemplateWithKey } from "../../../types/TaskTemplate";
import Modal from "react-responsive-modal";
import TextInput from "../../../components/Input/TextInput";
import useDebounce from "../../../helpers/useDebounce";
import endpoints, { axios } from "../../../config/api";
import { PulseLoader } from "react-spinners";
import classnames from "classnames";
import api from "../../../api";
import ProjectTemplateAddTaskDependencyConfirmModal from "./ProjectTemplateAddTaskDependencyConfirmModal";
import shortid from "shortid";

interface Props {
  isModalOpen: boolean;
  onSubmit: (
    selectedTemplate: TaskTemplateWithKey,
    dependenciesToDisplay: TaskTemplate[]
  ) => void;
  closeModal: () => void;
}

const ProjectTemplateAddTaskModal: FC<Props> = ({
  closeModal,
  isModalOpen,
  onSubmit
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<
    TaskTemplate
  >();
  const [dependenciesToAdd, setDependenciesToAdd] = useState<TaskTemplate[]>(
    []
  );
  const [isDependencyModalOpen, setIsDependancyModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTaskTemplates([]);
    axios
      .get(endpoints.taskTemplates.getPage(1, debouncedSearchTerm))
      .then(({ data }) => {
        setTaskTemplates([...data.payload]);
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [debouncedSearchTerm]);

  const checkForDependencies = (taskTemplate: TaskTemplateWithKey) => {
    api.getRecursiveTaskTemplateDependancies(taskTemplate.id).then(data => {
      if (data.length === 0) {
        onSubmit({ ...taskTemplate, key: shortid.generate() }, []);
        return;
      }
      setSelectedTaskTemplate(taskTemplate);
      setDependenciesToAdd(data);
      setIsDependancyModalOpen(true);
    });
  };

  return (
    <Modal
      onClose={() => {
        closeModal();
      }}
      open={isModalOpen}
    >
      <h2>Add a Task</h2>
      <div>
        <TextInput
          className="border-2"
          label="Search for a Task to add"
          placeholder="Enter a task name"
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          maxLength={256}
        />
        <div>
          {loading ? (
            <div className="flex justify-center">
              <PulseLoader color="#00B2A9" />
            </div>
          ) : (
            taskTemplates.slice(0, 10).map(tt => (
              <div
                className={`flex flex-row py-1 px-2 cursor-pointer ${
                  selectedTaskTemplate === tt ? "bg-lightGrey" : ""
                }`}
                onClick={() => setSelectedTaskTemplate(tt)}
              >
                <p>{tt.name}</p>
              </div>
            ))
          )}
        </div>
        <div className="pt-4">
          <p>
            Selected Task: {selectedTaskTemplate && selectedTaskTemplate.name}
          </p>
        </div>
        <div className="flex flex-row justify-between pt-4">
          <button
            className="btn-secondary border-red text-red hover:border-red hover:text-red w-32"
            onClick={() => {
              setSearchTerm("");
              setSelectedTaskTemplate(undefined);
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            className={classnames("w-32", {
              "btn-primary": selectedTaskTemplate,
              "btn-disabled": !selectedTaskTemplate
            })}
            onClick={() => {
              if (onSubmit && selectedTaskTemplate) {
                checkForDependencies({
                  ...selectedTaskTemplate,
                  key: shortid.generate()
                });
                setSelectedTaskTemplate(undefined);
              }
            }}
          >
            Add Task
          </button>
        </div>
        <ProjectTemplateAddTaskDependencyConfirmModal
          onSubmit={() => {
            selectedTaskTemplate &&
              onSubmit(
                { ...selectedTaskTemplate, key: shortid.generate() },
                dependenciesToAdd
              );
            setIsDependancyModalOpen(false);
            setDependenciesToAdd([]);
          }}
          isModalOpen={isDependencyModalOpen}
          closeModal={() => setIsDependancyModalOpen(false)}
          dependanciesToAdd={dependenciesToAdd}
        />
      </div>
    </Modal>
  );
};

export default ProjectTemplateAddTaskModal;
