import React, { useState, useEffect, FC } from "react";
import TextInput from "../../../components/Input/TextInput";
import TaskTemplate from "../../../types/TaskTemplate";
import api from "../../../api";
import useDebounce from "../../../helpers/useDebounce";
import SelectInput from "../../../components/Input/SelectInput";
import { Option } from "react-select/src/filters";
import ProjectTemplateAddTaskDependencyConfirmModal from "../../ProjectTemplateWizard/components/ProjectTemplateAddTaskDependencyConfirmModal";

interface Props {
  selectedTemplate: TaskTemplate | undefined;
  setSelectedTemplate: (template: TaskTemplate) => void;
}

const TaskTemplateSelectionPage: FC<Props> = ({
  selectedTemplate,
  setSelectedTemplate
}) => {
  const [templateTasks, setTemplateTasks] = useState<TaskTemplate[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [dependenciesToAdd, setDependenciesToAdd] = useState<TaskTemplate[]>(
    []
  );
  const [isDependencyModalOpen, setIsDependancyModalOpen] = useState(false);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState<
    TaskTemplate | undefined
  >(selectedTemplate);
  useEffect(() => {
    api.getTaskTemplates(1, debouncedSearchTerm).then(({ data }) => {
      setTemplateTasks([...data.payload]);
    });
  }, [debouncedSearchTerm]);

  const checkForDependencies = (taskTemplate: TaskTemplate) => {
    api.getRecursiveTaskTemplateDependancies(taskTemplate.id).then(data => {
      if (data.length === 0) {
        setSelectedTemplate(taskTemplate);
        return;
      }

      setDependenciesToAdd(data);
      setIsDependancyModalOpen(true);
    });
  };

  return (
    <div>
      <SelectInput
        label="Select a template task"
        placeholder="Select a template task"
        value={
          selectedTaskTemplate
            ? {
                value: selectedTaskTemplate.id,
                label: selectedTaskTemplate.name,
                data: selectedTaskTemplate
              }
            : {}
        }
        options={templateTasks.map(tt => {
          return {
            value: tt.id.toString(),
            label: tt.name,
            data: tt
          };
        })}
        onChange={e => {
          setSelectedTaskTemplate((e as Option).data);
          checkForDependencies((e as Option).data);
        }}
        onSearchChange={e => {
          setSearchTerm(e);
        }}
      />
      <ProjectTemplateAddTaskDependencyConfirmModal
        onSubmit={() => {
          selectedTaskTemplate && setSelectedTemplate(selectedTaskTemplate);
          setIsDependancyModalOpen(false);
        }}
        onCancel={() => setSelectedTaskTemplate(undefined)}
        isModalOpen={isDependencyModalOpen}
        closeModal={() => setIsDependancyModalOpen(false)}
        dependanciesToAdd={dependenciesToAdd}
      />
    </div>
  );
};

export default TaskTemplateSelectionPage;
