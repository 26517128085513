import database from "../database";
import endpoints, { axios } from "../config/api";
import Project from "../types/Project";
import { getOnlineStatus } from "../helpers/useOnlineStatus";

async function getProjectById(projectId: number): Promise<Project | any> {
  if (!getOnlineStatus()) {
    const project = await database.get(`projects:${projectId}`);

    return project;
  }

  const response = await axios.get(endpoints.project.get(projectId));
  return response.data;
}

export default getProjectById;
