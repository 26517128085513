import React from "react";

interface Props {
  className?: string;
  label: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  checked?: boolean;
  pattern?: string;
}

const CheckboxInput = ({
  className,
  label,
  name,
  onChange,
  placeholder,
  value,
  checked
}: Props) => {
  return (
    <div className="w-full flex flex-row ">
      <input
        id={`form-input-${label}`}
        className={`${className} checkbox appearance-none border-lightBlue border-2 cursor-pointer`}
        type="checkbox"
        placeholder={placeholder}
        defaultValue={value}
        onChange={e => onChange && onChange(e)}
        name={name}
        checked={checked}
      />
      <label className="text-sm pl-3" htmlFor={label}>
        {label}
      </label>
    </div>
  );
};

export default CheckboxInput;
