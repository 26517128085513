import Project from "../types/Project";
import endpoints, { axios } from "../config/api";
import database from "../database";
import { getOnlineStatus } from "../helpers/useOnlineStatus";

async function updateProject(project: Project) {
  try {
    if (!getOnlineStatus()) {
      const storedProject = await database.get(`projects:${project.id}`);
      const updatedProject = {
        ...storedProject,
        ...project
      };

      await database.put(updatedProject, { force: true });
      return updatedProject;
    }

    return axios.put(endpoints.project.update(project.id), project);
  } catch (error) {
    console.error(error);
  }
}

export default updateProject;
