import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
  FC
} from "react";
import { Link, RouteChildrenProps } from "react-router-dom";
import { useAuth } from "../../state/AuthProvider";
import Header from "../../components/Header";
import ProtectedComponent from "../../components/ProtectedComponent";
import Project from "../../types/Project";
import Task from "../../types/Task";
import Tabs from "../../components/Tab/Tabs";
import Tab from "../../components/Tab/Tab";
import { PulseLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroller";
import useDebounce from "../../helpers/useDebounce";
import ProjectDashboardProjectItem from "../../containers/ProjectDashboard/ProjectDashboardProjectItem";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import { useToasts } from "react-toast-notifications";
import api from "../../api";
import ProjectDashboardTaskItem from "../../containers/ProjectDashboard/ProjectDashboardTaskItem";
import Button from "../../components/Button";
import shortid from "shortid";
import { useOnlineProvider } from "../../state/OnlineProvider";
import { getOnlineStatus } from "../../helpers/useOnlineStatus";

interface DashboardResponse {
  projectCount: number;
  taskCount: number;
  flagCount: number;
  projects: ProjectListResponse;
  tasks: TaskListResponse;
}
interface ProjectListResponse {
  payload: Project[];
  pageSize: number;
  currentPage: number;
  maxPage: number;
}
interface TaskListResponse {
  payload: Task[];
  pageSize: number;
  currentPage: number;
  maxPage: number;
}
interface Match {
  id: string;
  tab: string;
}
const ProjectDashboard: FC<RouteChildrenProps<Match>> = ({ match }) => {
  const { user, logout } = useAuth();
  const scrollRef = useRef(null);
  const { addToast } = useToasts();

  const { isOnline } = useOnlineProvider();
  const [projects, setProjects] = useState<Project[]>([]);
  const [maxProjectPage, setMaxProjectPage] = useState(1);
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [projectCount, setProjectCount] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [projectSearchTerm, setProjectSearchTerm] = useState("");
  const debouncedProjectSearchTerm = useDebounce(projectSearchTerm, 300);
  const [projectToDelete, setProjectToDelete] = useState<number>(0);
  const [taskToDelete, setTaskToDelete] = useState<Task>();
  const [isTaskDeleteModalOpen, setIsTaskDeleteModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [maxTaskPage, setMaxTaskPage] = useState(0);
  const [currentTaskPage, setCurrentTaskPage] = useState(0);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [taskSearchTerm, setTaskSearchTerm] = useState("");
  const debouncedTaskSearchTerm = useDebounce(taskSearchTerm, 300);
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(false);
  const currentTab = match && match.params ? match.params.tab : "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const data = async () => {
      setDashboardLoading(true);
        const dashboard = await api.getDashboard();

        if (dashboard === undefined) {
            logout();
        }

      const projects = dashboard?.projects;
      setProjects(projects ? projects.payload : []);
      setCurrentProjectPage(projects ? projects.currentPage : 0);
      setMaxProjectPage(projects ? projects.maxPage : 0);
      setDashboardLoading(false);
      setProjectCount(dashboard?.projectCount);
      setTaskCount(dashboard?.taskCount);
    };
    data();
  }, [isOnline]);

  useEffect(() => {
    const data = async () => {
      setIsProjectsLoading(true);
      setIsTaskLoading(true);
      const pResponse = await api.getProjectList(1, debouncedProjectSearchTerm);
      setProjects(pResponse.payload);
      setCurrentProjectPage(pResponse.currentPage);
      setMaxProjectPage(pResponse.maxPage);
      setIsProjectsLoading(false);
    };
    data();
  }, [debouncedProjectSearchTerm, debouncedTaskSearchTerm, isOnline]);

  useEffect(() => {
    const data = async () => {
      setIsTaskLoading(true);
      const taskResponse = await api.getUserTaskList(debouncedTaskSearchTerm);
      setTasks(taskResponse.payload);
      setCurrentTaskPage(taskResponse.currentPage);
      setMaxTaskPage(taskResponse.maxPage);
      setIsTaskLoading(false);
    };
    isOnline && data();
  }, [debouncedProjectSearchTerm, debouncedTaskSearchTerm, isOnline]);

  const loadMoreProjects = (page: any) => {
    api
      .getProjectList(page)
      .then(data => {
        setProjects([...projects, ...data.payload]);
        setCurrentProjectPage(data.currentPage);
        setMaxProjectPage(data.maxPage);
      })
      .catch(error => console.log(error));
  };

  const loadMoreTasks = (page: any) => {
    api
      .getUserTaskList(debouncedTaskSearchTerm, page)
      .then(data => {
        setTasks([...tasks, ...data.payload]);
        setCurrentTaskPage(data.currentPage);
        setMaxTaskPage(data.maxPage);
      })
      .catch(error => console.log(error));
  };

  const deleteProject = useCallback(
    (projectId: number) => {
      api
        .deleteProject(projectId)
        .then(() => {
          addToast("Project Deleted Successfully", {
            appearance: "success",
            autoDismiss: true
          });
          setProjects(projects.filter(p => p.id !== projectToDelete));
          setTasks(tasks.filter(t => t.projectId !== projectId));
          setProjectToDelete(0);
          setIsDeleteModalOpen(false);
        })
        .catch(() => {
          addToast("Failed to delete Project. Try again.", {
            appearance: "error",
            autoDismiss: true
          });
        });
    },
    [addToast, projectToDelete, projects]
  );

  const deleteTask = (taskToDelete: Task) => {
    api
      .deleteTask(taskToDelete.projectId, taskToDelete.id)
      .then(() => {
        addToast("Task has been deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setTasks(tasks.filter(t => t.id !== taskToDelete.id));
        setTaskToDelete(undefined);
        setIsTaskDeleteModalOpen(false);
      })
      .catch(() => {
        addToast("Task deletion failed", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const incompleteTasks = tasks
    .filter(t => !t.isCompleted)
    .map(item => (
      <ProjectDashboardTaskItem
        key={item.id}
        item={item}
        setIsTaskDeleteModalOpen={setIsTaskDeleteModalOpen}
        setTaskToDelete={setTaskToDelete}
      />
    ));

  const completeTasks = tasks
    .filter(t => t.isCompleted)
    .map(item => (
      <ProjectDashboardTaskItem
        key={item.id}
        item={item}
        setIsTaskDeleteModalOpen={setIsTaskDeleteModalOpen}
        setTaskToDelete={setTaskToDelete}
      />
    ));

  return (
    <div ref={scrollRef} className="h-screen overflow-auto bg-white ">
      {isOnline && (
        <div className=" sm:hidden absolute bottom-2 right-1 z-10">
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <Link to="/projects/create">
              <button className="btn-circle">+</button>
            </Link>
          </ProtectedComponent>
        </div>
      )}
      <div className="hidden sm:block">
        <Header title={`Welcome, ${user?.firstName}`}>
          <ProtectedComponent requiredRole="PROJECTMANAGER">
            <Link to="/projects/create">
              <Button
                allowOffline={false}
                className="btn-primary"
                label="+ Create a new project"
              />
            </Link>
          </ProtectedComponent>
        </Header>
      </div>
      <div className=" bg-white flex-1">
        <div className="flex flex-col w-full  py-10 bg-lightGrey">
          <div className=" flex justify-between px-8 flex-col lg:flex-row  w-full lg:w-10/12">
            <h2 className="lg:self-center">Your Project Breakdown</h2>
            <div className="flex flex-row flex-1  pt-6 lg:justify-evenly">
              <div className="w-auto mr-12 lg:mr-0">
                <h1
                  className="text-lightBlue pb-3"
                  style={{ fontSize: "3.75rem" }}
                >
                  {projectCount}
                </h1>
                <h3>Open Projects</h3>
              </div>
              <div className="w-auto ">
                {getOnlineStatus() && (
                  <Fragment>
                    <h1
                      className="text-lightBlue pb-3"
                      style={{ fontSize: "3.75rem" }}
                    >
                      {taskCount}
                    </h1>
                    <h3>Open Tasks</h3>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-8">
          <Tabs>
            <Tab label="Projects" allowedOffline={true}>
              <div>
                <div className="flex flex-col md:flex-row md:justify-end md:items-center">
                  <p className="mt-4 md:my-0 md:mr-4">Look up a project...</p>

                  <input
                    className="w-full md:w-56"
                    placeholder="Type to search..."
                    value={projectSearchTerm}
                    onChange={({ target: { value } }) =>
                      setProjectSearchTerm(value)
                    }
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        e.currentTarget.blur();
                      }
                    }}
                  />
                </div>
                <div className="mt-8 block">
                  {dashboardLoading && (
                    <table className=" w-full ">
                      <thead>
                        <tr className="w-full">
                          <th className="w-full sm:w-1/6">Project Name</th>
                          <th className="hidden sm:table-cell sm:w-1/6">
                            Start Date
                          </th>
                          <th className="hidden sm:table-cell sm:w-1/6">
                            Completion Date
                          </th>
                          <th className="sm:w-1/2"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={3}>
                            <div className="w-full flex items-center justify-center">
                              <PulseLoader color="#00B2A9" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {projects && !isProjectsLoading && projects.length === 0 ? (
                    <h1>
                      {projectSearchTerm.trim().length === 0
                        ? "There are currently no projects available"
                        : "No projects match this search term"}
                    </h1>
                  ) : (
                    !dashboardLoading && (
                      <InfiniteScroll
                        pageStart={1}
                        threshold={250}
                        hasMore={currentProjectPage < maxProjectPage}
                        loadMore={loadMoreProjects}
                        useWindow={false}
                        className="mb-16"
                        element="div"
                        getScrollParent={() => scrollRef.current}
                        loader={
                          <div className="w-full flex justify-center items-center">
                            <PulseLoader color="#00B2A9" />
                          </div>
                        }
                      >
                        <table className=" w-full ">
                          <thead>
                            <tr className="w-full">
                              <th className="w-1/2 sm:w-1/6">Project Name</th>
                              <th className="hidden sm:table-cell sm:w-1/6">
                                Start Date
                              </th>
                              <th className="hidden sm:table-cell sm:w-1/6">
                                Completion Date
                              </th>
                              <th className="sm:w-1/2"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {projects &&
                              !isProjectsLoading &&
                              projects.length === 0 && (
                                <tr className="text-center">
                                  <td colSpan={4}>No Projects Found</td>
                                </tr>
                              )}
                            {projects &&
                              projects.map(item => (
                                <ProjectDashboardProjectItem
                                  key={shortid.generate()}
                                  item={item}
                                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                                  setProjectToDelete={setProjectToDelete}
                                />
                              ))}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    )
                  )}
                </div>
                <ProtectedComponent requiredRole="PROJECTMANAGER">
                  <ConfirmDeleteModal
                    onConfirm={() => deleteProject(projectToDelete)}
                    entityLabel="Project"
                    setIsModalOpen={setIsDeleteModalOpen}
                    isModalOpen={isDeleteModalOpen}
                  />
                </ProtectedComponent>
              </div>
            </Tab>
            <Tab label="Tasks">
              <div>
                <div className="flex flex-col md:flex-row md:justify-end md:items-center">
                  <p className="mt-4 md:my-0 md:mr-4">Look up a task...</p>
                  <input
                    className="w-full md:w-56"
                    placeholder="Type to search..."
                    value={taskSearchTerm}
                    onChange={({ target: { value } }) =>
                      setTaskSearchTerm(value)
                    }
                    onKeyPress={e => {
                      if (e.key === "Enter") {
                        e.currentTarget.blur();
                      }
                    }}
                  />
                </div>
                <div className="mt-8 block">
                  {dashboardLoading && (
                    <table className="w-full sm:w-2/3">
                      <thead>
                        <tr className="w-full">
                          <th className="w-full sm:w-auto">Task Name</th>
                          <th className="invisible sm:visible">Start Date</th>
                          <th className="invisible sm:visible">
                            Completion Date
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={3}>
                            <div className="w-full flex items-center justify-center">
                              <PulseLoader color="#00B2A9" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {tasks && !isTaskLoading && tasks.length === 0 ? (
                    <h1>
                      {taskSearchTerm.trim().length === 0
                        ? "There are currently no tasks available"
                        : "No tasks match this search term"}
                    </h1>
                  ) : (
                    !dashboardLoading && (
                      <InfiniteScroll
                        pageStart={1}
                        threshold={250}
                        hasMore={currentTaskPage < maxTaskPage}
                        loadMore={loadMoreTasks}
                        useWindow={false}
                        className="mb-16"
                        element="div"
                        getScrollParent={() => scrollRef.current}
                        loader={
                          <div className="w-full flex justify-center items-center">
                            <PulseLoader color="#00B2A9" />
                          </div>
                        }
                      >
                        <table className=" w-full ">
                          <thead>
                            <tr className="w-full">
                              <th className="w-1/2 sm:w-1/6">Task Name</th>
                              <th className="hidden sm:table-cell sm:w-1/6">
                                Start Date
                              </th>
                              <th className="hidden sm:table-cell sm:w-1/6">
                                Completion Date
                              </th>
                              <th className="sm:w-1/2"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {incompleteTasks}
                            {completeTasks.length > 0 && (
                              <tr>
                                <td>
                                  <p className="font-roboto font-black text-sm text-blue">
                                    Completed Tasks
                                  </p>
                                </td>
                              </tr>
                            )}
                            {completeTasks}
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    )
                  )}
                </div>
                <ProtectedComponent requiredRole="PROJECTMANAGER">
                  <ConfirmDeleteModal
                    onConfirm={() => taskToDelete && deleteTask(taskToDelete)}
                    entityLabel="Task"
                    setIsModalOpen={setIsTaskDeleteModalOpen}
                    isModalOpen={isTaskDeleteModalOpen}
                  />
                </ProtectedComponent>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProjectDashboard;
