import { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";

export function getOnlineStatus() {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
}

function useOnlineStatus() {
  const { addToast } = useToasts();
  const [isOnline, setOnlineStatus] = useState(getOnlineStatus());

  const goOnline = () => {
    addToast("Online", {
      appearance: "info",
      autoDismiss: true
    });
    setOnlineStatus(true);
  };

  const goOffline = () => {
    addToast("Offline", {
      appearance: "info",
      autoDismiss: true
    });
    setOnlineStatus(false);
  };

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return { isOnline, goOnline, goOffline };
}

export default useOnlineStatus;
