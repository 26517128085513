import React, { useCallback, useState, FC, useEffect, Fragment } from "react";
import { useMachine } from "@xstate/react";
import ProjectTemplateWizardStateMachine from "./stateMachine";
import { useHistory, RouteChildrenProps } from "react-router-dom";
import ProjectTemplateDetailPage from "../../../containers/ProjectTemplateWizard/components/ProjectTemplateDetailPage";
import Document from "../../../types/Document";
import ProjectTypeSelectionPage from "../../../components/Wizards/common/ProjectTypeSelectionPage";
import ProjectDocumentUploadPage from "../../../containers/ProjectWizard/components/ProjectDocumentUploadPage";
import ProjectTemplateTaskPage from "../../../containers/ProjectTemplateWizard/components/ProjectTemplateTaskPage";
import { useToasts } from "react-toast-notifications";
import endpoints, { axios } from "../../../config/api";
import TemplateProjectEditDocumentsTab from "../../../containers/TemplatesPage/components/TemplateProjectEditDocumentsTab";
import TaskTemplate, { TaskTemplateWithKey } from "../../../types/TaskTemplate";
import ProjectTemplateEditTaskPage from "../../../containers/ProjectTemplateWizard/components/ProjectTemplateEditTaskPage";
import shortid from "shortid";
import api from "../../../api";
export interface IProjectTemplateWizardForm {
  name: string;
  description: string;
  type: number;
  documents: Document[];
}

export interface DocumentToCreate {
  name: string;
  file: File;
  isGlobal: boolean;
  isPrivate: boolean;
}

interface Match {
  id: string;
}

const ProjectTemplateWizard: FC<RouteChildrenProps<Match>> = ({ match }) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [documentsToCreate, setDocumentsToCreate] = useState<
    DocumentToCreate[]
  >([]);
  //const [createdDocuments, setCreatedDocuments] = useState<Document[]>([]);
  const [templateId, setTemplateId] = useState<number>();
  const [templateFolder, setTemplateFolder] = useState<Document>();
  const [isEditing, setIsEditing] = useState(false);
  const [tasksToCreate, setTasksToCreate] = useState<TaskTemplateWithKey[]>([]);
  const [taskDependencies, setTaskDependencies] = useState<any[]>([]);
  const { addToast } = useToasts();

  useEffect(() => {
    if (templateId) {
      setTemplateFolder(undefined);
      api.newFolders.getRootFolderTemplateProject(templateId).then(x => {
        setTemplateFolder(x);
        // api.newFolders.getFolderContents(x.id).then(x => {
        //   setCreatedDocuments(x.payload);
        // });
      });
    }
  }, [templateId]);

  const [machine, send] = useMachine(ProjectTemplateWizardStateMachine, {
    actions: {
      redirectToTemplatePage: () => {
        history.push("/templates");
        addToast(`Template ${isEditing ? "Updated" : "Created"} Successfully`, {
          appearance: "success",
          autoDismiss: true
        });
        return;
      },
      showErrorNotification: () =>
        addToast("An error has occured when creating the template", {
          appearance: "error",
          autoDismiss: true
        })
    }
  });

  const { nextEvents } = machine;

  const hasPrevious = useCallback(
    () => !!nextEvents.find(s => s.includes("PREVIOUS")),
    [nextEvents]
  );

  const hasNext = useCallback(
    () => !!nextEvents.find(s => s.includes("NEXT")),
    [nextEvents]
  );

  const validatePage = () => {
    switch (machine.value) {
      case "step1":
        if (name.trim().length === 0) {
          setNameError("Project Name is a required field");
          return;
        }
        setNameError("");
        if (description.trim() === "") {
          setDescriptionError("Description is required");
          return;
        }
        setDescriptionError("");
        send("NEXT");
        return;

      default:
        send("NEXT");
    }
  };

  const pageToRender = () => {
    switch (machine.value) {
      case "step1":
        return (
          <ProjectTemplateDetailPage
            name={name}
            setName={setName}
            nameError={nameError}
            description={description}
            setDescription={setDescription}
            descriptionError={descriptionError}
          />
        );
      case "step2":
        return <ProjectTypeSelectionPage setType={setType} type={type} />;
      case "step3":
        return isEditing && templateId ? (
          templateFolder && (
            <TemplateProjectEditDocumentsTab
              projectTemplateId={templateId}
              // createdDocuments={createdDocuments}
              // setCreatedDocuments={setCreatedDocuments}
              templateFolder={templateFolder}
            />
          )
        ) : (
          <ProjectDocumentUploadPage
            documentsToCreate={documentsToCreate}
            setDocumentsToCreate={setDocumentsToCreate}
          />
        );
      case "step4":
        return isEditing && templateId ? (
          <ProjectTemplateEditTaskPage
            setTasks={setTasksToCreate}
            tasks={tasksToCreate}
            templateId={templateId}
            taskDependencies={taskDependencies}
            setTaskDependencies={setTaskDependencies}
          />
        ) : (
          <ProjectTemplateTaskPage
            setTasksToCreate={setTasksToCreate}
            tasksToCreate={tasksToCreate}
            taskDependencies={taskDependencies}
            setTaskDependencies={setTaskDependencies}
          />
        );
    }
  };

  useEffect(() => {
    if (match?.params.id) {
      axios
        .get(endpoints.projectTemplates.get(parseFloat(match.params.id)))
        .then(({ data }) => {
          setName(data.name);
          setDescription(data.description);
          setType(data.type);
          setTemplateId(data.id);
          setIsEditing(true);
        });
    }
  }, [match]);

  useEffect(() => {
    if (match?.params.id) {
      axios
        .get(
          endpoints.projectTemplates.getTasksAndDependencies(
            parseFloat(match.params.id)
          )
        )
        .then(({ data }) => {
          const tasks = data.map((t: any) => {
            return { ...t.templateTask, key: shortid.generate() };
          });
          const taskDependencies = data.map((x: any, i: any) => {
            return {
              id: tasks[i].key,
              dependencies: x.dependencies
            };
          });
          setTasksToCreate(tasks);
          setTaskDependencies(taskDependencies);
        });
    }
  }, [match]);

  return (
    <div className="h-screen overflow-auto ">
      <div className="wizard">
        <div className="wizardHeader">
          <h1>
            {machine.value === "step1"
              ? isEditing
                ? "Update a Project Template"
                : "Create a Project Template "
              : name}
          </h1>
        </div>
        <div className="wizardContent">
          <form onSubmit={e => e.preventDefault()}>{pageToRender()}</form>
        </div>
        <div className="wizardButtons">
          {machine.value === "step1" && (
            <button
              className="btn-secondary sm:w-56 w-full mt-4 sm:mt-0 "
              onClick={() => history.push("/templates")}
            >
              Cancel
            </button>
          )}

          {hasPrevious() && (
            <button
              className="btn-secondary sm:w-56 w-full mt-4 sm:mt-0"
              onClick={() => send("PREVIOUS")}
            >
              Back
            </button>
          )}

          {hasNext() && (
            <button
              className="btn-primary w-full sm:w-56"
              onClick={() => validatePage()}
            >
              Next
            </button>
          )}

          {machine.value === "step4" && (
            <button
              className={`${"btn-primary"} w-full sm:w-56`}
              onClick={() =>
                send("SUBMIT", {
                  name,
                  description,
                  typeIndex: type,
                  documentsToCreate,
                  id: templateId,
                  existingTasks: tasksToCreate
                })
              }
            >
              {isEditing ? "Update" : "Create"} Project
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectTemplateWizard;
