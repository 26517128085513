import endpoints, { axios } from "../config/api";
import getProjectList from "./getProjectList";
import { getOnlineStatus } from "../helpers/useOnlineStatus";

async function getDashboard() {
  try {
    if (!getOnlineStatus()) {
      const projects = await getProjectList(1);
      return { projectCount: projects.payload.length, projects };
    }

    const response = await axios.get(endpoints.account.dashboard);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export default getDashboard;
