import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import LoginLayout from "../../layouts/LoginLayout";
import AppleBridgeLogo from "../../assets/img/logoWithBlackText.svg";
import SideBackground from "../../assets/img/logo.svg";
import TextInput from "../../components/Input/TextInput";
import { useAuth } from "../../state/AuthProvider";
import { Redirect, Link } from "react-router-dom";
import { useMachine } from "@xstate/react";
import LoginStateMachine from "./stateMachine";
import { PulseLoader } from "react-spinners";
import { getOnlineStatus } from "../../helpers/useOnlineStatus";
import OfflinePage from "../OfflinePage";

type LoginFormInputs = {
  username: string;
  password: string;
};

function LoginPage() {
  const [machine, send] = useMachine(LoginStateMachine);
  const { register, handleSubmit, errors } = useForm<LoginFormInputs>();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, isAuthenticated, error, checkLogin } = useAuth();

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  if (!login) {
    return null;
  }

  if (isAuthenticated || machine.matches("completed")) {
    return <Redirect to="/projects" />;
  }

  const submitLogin = () => {
    send("SUBMIT", {
      login,
      username,
      password
    });
  };

  return (
    <LoginLayout>
      <section className="flex flex-1 justify-center pt-8 md:pt-0 md:items-center relative">
        <form
          className="w-screen px-4 sm:px-0 sm:w-2/3 xl:w-2/5 flex flex-col "
          onSubmit={handleSubmit(submitLogin)}
        >
          <div className="my-12 flex justify-center md:justify-start">
            <img
              className="h-16"
              src={AppleBridgeLogo}
              alt="Applebridge logo"
            />
          </div>
          {!getOnlineStatus() ? (
            <OfflinePage />
          ) : (
            <Fragment>
              <div className="mb-4">
                {error && error.response?.status === 401 && (
                  <p className="leading-4 text-red text-xs">
                    Incorrect email or password
                    <br />
                    Please try again or contact your system administrator
                  </p>
                )}
                <TextInput
                  errorMessage={errors.username && "Email address is required"}
                  className={error?.response?.status === 401 ? "error" : ""}
                  inputRef={register({ required: true })}
                  label="Email Address"
                  name="username"
                  onChange={e => setUsername(e.target.value)}
                  placeholder="Enter your email address"
                  type="email"
                  value={username}
                />
              </div>
              <div className="mb-6">
                <TextInput
                  errorMessage={errors.password && "Password is required"}
                  inputRef={register({ required: true })}
                  className={error?.response?.status === 401 ? "error" : ""}
                  label="Password"
                  name="password"
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  type="password"
                  value={password}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className={classnames("w-full md:w-56", {
                    "btn-disabled": machine.matches("loading"),
                    "btn-primary": !machine.matches("loading")
                  })}
                >
                  {machine.matches("loading") ? (
                    <PulseLoader color="#D1D1D1" size="8px" />
                  ) : (
                    <span>Log into my account</span>
                  )}
                </button>
              </div>
              <div className="flex justify-center md:justify-start mt-10 md:mt-16">
                <Link to={`/account/recovery?username=${username}`}>
                  Reset password
                </Link>
              </div>
            </Fragment>
          )}
        </form>
      </section>
      <section className="flex flex-1 invisible sm:visible">
        <img
          className="h-full w-full object-cover"
          src={SideBackground}
          alt="Applebridge Background"
        />
      </section>
    </LoginLayout>
  );
}

export default LoginPage;
