import endpoints, { axios } from "../config/api";
import { getOnlineStatus } from "../helpers/useOnlineStatus";
import database from "../database";

async function getProjectUsers(projectId: number, search: string) {
  if (!getOnlineStatus()) {
    const project = await database.find({
      selector: {
        _id: {
          $regex: `^projects:${projectId}:members:[({]?[a-fA-F0-9]{8}[-]?([a-fA-F0-9]{4}[-]?){3}[a-fA-F0-9]{12}[})]?`
        }
      }
    });

    return {
      payload: project.docs,
      maxPage: 1,
      currentPage: 1
    };
  }

  const response = await axios.get(
    endpoints.project.getUsers(projectId, search)
  );

  return {
    payload: response.data,
    maxPage: 1,
    currentPage: 1
  };
}

export default getProjectUsers;
