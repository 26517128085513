import endpoints, { axios } from "../config/api";
import database from "../database";

async function downloadProject(projectId: number) {
  try {
    const { data } = await axios.get(endpoints.project.download(projectId));
    const { project, generatedAt } = data;

    await database.put({
      _id: `projects:${data.project.id}`,
      ...project,
      generatedAt
    });

    const tasksPromises = project.tasks.map((task: any) =>
      database.put({
        _id: `projects:${project.id}:tasks:${task.id}`,
        generatedAt,
        ...task
      })
    );

    const documentPromises = project.documents.map((doc: any) =>
      database.put({
        _id: `projects:${project.id}:documents:${doc.id}`,
        generatedAt,
        ...doc
      })
    );

    const memberPromises = project.members.map((member: any) =>
      database.put({
        _id: `projects:${project.id}:members:${member.id}`,
        generatedAt,
        ...member
      })
    );

    await Promise.all([tasksPromises, documentPromises, memberPromises]);
  } catch (error) {
    console.error(error);
  }
}

export default downloadProject;
