import React, { useState } from "react";
import Modal from "react-responsive-modal";
import TextInput from "../../../components/Input/TextInput";
import { PulseLoader } from "react-spinners";

interface Props {
  isModalOpen: boolean;
  folderName: string;
  onFolderNameChange: (value: string) => void;
  onSubmit: () => void;
  nameError: string;
  closeModal: () => void;
  isSubfolderUpdating: boolean;
  modalTitle?: string;
}

const UpdateSubfolderModal = ({
  folderName,
  isModalOpen,
  onFolderNameChange,
  onSubmit,
  nameError,
  closeModal,
  isSubfolderUpdating
}: Props) => {
  const [nameRegexError, setNameRegexError] = useState("");

  return (
    <Modal
      onClose={() => closeModal()}
      open={isModalOpen}
      center={true}
      showCloseIcon={false}
    >
      <h2>Update Folder</h2>
      <TextInput
        label="Folder Name"
        errorMessage={nameRegexError || nameError}
        value={folderName}
        onChange={e => {
          const regex = RegExp("^[a-zA-Z . 0-9_-]+$");
          if (!regex.test(e.target.value)) {
            setNameRegexError(
              "Folder names can not contain special characters."
            );
          } else {
            setNameRegexError("");
          }
          onFolderNameChange(e.target.value);
        }}
        maxLength={256}
      />

      <div className="flex flex-col-reverse sm:flex-row  mt-8">
        <button
          className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          disabled={isSubfolderUpdating || nameRegexError !== ""}
          className={`btn-${
            isSubfolderUpdating ? "disabled" : "primary"
          }  w-full sm:w-56 `}
          onClick={() => onSubmit()}
        >
          {isSubfolderUpdating ? <PulseLoader color="#00B2A9" /> : "Update"}
        </button>
      </div>
    </Modal>
  );
};

export default UpdateSubfolderModal;
