import endpoints, { axios } from "../config/api";

function addTaskDependancy(
  projectId: number,
  taskId: number,
  dependancyId: number
) {
  return axios.post(endpoints.task.addTaskDependancy(projectId, taskId), [
    dependancyId
  ]);
}

export default addTaskDependancy;
