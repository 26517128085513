import { Machine, assign } from "xstate";
import endpoints, { axios } from "../../config/api";

interface AccountContext {
  profileImage: string;
}

const updateUserRequest = (user: any) => {
  const formData = new FormData();
  formData.append("email", user.email);
  if (user.phoneNumber) {
    formData.append("phoneNumber", user.phoneNumber);
  }
  if (user.updatedProfileImage) {
    formData.append("profileImage", user.updatedProfileImage);
  }
  if (user.role) {
    formData.append("role", user.role);
  }
  return axios.put(endpoints.account.update(user.id), formData);
};

const isFormValid = () => true;

const AccountPageStateMachine = Machine<AccountContext>({
  id: "AccountPageStateMachine",
  initial: "idle",
  context: {
    profileImage: ""
  },
  states: {
    idle: {
      on: { SUBMIT: { target: "loading", cond: isFormValid } }
    },
    loading: {
      invoke: {
        id: "updateUser",
        src: (_, user) => updateUserRequest(user),
        onDone: {
          target: "success",
          actions: assign({
            profileImage: (_, event) => {
              return event.data.data.profileImage;
            }
          })
        },
        onError: {
          target: "error",
          actions: assign({ profileImage: "" })
        }
      }
    },
    success: {
      entry: "showSuccessNotification",
      after: {
        1000: "idle"
      }
    },
    error: {
      entry: "showErrorNotification",
      after: {
        1000: "idle"
      }
    }
  }
});

export default AccountPageStateMachine;
