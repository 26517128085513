import React, { FunctionComponent, Fragment } from "react";
import checkUserRole from "../helpers/checkUserRole";
import { useAuth } from "../state/AuthProvider";

interface Props {
  requiredRole: string;
  [x: string]: any;
}
const ProtectedComponent: FunctionComponent<Props> = ({
  children,
  requiredRole = "STANDARDUSER"
}) => {
  const { user, isAuthenticated } = useAuth();

  const roleCheck = user ? checkUserRole(requiredRole, user?.role) : false;
  if (isAuthenticated === true && roleCheck) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};

export default ProtectedComponent;
