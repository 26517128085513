import React, { useEffect, FC, useState } from "react";
import Modal from "react-responsive-modal";
import api from "../../api";
import { FolderPath } from "../../types/Folder";
import SelectInput from "../../components/Input/SelectInput";
import Document from "../../types/Document";

interface Props {
  setIsModalOpen: (isOpen: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  defaultFolder?: string;
  selectedFolder?: Document;
  setSelectedFolder?: (folderPath: Document) => void;
  projectId?: number;
}

const MoveDocumentModal: FC<Props> = ({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  defaultFolder,
  setSelectedFolder,
  selectedFolder,
  projectId
}) => {
  const [folderPaths, setFolderPaths] = useState<Document[]>([]);

  useEffect(() => {
    (async () => {
      if (isModalOpen) {
        const rootFolder = await (projectId
          ? api.newFolders.getRootFolderProject(projectId)
          : api.newFolders.getGlobalFolder());

        api.newFolders.getSubfolders(rootFolder.id).then((data: Document[]) => {
          console.log(data, defaultFolder);
          if (defaultFolder) {
            var folders = data.filter(x => x.id == defaultFolder);
            if (folders.length && setSelectedFolder) {
              setSelectedFolder(folders[0]);
            }
          }
          setFolderPaths([rootFolder, ...data]);
        });
      }
    })();
  }, [defaultFolder, isModalOpen]);

  return (
    <Modal
      center={true}
      onClose={() => setIsModalOpen(false)}
      open={isModalOpen}
      showCloseIcon={false}
    >
      <h2>Select a folder to move this document to</h2>
      <SelectInput
        className="my-8"
        label="Select a folder for your document"
        placeholder="Select a folder for your document"
        value={
          selectedFolder
            ? {
                value: selectedFolder.id,
                label: selectedFolder.name,
                data: selectedFolder
              }
            : {}
        }
        options={folderPaths.map(fp => {
          return {
            value: fp.id?.toString(),
            label: fp.path,
            data: fp
          };
        })}
        onChange={e => {
          setSelectedFolder && setSelectedFolder((e as any).data);
        }}
      />
      <div className="flex flex-col-reverse sm:flex-row  mt-8">
        <button
          className="btn-secondary mt-4 sm:mt-0 sm:mr-8 w-full sm:w-56 "
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          className="btn-primary w-full sm:w-56"
          onClick={() => onConfirm()}
        >
          Move Document
        </button>
      </div>
    </Modal>
  );
};

export default MoveDocumentModal;
