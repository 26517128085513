import Axios from "axios";
import { configure } from "axios-hooks";
import settings from "./settings";
import { getItem } from "../services/LocalStorage";
import history from "../helpers/history";

const axios = Axios.create({
  baseURL: `${settings.engineUrl}`
});

axios.interceptors.request.use(
  config => {
    const token = getItem("applebridge:token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    if (error.response.status === 401 && !error.config._retry) {
      history.push("/login");
    }
    return Promise.reject(error);
  }
);

const endpoints = {
  authentication: {
    login: `/authenticate`,
    recovery: `/authenticate/requestpasswordreset`,
    reset: `/authenticate/resetpassword`
  },
  account: {
    update: (userId: string) => `/users/${userId}`,
    dashboard: "/account/dashboard",
    tasks: (page: number, term: string) =>
      `/account/tasks?page=${page}&term=${term}`,
    notifications: (page: number = 1) => `/account/notifications?page=${page}`,
    totalNotifications: () => `/account/notifications/total`,
    markNotificationAsRead: (notificationId: number) =>
      `/account/notifications/${notificationId}`,
    deleteNotification: (notificationId: number) =>
      `/account/notifications/${notificationId}`
  },
  commercials: {
    get: `/commercial/templates`,
    create: (projectId: number) => `/projects/${projectId}/commercials`,
    delete: (projectId: number, templateId: string) =>
      `/projects/${projectId}/commercials/${templateId}`
  },
  project: {
    create: "/project",
    update: (projectId: number) => `/project/${projectId}`,
    get: (projectId: number) => `/project/${projectId}`,
    download: (projectId: number) => `/project/${projectId}/download`,
    delete: (projectId: number) => `/project/${projectId}`,
    getRootFolder: (projectId: number) => `/project/${projectId}/rootFolder`,
    getCommercialFolder: (projectId: number) =>
      `/project/${projectId}/commercialFolder`,
    getPage: (page: number) => `/project?page=${page}`,
    search: (term: string, page: number = 1) =>
      `/project?page=${page}&term=${term}`,
    getUsers: (projectId: number, search: string) =>
      `/project/${projectId}/members?search=${search}`,
    addUser: (projectId: number) => `/project/${projectId}/members`,
    updateMember: (projectId: number) => `/project/${projectId}/members`,
    removeUser: (projectId: number) => `/project/${projectId}/members`,
    searchAddableMembers: (projectId: number, search: string) =>
      `/project/${projectId}/members/addable?search=${search}`,
    getCommercials: (projectId: number, page: number) =>
      `/projects/${projectId}/commercials?page=${page}`
  },
  users: {
    search: (term: string, page: number = 1) =>
      `/users?page=${page}&term=${term}`,
    delete: (userId: string) => `/users/${userId}`,
    create: "/users",
    get: (userId: string) => `/users/${userId}`
  },
  newfolders: {
    global: `/newfolder/global`,
    commercialTemplates: `/newfolder/commercial-templates`,
    folderContents: (folderId: string) => `/newfolder/${folderId}/files`,
    subfolders: (folderId: string) => `/newfolder/${folderId}/subfolders`,
    byId: (folderId: string) => `/newfolder/${folderId}`,
    file: (folderId: string, fileId: string) =>
      `/newfolder/${folderId}/files/${fileId}`,
    replace: (folderId: string, fileId: string) =>
      `/newfolder/${folderId}/files/${fileId}/replace`,
    copyFile: (folderId: string, fileId: string) =>
      `/newfolder/${folderId}/files/${fileId}/copy`,
    downloadFile: (folderId: string, fileId: string) =>
      `/newfolder/${folderId}/files/${fileId}/download`,
    editFile: (folderId: string, fileId: string) =>
      `/newfolder/${folderId}/files/${fileId}/edit`
  },
  task: {
    getUserTasks: (term: string, page: number = 1) =>
      `/account/tasks?page=${page}&term=${term}`,
    getProjectTasks: (
      projectId: number,
      page: number = 1,
      search: string = ""
    ) => `/projects/${projectId}/tasks/?page=${page}&search=${search}`,
    get: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}`,
    getSubTasks: (projectId: number, taskId: number, page: number = 1) =>
      `/projects/${projectId}/tasks/${taskId}/subtasks?page=${page}`,
    getRootFolder: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/rootFolder`,
    complete: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/complete`,
    delete: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}`,
    update: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}`,
    getUsers: (projectId: number, taskId: number, search: string) =>
      `/projects/${projectId}/tasks/${taskId}/members?search=${search}`,
    create: (projectId: number) => `/projects/${projectId}/tasks`,
    addUser: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/members`,
    removeUser: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/members`,
    searchAddableMembers: (projectId: number, taskId: number, search: string) =>
      `/projects/${projectId}/tasks/${taskId}/members/addable?search=${search}`,
    getByTaskId: (taskId: number) => `/tasks/${taskId}`,
    getTaskDependancies: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/dependencies`,
    getAddableTaskDependancies: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/dependencies/addable`,
    addTaskDependancy: (projectId: number, taskId: number) =>
      `/projects/${projectId}/tasks/${taskId}/dependencies`
  },
  projectTemplates: {
    create: "/TemplateProjects",
    getPage: (page: number, search: string = "") =>
      `/TemplateProjects?page=${page}&search=${search}`,
    delete: (projectTemplateId: number = 1) =>
      `/TemplateProjects/${projectTemplateId}`,
    get: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}`,
    getRootFolder: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}/rootFolder`,
    update: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}`,
    getTasks: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}/tasks`,
    getTasksAndDependencies: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}/tasksanddependencies`,
    addTask: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}/tasks`,
    removeTask: (projectTemplateId: number) =>
      `/TemplateProjects/${projectTemplateId}/tasks`
  },
  taskTemplates: {
    create: "/templatetasks",
    getPage: (page: number, search: string = "") =>
      `/templatetasks?page=${page}&search=${search}`,
    delete: (taskTemplateId: number = 1) => `/templatetasks/${taskTemplateId}`,
    get: (taskTemplateId: number) => `/templatetasks/${taskTemplateId}`,
    getRootFolder: (taskTemplateId: number) =>
      `/templatetasks/${taskTemplateId}/rootFolder`,
    getSubtasks: (taskTemplateId: number, page: number = 1) =>
      `/templatetasks/${taskTemplateId}/subtasks?page=${page}`,
    update: (taskTemplateId: number) => `/templatetasks/${taskTemplateId}`,
    getAddableTaskDependancies: (taskTemplateId: number) =>
      `/templatetasks/${taskTemplateId}/dependencies/addable`,
    getAddableTaskDependanciesByIds: (selectedIds: string) =>
      `/templatetasks/addable?selectedIds=${selectedIds}`,
    getTaskTemplateDependancies: (taskTemplateId: number) =>
      `/templatetasks/${taskTemplateId}/dependencies`,
    addTaskTemplateDependancies: (taskTemplateId: number) =>
      `/templatetasks/${taskTemplateId}/dependencies`,
    getRecursiveDependancies: (taskTemplateId: number) =>
      `/templatetasks/${taskTemplateId}/dependencies/recursive`,
    getTasksAndDependencies: (taskTemplateId: number) =>
      `/templatetasks/${taskTemplateId}/tasksanddependencies`
  }
};

configure({ axios });

export { axios };
export default endpoints;
