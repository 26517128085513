import React, { useState, useEffect } from "react";
import TextInput from "../../../components/Input/TextInput";
import ListItem from "../../../components/List/ListItem";
import options from "../../../assets/img/options.svg";
import placeholder from "../../../assets/img/placeholder.png";
import User from "../../../types/User";
import useDebounce from "../../../helpers/useDebounce";
import endpoints, { axios } from "../../../config/api";
import SelectInput from "../../../components/Input/SelectInput";
import { Option } from "react-select/src/filters";
import { useTranslate } from "react-polyglot";

interface Props {
  teamMembers: User[];
  setTeamMembers: (teamMembers: User[]) => void;
  teamMembersError: string;
  projectId: number;
}

const TaskAssignTeamMembersPage = ({
  setTeamMembers,
  teamMembers,
  teamMembersError,
  projectId
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const t = useTranslate();

  useEffect(() => {
    setLoading(true);
    setUsers([]);
    axios
      .get(endpoints.project.getUsers(projectId, debouncedSearchTerm))
      .then(({ data }) => {
        setUsers([...data]);

        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [debouncedSearchTerm]);

  return (
    <div>
      <SelectInput
        className="mb-4"
        label="Assign team members"
        placeholder="Assign team members"
        errorMessage={teamMembersError}
        onSearchChange={e => {
          setSearchTerm(e);
        }}
        onChange={e => {
          const updatedTeamMembers = e ? (e as Option[]).map(o => o.data) : [];
          setTeamMembers(updatedTeamMembers);
        }}
        options={users.map(u => {
          return {
            value: u.id,
            label: `${u.firstName + " " + u.lastName}`,
            data: u
          };
        })}
        value={teamMembers.map(u => {
          return {
            value: u.id,
            label: `${u.firstName + " " + u.lastName}`,
            data: u
          };
        })}
        isMulti={true}
        searchValue={searchTerm}
        isLoading={loading}
      />
      <div className="mt-8">
        {teamMembers &&
          teamMembers.map(u => (
            <ListItem
              label={u.firstName + " " + u.lastName}
              meta={t(u.role)}
              imageUrl={
                u.profileImage
                  ? u.profileImage
                  : `https://eu.ui-avatars.com/api/?name=${u.firstName}+${u.lastName}&size=64`
              }
            />
          ))}
      </div>
    </div>
  );
};

export default TaskAssignTeamMembersPage;
