import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Popover from "react-awesome-popover";
import CheckIsMobile from "../../helpers/checkIsMobile";
import ActionIcon from "../../assets/img/options.svg";
import ProtectedComponent from "../../components/ProtectedComponent";
import deleteHoverIcon from "../../assets/img/deleteHover.svg";
import deleteIcon from "../../assets/img/delete.svg";
import Task from "../../types/Task";
import classnames from "classnames";
import { useOnlineProvider } from "../../state/OnlineProvider";

interface Props {
  item: Task;
  setTaskToDelete: (task: Task) => void;
  setIsTaskDeleteModalOpen: (isOpen: boolean) => void;
}
const ProjectDashboardTaskItem = ({
  item,
  setIsTaskDeleteModalOpen,
  setTaskToDelete
}: Props) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { isOnline } = useOnlineProvider();
  const popoverRef = useRef<any>();

  function handleClickOutside(event: any) {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      isPopoverOpen
    ) {
      setIsPopoverOpen(false);
    }
  }

  useEffect(() => {
    if (popoverRef) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [popoverRef, isPopoverOpen]);

  return (
    <tr className=" w-full  cursor-pointer" key={item.id}>
      <td
        className="pl-2"
        onClick={() =>
          history.push(`/projects/${item.projectId}/tasks/${item.id}`)
        }
      >
        <div className="flex-row">
          <p
            className={classnames({
              "text-grey": item.isCompleted
            })}
          >
            {item.name}
          </p>
          <small
            className={classnames({
              "text-grey": item.isCompleted
            })}
          >
            {item.project.name}
          </small>
        </div>
      </td>
      <td
        className={classnames("hidden sm:table-cell", {
          "text-grey": item.isCompleted
        })}
        onClick={() =>
          history.push(`/projects/${item.projectId}/tasks/${item.id}`)
        }
      >
        {new Date(item.startDate).toLocaleDateString()}
      </td>
      <td
        className={classnames("hidden sm:table-cell", {
          "text-grey": item.isCompleted
        })}
        onClick={() =>
          history.push(`/projects/${item.projectId}/tasks/${item.id}`)
        }
      >
        {new Date(item.expectedCompletionDate).toLocaleDateString()}
      </td>
      <td>
        <div className="flex justify-end">
          <Popover
            overlayColor="rgba(0,0,0,0)"
            placement="left-center"
            arrowProps={{ className: "text-white " }}
            arrow={!CheckIsMobile()}
            action={null}
            open={isPopoverOpen}
            initZindex={0}
          >
            <button
              className="py-3 m-0 pr-4 focus:outline-none cursor-pointer "
              onClick={() => setIsPopoverOpen(true)}
              onBlur={() => setIsPopoverOpen(false)}
            >
              <img
                className="h-full w-full"
                src={ActionIcon}
                alt="item options"
              />
            </button>
            <div
              ref={popoverRef}
              className="bg-white py-2 flex flex-col w-56 h-32 justify-evenly shadow-xl"
            >
              <button
                className="btn-tertiary h-8 w-full flex flex-row sm:pl-6 font-normal justify-center  sm:justify-start"
                onMouseDown={() =>
                  history.push(`/projects/${item.projectId}/tasks/${item.id}`)
                }
              >
                View Task
              </button>
              <div className="h-1 w-full border-t-2 border-lightGrey"></div>
              <ProtectedComponent requiredRole="PROJECTMANAGER">
                {isOnline ? (
                  <button
                    className="btn-tertiary mt-2 h-8 text-red hover:text-red flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                    onMouseDown={() => {
                      setTaskToDelete(item);
                      setIsTaskDeleteModalOpen(true);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <img src={deleteHoverIcon} className="pr-4 " alt="Delete" />
                    Delete Task
                  </button>
                ) : (
                  <button
                    className="btn-tertiary mt-2  text-grey hover:text-grey flex flex-row sm:pl-6 font-normal justify-center sm:justify-start"
                    disabled={true}
                  >
                    <img src={deleteIcon} className="pr-4 " alt="Delete" />
                    Delete Task
                  </button>
                )}
              </ProtectedComponent>
            </div>
          </Popover>
        </div>
      </td>
    </tr>
  );
};

export default ProjectDashboardTaskItem;
