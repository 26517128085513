import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import ProtectedComponent from "../../components/ProtectedComponent";
import api from "../../api";
import { useToasts } from "react-toast-notifications";
import InfiniteScroll from "react-infinite-scroller";
import { PulseLoader } from "react-spinners";
import ListItem from "../../components/List/ListItem";
import CreateCommercialModal from "../../components/Modal/CreateCommercalModal";
import DeleteDocumentModal from "../DocumentPage/DeleteDocumentModal";
import Document from "../../types/Document";
import settings from "../../config/settings";
import endpoints from "../../config/api";
import CreateDocumentModal from "../DocumentPage/CreateDocumentModal";
import ReplaceDocumentModal from "../DocumentPage/ReplaceDocumentModal";

interface Props {
  projectId: number;
}

const ProjectCommercialTab: FunctionComponent<Props> = ({ projectId }) => {
  const { addToast } = useToasts();

  const [templateFolder, setTemplateFolder] = useState<Document>(
    {} as Document
  );
  const [templates, setTemplates] = useState<Document[]>([]);

  const [isTemplateModalOpen, setTemplateModalOpen] = useState(false);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [documentFile, setDocumentFile] = useState<File>();
  const [documentFileError, setDocumentFileError] = useState("");
  const [newDocName, setNewDocName] = useState("");
  const [documentNameError, setDocumentNameError] = useState("");
  const [documentIsPrivate, setDocumentIsPrivate] = useState(false);

  const [currentDocuments, setCurrentDocuments] = useState<Document[]>([]);
  const [currentFolder, setCurrentFolder] = useState<Document>({} as Document);

  //const [commercials, setCommercials] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [replaceFile, setReplaceFile] = useState<Document>();
  const [documentToDelete, setDocumentToDelete] = useState("");
  const scrollRef = useRef(null);

  useEffect(() => {
    setLoading(true);

    api.newFolders.getCommercialTemplatesFolder().then(async folder => {
      setTemplateFolder(folder);

      api.newFolders
        .getFolderContents(folder.id)
        .then(data => {
          setTemplates(data.payload);
        })
        .catch(() => {
          addToast("Failed to get Commercial Templates.", {
            appearance: "error",
            autoDismiss: true
          });
        });
    });

    api.newFolders.getCommercialFolderProject(projectId).then(async folder => {
      setCurrentFolder(folder);

      api.newFolders
        .getFolderContents(folder.id, "TIME")
        .then(data => {
          setCurrentDocuments([...currentDocuments, ...data.payload]);
          setCurrentPage(data.currentPage);
          setMaxPage(data.maxPage);
          setLoading(false);
        })
        .catch(() => {
          addToast("Failed to get commercial documents. Try again", {
            appearance: "error",
            autoDismiss: true
          });
        });
    });
  }, [projectId]);

  const createDocumentFromTemplate = (name: string, template: Document) => {
    setCreating(true);
    api.newFolders
      .copyFile(
        templateFolder.id,
        template.id,
        name + "-" + projectId,
        currentFolder.id
      )
      .then(data => {
        api.newFolders
          .getFolderContents(currentFolder.id, "TIME")
          .then(data => {
            setCurrentDocuments([...data.payload]);
            setCurrentPage(data.currentPage);
            setMaxPage(data.maxPage);
            setLoading(false);
            setCreating(false);
            setTemplateModalOpen(false);
          })
          .finally(() => {
            addToast("Commercial document created.", {
              appearance: "success",
              autoDismiss: true
            });
          });
      })
      .catch(() => {
        addToast("Failed to create commercial document. Try again.", {
          appearance: "error",
          autoDismiss: true
        });
        setCreating(false);
        setTemplateModalOpen(false);
      });
  };

  const deleteDocument = (documentId: any) => {
    api.newFolders
      .deleteFile(currentFolder.id, documentId)
      .then(() => {
        setCurrentDocuments(
          currentDocuments.filter((c: any) => c.id !== documentId)
        );
        setDeleteModalOpen(false);
        addToast("Commercial document deleted.", {
          appearance: "success",
          autoDismiss: true
        });
      })
      .catch(() => {
        setDeleteModalOpen(false);
        addToast("Failed to delete commercial document. Try again.", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const openDocument = (location: string) => {
    window.open(location, "_blank");
  };

  const closeCreateModal = () => {
    setIsUploadModalOpen(false);
    setDocumentFile(undefined);
    setNewDocName("");
    setDocumentIsPrivate(false);
  };

  const createDocument = () => {
    if (!newDocName || newDocName.trim().length === 0) {
      setDocumentNameError("Please provide a file name");
      return;
    }
    setDocumentNameError("");
    if (!documentFile) {
      setDocumentFileError("Please provide a file to upload");
      return;
    }
    setDocumentFileError("");

    setLoading(true);
    api.newFolders
      .uploadFile(currentFolder.id, newDocName, documentIsPrivate, documentFile)
      .then((response: Document) => {
        setCurrentDocuments([response, ...currentDocuments]);
        closeCreateModal();
        addToast("Commercial Document Uploaded Successfully", {
          appearance: "success",
          autoDismiss: true
        });
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error);
        addToast(
          "Error uploading Commercial Document. Try again with a different name.",
          {
            appearance: "error",
            autoDismiss: true
          }
        );
      });
  };

  return (
    <div className="flex flex-col flex-1 mt-12">
      {/* <ProtectedComponent requiredRole="PROJECTMANAGER"> */}
      <DeleteDocumentModal
        setIsModalOpen={setDeleteModalOpen}
        isModalOpen={isDeleteModalOpen}
        onConfirm={() => documentToDelete && deleteDocument(documentToDelete)}
      />
      {replaceFile && (
        <ReplaceDocumentModal
          setIsModalOpen={v => {
            if (!v) {
              setReplaceFile(undefined);
            }
          }}
          isModalOpen={replaceFile !== undefined}
          onConfirm={() => setReplaceFile(undefined)}
          document={replaceFile}
          folder={currentFolder.id}
        />
      )}
      <CreateCommercialModal
        isOpen={isTemplateModalOpen}
        templates={templates}
        closeModal={() => setTemplateModalOpen(false)}
        onSubmit={(name, template) =>
          createDocumentFromTemplate(name, template)
        }
        isLoading={isCreating}
      />
      <CreateDocumentModal
        documentName={newDocName}
        dropzoneLabel="Drop your document here or click to open the file explorer"
        isModalOpen={isUploadModalOpen}
        onDocumentNameChange={value => setNewDocName(value)}
        onDocumentUpload={file => setDocumentFile(file)}
        onSubmit={() => createDocument()}
        setIsModalOpen={setIsUploadModalOpen}
        acceptedFileName={documentFile?.name}
        nameError={documentNameError}
        isPrivate={documentIsPrivate}
        setIsPrivate={setDocumentIsPrivate}
        closeModal={() => closeCreateModal()}
        dropzoneError={documentFileError}
        isDocumentCreating={isLoading}
        disableFolderSelect
        disablePrivateSelect
      />
      <div className="flex md:self-end justify-between flex-col lg:flex-row">
        <button
          className="btn-primary w-full md:w-56 mt-1 mr-0 mb-4 lg:mb-0 lg:mr-8"
          onClick={() => setTemplateModalOpen(true)}
        >
          + New Commercial Document
        </button>
        <button
          className="btn-primary w-full md:w-56 mr-0 mb-4  lg:mb-0 lg:mr-8 mt-4 lg:mt-1"
          onClick={() => setIsUploadModalOpen(true)}
        >
          Upload
        </button>
      </div>
      {/* </ProtectedComponent> */}
      <div className="mt-12">
        <InfiniteScroll
          pageStart={1}
          threshold={250}
          hasMore={currentPage < maxPage}
          loadMore={() => setCurrentPage(currentPage + 1)}
          useWindow={false}
          element="div"
          className="mb-8"
          getScrollParent={() => scrollRef.current}
          loader={
            <div className="w-full flex justify-center items-center">
              <PulseLoader color="#00B2A9" />
            </div>
          }
        >
          {isLoading && (
            <tr>
              <td colSpan={3}>
                <div className="w-full flex items-center justify-center">
                  <PulseLoader color="#00B2A9" />
                </div>
              </td>
            </tr>
          )}
          {currentDocuments && currentDocuments.length === 0 && (
            <ListItem
              containerStyle="bg-white hover:bg-lightGrey"
              label="No commercial documents assigned to project."
            />
          )}
          {currentDocuments &&
            currentDocuments.map(commercial => (
              <ListItem
                containerStyle="bg-white hover:bg-lightGrey"
                key={commercial.id}
                label={commercial.name}
                labelStyle="no-underline"
                onClick={() =>
                  openDocument(
                    `${settings.engineUrl}${endpoints.newfolders.downloadFile(
                      currentFolder.id,
                      commercial.id
                    )}`
                  )
                }
                popover={
                  <ProtectedComponent requiredRole="PROJECTMANAGER">
                    <div
                      id="deleteIcon"
                      className="cursor-pointer hidden sm:block"
                      onClick={() => {
                        setDocumentToDelete(commercial.id);
                        setDeleteModalOpen(true);
                      }}
                    />
                    <div
                      id="editIcon"
                      className="cursor-pointer hidden sm:block"
                      onClick={() => {
                        setReplaceFile(commercial);
                      }}
                    />
                  </ProtectedComponent>
                }
              >
                <h4 className="pr-4 text-darkGrey font-normal">
                  {new Date(commercial.createdAt).toLocaleString()}
                </h4>
              </ListItem>
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ProjectCommercialTab;
