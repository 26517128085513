import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import defaultTheme from "./defaultTheme";
import * as serviceWorker from "./serviceWorker";
import { I18n } from "react-polyglot";
import en from "./locale/en";
import "./assets/main.css";
import Router from "./components/Router";
import AuthProvider from "./state/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { ToastProvider } from "react-toast-notifications";
import "react-dropdown/style.css";
import "react-responsive-modal/styles.css";
import SidebarProvider from "./state/SidebarProvider";
import AppleToast from "./components/Toast";
import OnlineStatusProvider from "./state/OnlineProvider";
import settings from "./config/settings";
import NotificationProvider from "./state/NotificationProvider";

ReactDOM.render(
  <ThemeProvider theme={defaultTheme}>
    <I18n locale="en" messages={en}>
      <ToastProvider components={{ Toast: AppleToast }}>
        <AuthProvider>
          <NotificationProvider>
            <OnlineStatusProvider>
              <SidebarProvider>
                <Router />
              </SidebarProvider>
            </OnlineStatusProvider>
          </NotificationProvider>
        </AuthProvider>
      </ToastProvider>
    </I18n>
  </ThemeProvider>,
  document.getElementById("root")
);

if (settings.enableServiceWorker) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
