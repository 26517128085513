import React, { FC, useState } from "react";
import Modal from "react-responsive-modal";
import TextInput from "../../components/Input/TextInput";
import SelectInput from "../../components/Input/SelectInput";
import Dropzone from "../../components/Dropzone";
import dropzoneAcceptedFileTypes from "../../helpers/dropzoneAcceptedFileTypes";
import userRoleOptions from "../../helpers/userRoleOptions";
import { Option } from "react-select/src/filters";
import { useAuth } from "../../state/AuthProvider";

export interface UserToCreate {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  profileImage: File | undefined;
  phoneNumber?: string;
}

interface Props {
  isModalOpen: boolean;
  onSubmit: (createdUser: UserToCreate) => void;
  closeModal: () => void;
}

const CreateUserModal: FC<Props> = ({ closeModal, isModalOpen, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [role, setRole] = useState<Option>();
  const [roleError, setRoleError] = useState("");
  const [profileImage, setProfileImage] = useState<File>();
  const { user } = useAuth();

  const closeCreateModal = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setRole(undefined);
    setProfileImage(undefined);
    setPhoneNumber("");
    closeModal();
  };
  const createUser = () => {
    if (!firstName) {
      setFirstNameError("First Name is Required");
      return;
    }

    if (!lastName) {
      setLastNameError("Last Name is Required");
      return;
    }
    setLastNameError("");
    if (!role) {
      setRoleError("Role is Required");
      return;
    }
    setRoleError("");
    if (!email) {
      setEmailError("Email is Required");
      return;
    }
    const emailRegex = RegExp(
      "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
    );
    if (!emailRegex.test(email)) {
      setEmailError("Valid Email is Required");
      return;
    }
    setEmailError("");
    const phoneNumberRegex = RegExp(
      /^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*/
    );
    if (phoneNumber) {
      if (!phoneNumberRegex.test(phoneNumber)) {
        setPhoneNumberError("Please Enter a Vaild Phone Number");
        return;
      }
    }
    setPhoneNumberError("");
    const userToCreate: UserToCreate = {
      email,
      firstName,
      lastName,
      role: role?.value,
      profileImage,
      phoneNumber
    };

    onSubmit(userToCreate);
    closeCreateModal();
  };
  return (
    <Modal
      onClose={() => {
        closeModal();
      }}
      open={isModalOpen}
      center={true}
      classNames={{ modal: "w-84 " }}
    >
      <h2>Create a User</h2>
      <div>
        <Dropzone
          label="Drop a profile image here or click to open the explorer"
          onDrop={file => setProfileImage(file)}
          acceptedFileName={profileImage?.name}
          acceptedFileTypes={dropzoneAcceptedFileTypes.filter(t =>
            t.includes("image")
          )}
        />
        <TextInput
          className="border-2 mb-4 "
          label="First Name"
          placeholder="Enter the user's first name"
          onChange={e => {
            setFirstName(e.target.value);
          }}
          value={firstName}
          maxLength={256}
          errorMessage={firstNameError}
        />
        <TextInput
          className="border-2 mb-4"
          label="Last Name"
          placeholder="Enter the user's last name"
          onChange={e => {
            setLastName(e.target.value);
          }}
          value={lastName}
          maxLength={256}
          errorMessage={lastNameError}
        />
        <SelectInput
          className="mb-4"
          label="Role"
          placeholder="Select the user's role"
          onChange={e => {
            setRole(e as Option);
          }}
          value={role}
          options={userRoleOptions(user.role)}
          errorMessage={roleError}
          isSearchable={false}
        />
        <TextInput
          className="border-2 mb-4"
          label="Email"
          placeholder="Enter the user's email"
          onChange={e => {
            setEmail(e.target.value);
          }}
          value={email}
          maxLength={256}
          errorMessage={emailError}
          pattern="^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
        />
        <TextInput
          className="border-2 mb-4"
          label="Telephone Number"
          placeholder="Enter the user's telephone number"
          onChange={e => {
            setPhoneNumber(e.target.value);
          }}
          value={phoneNumber}
          maxLength={256}
          errorMessage={phoneNumberError}
        />
        <div className="flex flex-col-reverse  md:flex-row justify-between md:pt-4 ">
          <button
            className="btn-secondary border-red text-red hover:border-red hover:text-red w-full md:w-32 mt-4 md:mt-0 "
            onClick={() => {
              closeCreateModal();
            }}
          >
            Cancel
          </button>
          <button
            className={" btn-primary w-full md:w-32"}
            onClick={createUser}
          >
            Add User
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
