import React from "react";
import { Router as BrowserRouter, Switch } from "react-router-dom";
import history from "../helpers/history";
import LoginPage from "../pages/Login";
import App from "./App";
import Route from "./Route";
import PasswordRecoveryPage from "../pages/PasswordRecovery";
import PasswordResetPage from "../pages/PasswordReset";

const Router = () => (
  <BrowserRouter history={history}>
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route
        exact
        path="/account/recovery"
        component={PasswordRecoveryPage}
        allowOffline={false}
      />
      <Route
        exact
        path="/account/reset"
        component={PasswordResetPage}
        allowOffline={false}
      />
      <App />
    </Switch>
  </BrowserRouter>
);

export default Router;
