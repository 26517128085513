import { getOnlineStatus } from "../helpers/useOnlineStatus";
import endpoints, { axios } from "../config/api";
import database from "../database";
import createOfflineEvent from "../database/common/createOfflineEvent";

async function completeSubtask(
  projectId: number,
  taskId: number,
  subtaskId: number
) {
  if (!getOnlineStatus()) {
    const offlineEvents = await database.find({
      selector: {
        _id: { $regex: "offline:[\\s\\S]*" }
      }
    });
    const createdEvent = offlineEvents.docs.find(
      (doc: any) => doc.payload.id === subtaskId
    );

    const storedTask: any = await database.get(
      `projects:${projectId}:tasks:${taskId}`
    );

    const index = storedTask.subtasks.findIndex((t: any) => t.id === subtaskId);
    storedTask.subtasks[index] = {
      ...storedTask.subtasks[index],
      isCompleted: true
    };
    if (createdEvent) {
      await database.remove(createdEvent);

      await createOfflineEvent(
        "task",
        "CREATE",
        {
          ...storedTask.subtasks[index],
          isCompleted: true
        },
        createdEvent?._id.split(":")[1]
      );
    } else {
      await createOfflineEvent("task", "UPDATE", {
        projectId,
        taskId,
        subtaskId
      });
    }
    await database.put(storedTask, { force: true });
    return storedTask;
  }

  const response = await axios.post(
    endpoints.task.complete(projectId, subtaskId)
  );
  return response.data;
}

export default completeSubtask;
