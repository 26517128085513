import React from "react";
import TextInput from "../../../components/Input/TextInput";
import DateInput from "../../../components/Input/DateInput";

interface Props {
  name: string;
  nameError: string;
  setName: (name: string) => void;
  startDate: Date;
  startDateError: string;
  setStartDate: (date: Date) => void;
  expectedCompletionDate: Date;
  expectedCompletionDateError: string;
  setExpectedCompletionDate: (date: Date) => void;
}

const TaskDetailPage = ({
  name,
  nameError,
  setName,
  startDate,
  startDateError,
  setStartDate,
  expectedCompletionDate,
  expectedCompletionDateError,
  setExpectedCompletionDate
}: Props) => {
  return (
    <div>
      <TextInput
        label="Give your task a name"
        placeholder="Give your task a name"
        errorMessage={nameError}
        value={name}
        onChange={({ target: { value } }) => setName(value)}
        maxLength={256}
      />

      <DateInput
        groupClass="my-8 flex flex-col"
        errorMessage={startDateError}
        label="Add a start date"
        className=" w-full"
        value={startDate}
        onChange={date => {
          if (expectedCompletionDate < date) {
            setExpectedCompletionDate(date);
          }
          setStartDate(date);
        }}
      />

      <DateInput
        groupClass="my-8 flex flex-col"
        errorMessage={expectedCompletionDateError}
        label="Add a completion date"
        className="w-full"
        value={expectedCompletionDate}
        minDate={startDate}
        onChange={date => {
          setExpectedCompletionDate(date);
        }}
      />
    </div>
  );
};

export default TaskDetailPage;
