import React, { useState, useEffect, useRef, FC } from "react";
import useAxios from "axios-hooks";
import endpoints from "../../config/api";
import Document, { DocumentListResponse } from "../../types/Document";
import { useAuth } from "../../state/AuthProvider";
import { useToasts } from "react-toast-notifications";
import ProtectedComponent from "../../components/ProtectedComponent";
import api from "../../api";
import Modal from "react-responsive-modal";
import ProjectDocumentTab from "../../containers/ProjectOverviewPage/ProjectDocumentTab";
import { RouteChildrenProps, useHistory } from "react-router-dom";
import checkUserRole from "../../helpers/checkUserRole";
import CreateDocumentModal from "../../containers/DocumentPage/CreateDocumentModal";
import DeleteDocumentModal from "../../containers/DocumentPage/DeleteDocumentModal";
import MoveDocumentModal from "../../containers/DocumentPage/MoveDocumentModal";

interface Match {
  folderId: string;
}

const DocumentsPage: FC<RouteChildrenProps<Match>> = ({ match }) => {
  const { user, isAuthenticated } = useAuth();
  const scrollRef = useRef(null);
  const { addToast } = useToasts();
  const [rootFolder, setRootFolder] = useState("");
  const [currentDocumentPage, setCurrentDocumentPage] = useState(1);
  const [maxDocumentPage, setMaxDocumentPage] = useState(1);
  const [currentDocuments, setCurrentDocuments] = useState<Document[]>([]);

  const [documentToCreateFile, setDocumentToCreateFile] = useState<File>();
  const [documentToCreateFileError, setDocumentToCreateFileError] = useState(
    ""
  );

  const [documentToCreateName, setDocumentToCreateName] = useState("");
  const [documentToCreateNameError, setDocumentToCreateNameError] = useState(
    ""
  );

  const [documentToCreatePrivate, setDocumentToCreatePrivate] = useState(false);
  const [documentToCreateFolder, setDocumentToCreateFolder] = useState<
    Document
  >();
  const [documentToMoveFolder, setDocumentToMoveFolder] = useState<Document>();

  const [documentToDelete, setDocumentToDelete] = useState<string>();
  const [documentToMove, setDocumentToMove] = useState<string>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isCreateSubfolderModalOpen, setIsCreateSubfolderModalOpen] = useState(
    false
  );
  const [isSelectCreateModalOpen, setIsSelectCreateModalOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!match || !match.params.folderId) {
      api.newFolders.getGlobalFolder().then(response => {
        setRootFolder(response.id);
      });
    } else {
      setRootFolder(match.params.folderId);
    }
  }, [match?.params.folderId]);

  const loadMoreDocuments = (page: any) => {};

  const createDocument = () => {
    if (!documentToCreateName || documentToCreateName.trim().length === 0) {
      setDocumentToCreateNameError("Please provide a file name");
      return;
    }
    setDocumentToCreateNameError("");
    if (!documentToCreateFile) {
      setDocumentToCreateFileError("Please provide a file to upload");
      return;
    }
    setDocumentToCreateFileError("");
    setIsCreateLoading(true);
    api.newFolders
      .uploadFile(
        rootFolder,
        documentToCreateName,
        documentToCreatePrivate,
        documentToCreateFile
      )
      .then(createDocumentResponse => {
        if (createDocumentResponse.status === 200) {
          if (!documentToCreateFolder || !documentToCreateFolder.id) {
            setCurrentDocuments(
              [...currentDocuments, createDocumentResponse.data].sort(
                (a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                }
              )
            );
          }
          closeCreateModal();
          addToast("Document Created Successfully", {
            appearance: "success",
            autoDismiss: true
          });
        }
        setIsCreateLoading(false);
      })
      .catch(() => {
        addToast("Failed to Create document. Try again", {
          appearance: "error",
          autoDismiss: true
        });
        setIsCreateLoading(false);
      });
  };

  const deleteDocument = (documentId: string) => {
    api.newFolders
      .deleteFile(rootFolder, documentId)
      .then(() => {
        addToast("Document Successfully Deleted", {
          appearance: "success",
          autoDismiss: true
        });
        setCurrentDocuments(
          currentDocuments.filter(doc => doc.id !== documentId)
        );
        setIsDeleteModalOpen(false);
        setDocumentToDelete(undefined);
      })
      .catch(() => {
        addToast("Failed to delete document", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
    setDocumentToCreateFile(undefined);
    setDocumentToCreateName("");
    setDocumentToCreatePrivate(false);
    setDocumentToCreateFileError("");
    setDocumentToCreateNameError("");
  };

  const downloadDocument = (location: string) => {
    window.open(location, "_blank");
  };

  const moveDocument = () => {
    if (documentToMove) {
      api.newFolders
        .updateFile(
          rootFolder,
          documentToMove,
          undefined,
          documentToMoveFolder?.id
        )
        .then(() => {
          setCurrentDocuments(
            currentDocuments.filter(d => d.id !== documentToMove)
          );
          setDocumentToMove(undefined);
          setDocumentToMoveFolder(undefined);
          setIsMoveModalOpen(false);
          addToast("Document Successfully Moved", {
            appearance: "success",
            autoDismiss: true
          });
        })
        .catch(() => {
          addToast("Failed to move document", {
            appearance: "error",
            autoDismiss: true
          });
        });
    }
  };

  return (
    <div ref={scrollRef} className="h-screen overflow-auto">
      <div className=" sm:hidden absolute bottom-2 right-1">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          <Modal
            open={isSelectCreateModalOpen}
            onClose={() => setIsSelectCreateModalOpen(false)}
          >
            <div className="flex flex-col mt-6">
              <button
                className="btn-secondary mb-4"
                onClick={() => {
                  setIsCreateModalOpen(true);
                  setIsSelectCreateModalOpen(false);
                }}
              >
                Add a Document
              </button>
              <ProtectedComponent requiredRole="ADMINISTRATOR">
                <button
                  className="btn-secondary"
                  onClick={() => {
                    setIsCreateSubfolderModalOpen(true);
                    setIsSelectCreateModalOpen(false);
                  }}
                >
                  Add a Folder
                </button>
              </ProtectedComponent>
            </div>
          </Modal>
          <button
            onClick={() => setIsSelectCreateModalOpen(true)}
            className="btn-circle"
          >
            +
          </button>
        </ProtectedComponent>
      </div>
      <div className="wizardHeader hidden sm:flex">
        <h1 className="self-center ">Documentation</h1>
      </div>
      <div className="wizardContent flex flex-col pt-0">
        {rootFolder && <ProjectDocumentTab entityId={rootFolder} />}
      </div>
    </div>
  );
};

export default DocumentsPage;
