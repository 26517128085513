import React from "react";
import ReactDatePicker from "react-datepicker";

interface Props {
  label: string;
  value: Date;
  onChange: (e: Date) => void;
  className?: string;
  placeholder?: string;
  errorMessage?: string;
  minDate?: Date;
  groupClass?: string;
}

const DateInput = ({
  label,
  className,
  errorMessage,
  value,
  onChange,
  minDate,
  groupClass
}: Props) => (
  <fieldset className={groupClass}>
    <label className="text-sm" htmlFor={label}>
      {label}
    </label>
    <ReactDatePicker
      dateFormat="dd/MM/yyyy"
      id={label}
      className={className}
      minDate={minDate}
      onChange={e => {
        if (e) onChange(e);
      }}
      selected={value}
    />
    <div className="inputErrorMessage">{errorMessage}</div>
  </fieldset>
);

export default DateInput;
