import { Machine } from "xstate";

const LoginStateMachine = Machine({
  id: "loginStateMachine",
  initial: "idle",
  states: {
    idle: {
      on: { SUBMIT: "loading" }
    },
    loading: {
      invoke: {
        id: "loginUser",
        src: (context, event) => event.login(event.username, event.password),
        onDone: {
          target: "success"
        },
        onError: {
          target: "error"
        }
      }
    },
    success: {
      after: {
        1000: "completed"
      }
    },
    error: {
      on: { SUBMIT: "loading" }
    },
    completed: {
      type: "final"
    }
  }
});

export default LoginStateMachine;
