import { Machine, assign } from "xstate";
import endpoints, { axios } from "../../../config/api";

const createTaskTemplateRequest = (taskTemplate: any) => {
  const formData = new FormData();
  formData.append("name", taskTemplate.name);
  formData.append("description", taskTemplate.description);
  formData.append("newSubtasks", JSON.stringify(taskTemplate.subTasksToCreate));

  taskTemplate.documentsToCreate.forEach((d: any) => {
    formData.append("newDocuments", d.file);
    formData.append("newDocumentNames", d.name);
  });

  taskTemplate.dependencies.forEach((d: any) => {
    formData.append("dependancyIds", d.id.toString());
  });

  return axios.post(endpoints.taskTemplates.create, formData);
};

const updateTaskTemplateRequest = (taskTemplate: any) => {
  return axios.put(
    endpoints.taskTemplates.update(taskTemplate.id),
    taskTemplate
  );
};

const TaskTemplateWizardStateMachine = Machine({
  id: "TaskTemplateWizardStateMachine",
  initial: "step1",
  context: {
    success: false,
    errors: []
  },
  states: {
    step1: {
      on: {
        NEXT: "step2"
      }
    },
    step2: {
      on: {
        NEXT: "step3",
        PREVIOUS: "step1"
      }
    },
    step3: {
      on: {
        PREVIOUS: "step2",
        NEXT: "step4"
      }
    },
    step4: {
      on: {
        PREVIOUS: "step3",
        SUBMIT: { target: "loading" }
      }
    },
    loading: {
      invoke: {
        id: "createTaskTemplate",
        src: (_, taskTemplate) =>
          taskTemplate.id
            ? updateTaskTemplateRequest(taskTemplate)
            : createTaskTemplateRequest(taskTemplate),
        onDone: {
          target: "success",
          actions: assign({ success: true, errors: [] })
        },
        onError: {
          target: "error",
          actions: assign({ success: false, errors: [] })
        }
      }
    },
    success: {
      entry: "redirectToTemplatePage"
    },
    error: {
      entry: "showErrorNotification",
      after: {
        1000: "step3"
      }
    }
  }
});

export default TaskTemplateWizardStateMachine;
