import React, {
  useCallback,
  useState,
  FC,
  useEffect,
  useRef,
  Fragment
} from "react";
import { useMachine } from "@xstate/react";
import { useHistory, RouteChildrenProps } from "react-router-dom";
import Document from "../../../types/Document";
import { useToasts } from "react-toast-notifications";
import endpoints, { axios } from "../../../config/api";
import TaskTemplateSubtaskPage from "../../../containers/TaskTemplateWizard/TaskTemplateSubtaskPage";
import TaskTemplateDetailPage from "../../../containers/TaskTemplateWizard/TaskTemplateDetailPage";
import TemplateTaskEditDocumentsTab from "../../../containers/TaskTemplateWizard/TaskTemplateEditDocumentsPage";
import TaskDocumentUploadPage from "../../../containers/TaskWizard/components/TaskDocumentUploadPage";
import TaskTemplateWizardStateMachine from "./stateMachine";
import TaskTemplateEditSubtaskPage from "../../../containers/TaskTemplateWizard/TaskTemplateEditSubtaskPage";
import TaskTemplate, { TaskTemplateWithKey } from "../../../types/TaskTemplate";
import TaskTemplateDependancySelectionPage from "../../../containers/TaskTemplateWizard/TaskTemplateDependencyPage";
import TaskTemplateEditDependancySelectionPage from "../../../containers/TaskTemplateWizard/TaskTemplateEditDependencyPage";
import shortid from "shortid";
import api from "../../../api";

export interface ITaskTemplateWizardForm {
  name: string;
  description: string;
  type: number;
  documents: Document[];
}

export interface DocumentToCreate {
  name: string;
  file: File;
  isGlobal: boolean;
  isPrivate: boolean;
}

interface Match {
  id: string;
}

export interface SubtaskTemplateToCreate {
  name: string;
  description: string;
}

const TaskTemplateWizard: FC<RouteChildrenProps<Match>> = ({ match }) => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [documentsToCreate, setDocumentsToCreate] = useState<
    DocumentToCreate[]
  >([]);
  const [subTasksToCreate, setSubTasksToCreate] = useState<
    SubtaskTemplateToCreate[]
  >([]);
  const [createdDocuments, setCreatedDocuments] = useState<Document[]>([]);
  const [createdSubtasks, setCreatedSubtasks] = useState<TaskTemplate[]>([]);
  const [templateId, setTemplateId] = useState<number>();
  const [templateFolder, setTemplateFolder] = useState<Document>();
  const [isEditing, setIsEditing] = useState(false);
  const { addToast } = useToasts();
  const scrollRef = useRef(null);
  const [selectedTemplateDependancies, setSelectedDependancies] = useState<
    TaskTemplateWithKey[]
  >([]);
  const [taskDependencies, setTaskDependencies] = useState<any[]>([]);

  useEffect(() => {
    if (templateId) {
      setTemplateFolder(undefined);
      api.newFolders.getRootFolderTemplateTask(templateId).then(x => {
        setTemplateFolder(x);
        api.newFolders.getFolderContents(x.id).then(x => {
          setCreatedDocuments(x.payload);
        });
      });
    }
  }, [templateId]);

  const [machine, send] = useMachine(TaskTemplateWizardStateMachine, {
    actions: {
      redirectToTemplatePage: () => {
        history.push("/templates");
        addToast(`Template ${isEditing ? "Updated" : "Created"} Successfully`, {
          appearance: "success",
          autoDismiss: true
        });
        return;
      },
      showErrorNotification: () =>
        addToast(
          `An error has occured when ${
            isEditing ? "updating" : "creating"
          } the template`,
          {
            appearance: "error",
            autoDismiss: true
          }
        )
    }
  });

  const { nextEvents } = machine;

  const hasPrevious = useCallback(
    () => !!nextEvents.find(s => s.includes("PREVIOUS")),
    [nextEvents]
  );

  const hasNext = useCallback(
    () => !!nextEvents.find(s => s.includes("NEXT")),
    [nextEvents]
  );

  const validatePage = () => {
    switch (machine.value) {
      case "step1":
        if (name.trim().length === 0) {
          setNameError("Task Name is a required field");
          return;
        }
        setNameError("");
        if (description.trim() === "") {
          setDescriptionError("Description is required");
          return;
        }
        setDescriptionError("");
        send("NEXT");
        return;

      default:
        send("NEXT");
    }
  };

  const pageToRender = () => {
    switch (machine.value) {
      case "step1":
        return (
          <TaskTemplateDetailPage
            name={name}
            setName={setName}
            nameError={nameError}
            description={description}
            setDescription={setDescription}
            descriptionError={descriptionError}
          />
        );
      case "step2":
        return isEditing && templateId ? (
          templateFolder && (
            <TemplateTaskEditDocumentsTab
              taskTemplateId={templateId}
              createdDocuments={createdDocuments}
              setCreatedDocuments={setCreatedDocuments}
              templateFolder={templateFolder}
            />
          )
        ) : (
          <TaskDocumentUploadPage
            documentsToCreate={documentsToCreate}
            setDocumentsToCreate={setDocumentsToCreate}
          />
        );
      case "step3":
        return isEditing && templateId ? (
          <TaskTemplateEditSubtaskPage
            subtasks={createdSubtasks}
            setSubtasks={setCreatedSubtasks}
            parentTaskId={templateId}
            scrollRef={scrollRef}
          />
        ) : (
          <TaskTemplateSubtaskPage
            subtasksToCreate={subTasksToCreate}
            setSubtasksToCreate={setSubTasksToCreate}
          />
        );
      case "step4":
        return isEditing && templateId ? (
          <TaskTemplateEditDependancySelectionPage
            selectedTemplateDependancies={selectedTemplateDependancies}
            setSelectedDependancies={setSelectedDependancies}
            taskTemplateId={templateId}
            taskDependencies={taskDependencies}
            setTaskDependencies={setTaskDependencies}
          />
        ) : (
          <TaskTemplateDependancySelectionPage
            selectedTemplateDependancies={selectedTemplateDependancies}
            setSelectedDependancies={setSelectedDependancies}
            taskDependencies={taskDependencies}
            setTaskDependencies={setTaskDependencies}
          />
        );
    }
  };

  useEffect(() => {
    if (match?.params.id) {
      axios
        .get(endpoints.taskTemplates.get(parseFloat(match.params.id)))
        .then(({ data }) => {
          setName(data.name);
          setDescription(data.description);
          setTemplateId(data.id);
          setIsEditing(true);
        });
    }
  }, [match]);

  useEffect(() => {
    if (match?.params.id) {
      axios
        .get(endpoints.taskTemplates.getSubtasks(parseFloat(match.params.id)))
        .then(({ data }) => {
          setCreatedSubtasks(data.payload);
        });
    }
  }, [match]);

  useEffect(() => {
    if (match?.params.id) {
      axios
        .get(
          endpoints.taskTemplates.getTasksAndDependencies(
            parseFloat(match.params.id)
          )
        )
        .then(({ data }) => {
          const tasks = data.map((t: any) => {
            return { ...t.templateTask, key: shortid.generate() };
          });
          const taskDependencies = data.map((x: any, i: any) => {
            return {
              id: tasks[i].key,
              dependencies: x.dependencies
            };
          });
          setSelectedDependancies(tasks);
          setTaskDependencies(taskDependencies);
        });
    }
  }, [match]);

  return (
    <div ref={scrollRef} className="h-screen overflow-auto ">
      <div className="wizard">
        <div className="wizardHeader">
          <h1>
            {machine.value === "step1"
              ? isEditing
                ? "Update a Task Template"
                : "Create a  Template "
              : name}
          </h1>
        </div>
        <div className="wizardContent">
          <form onSubmit={e => e.preventDefault()}>{pageToRender()}</form>
        </div>
        <div className="wizardButtons">
          {machine.value === "step1" && (
            <button
              className="btn-secondary sm:w-56 w-full mt-4 sm:mt-0 "
              onClick={() => history.push("/templates")}
            >
              Cancel
            </button>
          )}

          {hasPrevious() && (
            <button
              className="btn-secondary sm:w-56 w-full mt-4 sm:mt-0"
              onClick={() => send("PREVIOUS")}
            >
              Back
            </button>
          )}

          {hasNext() && (
            <button
              className="btn-primary w-full sm:w-56"
              onClick={() => validatePage()}
            >
              Next
            </button>
          )}

          {machine.value === "step4" && (
            <button
              className={`${"btn-primary"} w-full sm:w-56`}
              onClick={() =>
                send("SUBMIT", {
                  name,
                  description,
                  documentsToCreate,
                  subTasksToCreate,
                  id: templateId,
                  dependencies: selectedTemplateDependancies
                })
              }
            >
              {isEditing ? "Update" : "Create"} Task
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskTemplateWizard;
