import React, { FunctionComponent, useState, useEffect, Fragment } from "react";
import { useToasts } from "react-toast-notifications";
import Header from "../../components/Header";
import { useAuth } from "../../state/AuthProvider";
import InlineEditableInput from "../../components/Input/InlineEditableInput";
import User from "../../types/User";
import { useForm } from "react-hook-form";
import { useMachine } from "@xstate/react";
import AccountPageStateMachine from "./stateMachine";
import PhoneIcon from "../../assets/img/phoneIcon.svg";
import EmailIcon from "../../assets/img/emailIcon.svg";
import { useTranslate } from "react-polyglot";
import EditUserImageModal from "../../containers/User/EditUserImageModal";
import { getOnlineStatus } from "../../helpers/useOnlineStatus";
import InlineEditableTypeInput from "../../components/Input/InlineEditableTypeInput";
import userRoleOptions from "../../helpers/userRoleOptions";

const AccountPage: FunctionComponent = () => {
  const t = useTranslate();
  const { addToast } = useToasts();
  const [machine, send] = useMachine(AccountPageStateMachine, {
    actions: {
      showSuccessNotification: () => {
        addToast("Your account has been updated", {
          appearance: "success",
          autoDismiss: true
        });
      },
      showErrorNotification: () =>
        addToast("An error has occured when updating your account", {
          appearance: "error",
          autoDismiss: true
        })
    }
  });
  const { register, errors, triggerValidation } = useForm();
  const { user, updateUser, logout } = useAuth();
  const [userState, setUserState] = useState<User>(user);
  const [isEditUserImageModalOpen, setIsEditUserImageModalOpen] = useState(
    false
  );
  const [updatedProfileImage, setUpdatedProfileImage] = useState<File>();

  useEffect(() => {
    if (JSON.stringify(user) === JSON.stringify(userState)) {
      return;
    }

    send("SUBMIT", {
      ...user,
      ...userState
    });
  }, [send, user, userState]);

  useEffect(() => {
    if (machine.value === "success") {
      if (JSON.stringify(user) === JSON.stringify(userState)) {
        return;
      }

      updateUser({ ...userState, profileImage: machine.context.profileImage });
      setUserState({
        ...userState,
        profileImage: machine.context.profileImage,
        updatedProfileImage: undefined
      });
    }
  }, [
    addToast,
    user,
    machine.value,
    machine.context.profileImage,
    updateUser,
    userState
  ]);

  return (
    <div className="h-screen overflow-auto bg-white ">
      <Header title="Account" />
      <section className="p-8 pb-20">
        {getOnlineStatus() && (
          <Fragment>
            <EditUserImageModal
              isModalOpen={isEditUserImageModalOpen}
              setIsModalOpen={setIsEditUserImageModalOpen}
              onConfirm={() => {
                if (updatedProfileImage) {
                  setUserState({ ...userState, updatedProfileImage });
                  setIsEditUserImageModalOpen(false);
                  setUpdatedProfileImage(undefined);
                }
              }}
              updatedProfileImage={updatedProfileImage}
              setUpdatedProfileImage={setUpdatedProfileImage}
            />
            <img
              className="border-2 border-white mb-8 w-40 md:w-64 md:h-64 cursor-pointer object-cover"
              src={
                user.profileImage ||
                `https://eu.ui-avatars.com/api/?name=${user.firstName}+${user.lastName}&size=256`
              }
              alt="Profile"
              onClick={() => {
                setIsEditUserImageModalOpen(true);
              }}
            />
          </Fragment>
        )}
        <h1>
          {userState?.firstName} {userState?.lastName}
          <br />
          <small>{t(userState?.role)}</small>
        </h1>
        <div className="mt-4 border-t border-grey w-full lg:w-1/2">
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <div className="mt-8 flex flex-row">
              <img src={PhoneIcon} alt="phone icon" className="mr-4" />
              <InlineEditableInput
                type="tel"
                pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
                placeholder="Phone Number"
                value={userState?.phoneNumber}
                inputRef={register({
                  required: true,
                  pattern: {
                    message: "Please enter a valid phone number",
                    value: /^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*/
                  }
                })}
                errors={errors["telephoneInput"]}
                validationMessage="Please enter a valid phone number"
                triggerValidation={triggerValidation}
                name="telephoneInput"
                onChange={(value: string | undefined) => {
                  if (!value) {
                    return;
                  }
                  setUserState({ ...userState, phoneNumber: value });
                }}
              />
            </div>
            <div className="mt-8 flex flex-row">
              <img src={EmailIcon} alt="phone icon" className="mr-4" />
              <InlineEditableInput
                placeholder="Email Address"
                value={userState?.email}
                type="email"
                name="emailInput"
                errors={errors["emailInput"]}
                triggerValidation={triggerValidation}
                pattern="^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                validationMessage="Please enter a valid email address"
                inputRef={register({
                  required: true
                })}
                onChange={(value: string | undefined) => {
                  if (!value) {
                    return;
                  }
                  setUserState({ ...userState, email: value });
                }}
              />
            </div>
          </form>
          <button
            className="btn-primary mt-8 w-full md:w-64"
            onClick={() => {
              logout();
            }}
          >
            Logout
          </button>
        </div>
      </section>
    </div>
  );
};

export default AccountPage;
