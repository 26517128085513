import React, { FC, useState, useRef, useEffect } from "react";
import Task from "../../types/Task";
import ProtectedComponent from "../../components/ProtectedComponent";
import classnames from "classnames";
import { useOnlineProvider } from "../../state/OnlineProvider";
import { useAuth } from "../../state/AuthProvider";
import checkUserRole from "../../helpers/checkUserRole";

interface Props {
  subtask: Task;
  completeSubtask: (subtaskId: number) => void;
  setSubtaskToDelete: (subtaskId: number) => void;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  parentTask?: Task;
  handleUpdateSubtask: (subtask: Task) => void;
  canCompleteSubtasks: boolean;
}

const TaskSubtaskItem: FC<Props> = ({
  subtask,
  parentTask,
  completeSubtask,
  setSubtaskToDelete,
  setIsDeleteModalOpen,
  handleUpdateSubtask,
  canCompleteSubtasks
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [subtaskName, setSubtaskName] = useState(subtask.name);
  const editRef = useRef<HTMLInputElement>(null);
  const [subtaskError, setSubtaskError] = useState(false);
  const { isOnline } = useOnlineProvider();
  const { user } = useAuth();

  useEffect(() => {
    if (isEditing && editRef.current) {
      editRef.current && editRef.current.focus();
    }
  }, [editRef, isEditing]);

  const updateSubtask = () => {
    if (subtaskName.trim().length === 0) {
      setSubtaskError(true);
      return;
    }
    setSubtaskError(false);
    if (subtaskName !== subtask.name) {
      handleUpdateSubtask({ ...subtask, name: subtaskName });
    }
    setIsEditing(false);
  };

  return (
    <tr className="bg-white hover:bg-white my-2  " key={subtask.id}>
      <td className="w-12 pl-0 md:pl-2 py-1">
        <input
          id={`form-input-${subtask.id}`}
          className={`checkbox appearance-none ${
            subtask.isCompleted || !canCompleteSubtasks
              ? "border-grey"
              : "border-lightBlue"
          } border-2 cursor-pointer h-6 w-6`}
          type="checkbox"
          placeholder=""
          disabled={subtask.isCompleted || !canCompleteSubtasks}
          checked={subtask.isCompleted}
          onChange={e => completeSubtask(subtask.id)}
        />
      </td>
      <td
        className={`pl-2 ${
          subtask.isCompleted || !canCompleteSubtasks ? "text-grey" : ""
        }`}
        onClick={() => !subtask.isCompleted && isOnline && setIsEditing(true)}
      >
        {subtask.isCompleted ? (
          subtask.name
        ) : isEditing && checkUserRole("PROJECTMANAGER", user.role) ? (
          <form onSubmit={updateSubtask}>
            <input
              id={`form-input-${subtask.id}`}
              placeholder=""
              className={classnames({
                "border-red": subtaskError
              })}
              value={subtaskName}
              onChange={e => setSubtaskName(e.target.value)}
              onBlur={updateSubtask}
              ref={editRef}
            />
          </form>
        ) : (
          <div className="pr-4">{subtask.name}</div>
        )}
      </td>
      <td className="flex justify-end">
        <ProtectedComponent requiredRole="PROJECTMANAGER">
          {!parentTask?.isCompleted && !subtask.isCompleted && isOnline && (
            <div
              id="deleteIcon"
              className="cursor-pointer"
              onClick={() => {
                setSubtaskToDelete(subtask.id);
                setIsDeleteModalOpen(true);
              }}
            />
          )}
        </ProtectedComponent>
      </td>
    </tr>
  );
};

export default TaskSubtaskItem;
